import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import login from "../../assets/login1.svg";
import logo from "../../assets/login-logo.svg";
import { useNavigate } from "react-router-dom";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OTPVerify: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    console.log("test test", storedUserId);

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);
  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const email = localStorage.getItem("email");
    if (otp.trim() === "") {
      setOTPError("OTP is Required");
      return;
    }
    const req = {
      register_otp: otp,
      email: email,
    };

    callApi("POST", "otp_verify.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          localStorage.setItem("idid", response.data.responseJson.data.id);
          navigate("/changepassword");
          setLoading(false);
        } else {
          showError("Error..!", response.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };
  const handleBack = () => {
    navigate("/"); // Navigate to the home page or any other appropriate page
  };

  return (
    <div className="login-grid">
      <div className="login-form">
        <div className="back-button">
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="welcome-text">
          <img src={logo} alt="logo" className="logo" />
          <p style={{ fontSize: "40px", marginTop: "10px" }}>OTP Verify</p>
        </div>
        <form onSubmit={handleVerify}>
          <div className="form-field">
            <TextField
              id="otp"
              label="Enter OTP"
              variant="standard"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              required
              error={!!otpError}
              helperText={otpError}
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>
          <div className="form-field">
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                background: "linear-gradient(180deg, #883DCF 0%, #451F69 100%)",
                color: "white",
              }}
              disabled={loading}
            >
              {loading ? (
                <Hourglass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "white"]}
                />
              ) : (
                "Verify OTP"
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="login-image">
        <img src={login} alt="loginbg" />
      </div>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OTPVerify;
