import Swal from "sweetalert2";

export function showError(title: any, message: any) {
  Swal.fire({
    title: title,
    icon: "error",
    text: message,
    timer: 2000,
    showConfirmButton: false,
    width: "25rem",
  });
}
export function showSuccess(title: any, message: any) {
  Swal.fire({
    title: title,
    icon: "success",
    text: message,
    timer: 2000,
    showConfirmButton: false,
    width: "25rem",
  });
}
