import React from "react";

const sendNotification = (deviceToken: string, title: string, body: string) => {
  const serverKey =
    "AAAARazOMI4:APA91bHv_M6OezABPFyvjl8QHR1_81Nc5pWdNk_j7SY6-sIzVHM6rdkch6dho-VxXoYTwISs2atIQkZ8wbwkCkXHzW71yoZ-OlAE9uYzFkBmvbfRhyZwmB6vKduezblZM0Di9xZx5Of3"; // Replace with your actual server key
  const message = {
    to: deviceToken,
    notification: {
      title: title,
      body: body,
    },
    data: {
      title: title,
      body: body,
    },
  };
  console.log("sendNotification", message);

  fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers: {
      Authorization: `key=${serverKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("FCM Response:", data);
      // You can handle the response as needed
      return data;
    })
    .catch((error) => {
      console.error("Error sending FCM message:", error);
      // You can handle the error as needed
      throw error;
    });
};

export default sendNotification;
