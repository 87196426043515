import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import "../../components/pages/style/subscribers.css";
import rightarrow from "../../assets/arrowright.svg";
import exportsvg from "../../assets/product/export.svg";
import plus from "../../assets/Header/plus.svg";
import threedot from "../../assets/three-dot-clr.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import deleteicon from "../../assets/user/delete.svg";
import line from "../../assets/user/line.svg";
import mail from "../../assets/user/mail.svg";
import phone from "../../assets/user/phone.svg";
import location from "../../assets/user/location.svg";
import calender from "../../assets/subscriber/calender.svg";
import calender2 from "../../assets/subscriber/calender2.svg";
import gender from "../../assets/subscriber/gender.svg";
import line2 from "../../assets/user/line2.svg";
import height from "../../assets/height.svg";
import weight from "../../assets/weight.svg";
import bmi from "../../assets/bmi.svg";
import history from "../../assets/medical-history.svg";
import ReactPaginate from "react-paginate";
import { callApi } from "../utils/api";
import * as XLSX from "xlsx";
import "./style/team.css";
import { showError, showSuccess } from "../utils/sweetAlert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { report } from "process";

interface team {
  id: number;
  name: string;
  user_name: string;
  email: string;
  phone_no: string;
  active_status: string;
  address: String;
  image: string;
  profile_img: string;
  dob: string;
  gender: string;
  employee_no: string;
  doj: string;
  role_name: string;
  designation: string;
  height: string;
  weight: string;
  bmi: string;
  medical_history: string;
  report: string;
}
interface auth {
  subscriber: boolean;
  plan: boolean;
  product: boolean;
  group: boolean;
  Notice: boolean;
  Attendance: boolean;
}
interface role {
  id: number;
  role: string;
}
const Team: React.FC = () => {
  const [roletypes, setroletypes] = useState<role[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activeButton") || "All Status";
  });
  const [activemodalButton, setActivemodalButton] = useState("Info");
  const [searchTerm, setSearchTerm] = useState("");
  const [roledata, setroledata] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(15);
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [showSecondColumn, setShowSecondColumn] = useState(false);
  const [modalContent, setModalContent] = useState("Info");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [teamdata, setteamdata] = useState<team[]>([]);
  const [authdata, setauthentication] = useState<auth[]>([]);
  const [isThreeDotModalOpen, setIsThreeDotModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    localStorage.setItem("activeButton", activeButton);
    return () => {
      localStorage.removeItem("activeButton");
    };
  }, [activeButton]);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      console.log("Selected Business:", storedBusiness);
      getteamselect(storedBusiness);
    }

    getrole();
    const storedname = localStorage.getItem("selectedname");
    if (storedname) {
      setSearchTerm(storedname); // Set the searchTerm state with storedName
    }
    localStorage.removeItem("selectedname");
    handleButtonClick(activeButton);
  }, []);
  const getteamselect = (businessType: string) => {
    setLoading(true);
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "team_select.php", req)
      .then((response) => {
        setLoading(false);
        const updatedTeamData = response.data.responseJson.map((item: any) => ({
          ...item,
          image: item.proof,
        }));
        setteamdata(updatedTeamData);
        console.log(",,,,,,,,,,,", teamdata);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const emptyObject: any = {};

  const getauthentication = (id: any) => {
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const req = {
      bussiness_type: storedBusiness,
      user_id: id,
    };
    callApi("POST", "staff_authentication.php", req)
      .then((response) => {
        setauthentication(response.data.responseJson);
        setroledata(response.data.role);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openThreeDotModal = (card: any) => {
    setIsThreeDotModalOpen(true);
    setSelectedItem(card);
    console.log("aaaaaaaaa", card);
  };

  const closeThreeDotModal = () => {
    setIsThreeDotModalOpen(false);
  };

  function formatDate(dateString: any) {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  const editsubmit = () => {
    setLoading(true);

    if (selectedItem.imageFile) {
      const allowedFormats = ["jpeg", "jpg", "png", "gif"]; // Add more formats if needed
      const fileExtension = selectedItem.imageFile.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        showError(
          "Error",
          "Unsupported image format. Please upload an image in JPEG, JPG, PNG, or GIF format."
        );
        setLoading(false);
        return;
      }
    }
    const edited = {
      ...selectedItem,
      designation: selectedItem.role_id,
      staff_id: selectedItem.id,
    };
    if (!edited.imageFile) {
      edited.image = ""; // or editedPlan.image = undefined;
    }

    callApi("POST", "staff_edit.php", edited)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          closeThreeDotModal();
          getteamselect(selectedBusiness);
        }
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);
  };
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };
  const handleCloseButtonClick = () => {
    setShowSecondColumn(false);
  };

  const validateEmail = (email: any) => {
    // Regular expression for validating email addresses with .com or .in domains
    const regex = /^[^\s@]+@[^\s@]+\.(com|in)$/;
    return regex.test(email);
  };

  const handlemodalButtonClick = (buttonName: string) => {
    setActivemodalButton(buttonName);
    if (buttonName === "Info") {
      setModalContent("Info");
    } else if (buttonName === "Authentications") {
      setModalContent("Authentications");
    }
  };
  const handleAccordionClick = (sectionNumber: number) => {
    const accordionContent = document.getElementById(
      `accordion-content-${sectionNumber}`
    );
    accordionContent?.classList.toggle("active");
  };

  const downloadExcel = () => {
    const todayDate: any = new Date();
    const formattedDate =
      todayDate.getDate().toString().padStart(2, "0") -
      (todayDate.getMonth() + 1).toString().padStart(2, "0") -
      todayDate.getFullYear();
    const flattenedData = filteredData.map((file: any, index: any) => {
      return {
        ID: index + 1,
        EmpNo: file.employee_no,
        name: file.name,
        gender: file.gender,
        Status: file.active_status,
        Mobile: file.phone_no,
        DOB: file.dob,
        email: file.email,
        address: file.address,
      };
    });
    const headers = [
      "ID",
      "EmpNo",
      "name",
      "Gender",
      "Status",
      "Mobile",
      "DOB",
      "email",
      "Address",
    ];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    // const fileName = `Files ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, `Team.xlsx`);
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleDecimal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = event;
    const { value } = currentTarget;

    // Allow numeric characters (0-9), backspace, delete, and one decimal point
    if (
      (!/^[\dA-Za-z]$/.test(key) &&
        key !== "Backspace" &&
        key !== "Delete" &&
        key !== ".") ||
      (key === "." && value.includes("."))
    ) {
      event.preventDefault();
    }
  };

  const handleBlur = (event: any) => {
    const { id, value } = event.target;
    if (id === "email" && !validateEmail(value)) {
      alert("Please enter a valid email address.");
      // Optionally, you can clear the input or display an error message.
      // Example: setFormData({ ...formData, [id]: '' });
    }
  };

  const getrole = () => {
    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "select_role.php", req)
      .then((response) => {
        setroletypes(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedItem({
      ...selectedItem,
      designation: e.target.value,
    });
  };

  const handleDelete = (item: any) => {
    setLoading(true);

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      const req = {
        id: item,
      };
      callApi("POST", "staff_delete.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          getteamselect(selectedBusiness);
          setShowSecondColumn(false);
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    } else {
      console.log("Deletion canceled.");
    }
  };
  const currentDate = new Date().toISOString().split("T")[0];

  const modalBody = (
    <div>
      {modalContent === "Info" && (
        <div className="user-details">
          <div>
            <img src={mail} alt="mail" className="mail-user" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.email}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={phone} alt="phone" className="phone-user" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.phone_no}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={location} alt="location" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.address}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={calender} alt="location" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.dob}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={gender} alt="location" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.gender}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={calender2} alt="location" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.doj}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={height} alt="height" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.height} cm
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={weight} alt="weight" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.weight} kg
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={bmi} alt="bmi" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {teamdata.find((card) => card.id === selectedCardId)?.bmi}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={history} alt="history" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {
                teamdata.find((card) => card.id === selectedCardId)
                  ?.medical_history
              }
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={history} alt="report" className="location-icon" />
            <a
              style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}
              href={teamdata.find((card) => card.id === selectedCardId)?.report}
              download
            >
              {teamdata.find((card) => card.id === selectedCardId)?.user_name}
              _report.pdf
            </a>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div style={{ position: "relative" }}>
            <img
              src={teamdata.find((card) => card.id === selectedCardId)?.image}
              alt="Your Image"
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "30px",
                marginTop: "30px",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                padding: "5px",
              }}
            >
              Proof
            </div>
          </div>
        </div>
      )}
      {modalContent === "Authentications" && (
        <div className="authentications-details">
          <h2>{roledata}</h2>
          {authdata && (
            <div>
              {Object.entries(authdata).map(([key, value]) => (
                <div key={key}>
                  <label>
                    <input
                      style={{ marginRight: "8px" }}
                      type="checkbox"
                      checked={!!value}
                      readOnly
                    />
                    {key}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const filteredData = teamdata
    ?.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .filter((item) => {
      if (filterCriteria === "All Status") return true;
      if (filterCriteria === "Active") return item.active_status === "Active";
      if (filterCriteria === "Resigned")
        return item.active_status === "inactive";

      return true;
    });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };
  const handleViewProfile = (user: any) => {
    setSelectedCardId(user.id);
    setShowSecondColumn(true);
    getauthentication(user.id);
  };
  // Get current items to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const totalItems = filteredData.length;
  const rangeEnd = Math.min((currentPage + 1) * itemsPerPage, totalItems);
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="finance-page">
        <div className="content">
          <p style={{ marginTop: "1px", marginBottom: "1px" }}>User</p>
          <div className="dashboard-container">
            <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
            <p>
              <img src={rightarrow} alt="Arrow Right" />
            </p>
            <p style={{ marginRight: "10px" }}>User</p>
            <div className="buttons-container" style={{ marginLeft: "63%" }}>
              <button className="export-button" onClick={downloadExcel}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={exportsvg}
                    alt="Export"
                    style={{ marginRight: "5px", marginLeft: "12px" }}
                  />
                  <span style={{ color: "purple" }}>Export</span>
                </div>
              </button>
              <button className="add-button1" onClick={openModal}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={plus}
                    alt="Add Role"
                    style={{ marginRight: "5px" }}
                  />
                  <span style={{ color: "white" }}>Add User</span>
                </div>
              </button>
            </div>
          </div>
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            buttontext={"User"}
            selecteddata={emptyObject}
          />
          <div className="card-and-search-container">
            {/* Cardmodal */}
            <div className="cardmodal-sub">
              <button
                className={activeButton === "All Status" ? "active" : ""}
                onClick={() => handleButtonClick("All Status")}
              >
                All Status
              </button>
              <button
                className={activeButton === "Active" ? "active" : ""}
                onClick={() => handleButtonClick("Active")}
              >
                Active
              </button>
              <button
                className={activeButton === "Resigned" ? "active" : ""}
                onClick={() => handleButtonClick("Resigned")}
              >
                Resigned
              </button>
            </div>
            {/* Filter input */}
            <div className="search-field-team">
              <input
                type="text"
                placeholder="Search User.."
                className="search-input-team"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            {/* <div>
              <button className="filter-button">
                <img src={filtersvg} alt="Filter" className="filter-icon" />
                Filter
              </button>
            </div> */}
          </div>
          <div className="row">
            <div className="column">
              <div
                className={`card-container-team ${
                  showSecondColumn
                    ? "three-cards-per-row"
                    : "four-cards-per-row"
                }`}
              >
                {currentItems.length === 0 ? (
                  <div>No data available</div>
                ) : (
                  currentItems.map((card) => (
                    <div
                      key={card.id}
                      className={`card1 ${
                        selectedCardId === card.id ? "selected-card" : ""
                      }`}
                    >
                      <div className="card-content">
                        <img
                          src={threedot}
                          alt="More Options"
                          className="three-dot-icon"
                          onClick={() => openThreeDotModal(card)}
                        />
                        <img
                          src={card.profile_img}
                          alt="Subscriber Logo"
                          className="subscriber-logo-team"
                        />
                        <p className="subscriber-name">{card.user_name}</p>
                        <p className="user-mail">{card.email}</p>
                        <p
                          className="user-mail"
                          style={{
                            color:
                              card.active_status === "Active" ? "green" : "red",
                          }}
                        >
                          {card.active_status}
                        </p>
                      </div>
                      <div className="card-footer">
                        <button onClick={() => handleViewProfile(card)}>
                          View Profile
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div
              className={`column ${showSecondColumn ? "visible" : "hidden"}`}
            >
              {selectedCardId !== null && (
                <div className="user-modal">
                  <div className="modal-header">
                    <button
                      className="closebtn"
                      onClick={handleCloseButtonClick}
                    >
                      <img src={closeIcon} alt="Close" />
                    </button>
                    <div className="avatar-container">
                      <img
                        src={
                          teamdata.find((card) => card.id === selectedCardId)
                            ?.profile_img
                        }
                        alt="Avatar"
                        className="avatar"
                        style={{ height: "32%", width: "32%" }}
                      />
                    </div>
                    <div className="deletebtn-container">
                      {teamdata.find((card) => card.id === selectedCardId)
                        ?.active_status === "Active" && (
                        <button className="deletebtn">
                          <img
                            src={deleteicon}
                            alt="delete"
                            onClick={() => handleDelete(selectedCardId)}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="modal-content">
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "2px",
                        fontSize: "x-large",
                        marginBottom: "3px",
                      }}
                    >
                      {
                        teamdata.find((card) => card.id === selectedCardId)
                          ?.user_name
                      }
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "3px",
                        color:
                          teamdata.find((card) => card.id === selectedCardId)
                            ?.active_status === "Active"
                            ? "green"
                            : "red",
                      }}
                    >
                      {
                        teamdata.find((card) => card.id === selectedCardId)
                          ?.active_status
                      }
                    </p>
                    <img src={line} alt="line" />
                    <div className="cardmodal-usermodal">
                      <button
                        className={activemodalButton === "Info" ? "active" : ""}
                        onClick={() => handlemodalButtonClick("Info")}
                      >
                        Info
                      </button>
                      <button
                        className={
                          activemodalButton === "Authentications"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handlemodalButtonClick("Authentications")
                        }
                      >
                        Authentications
                      </button>
                    </div>
                    {modalBody}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStart}-{rangeEnd} from {totalItems}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {isThreeDotModalOpen && selectedItem && (
            <div className="edit-modal">
              <div className="edit-modal-content">
                <button className="close-button" onClick={closeThreeDotModal}>
                  <img src={closeIcon} alt="Close" />
                </button>
                <h2>Edit User</h2>
                <div className="product-fields">
                  <div className="input-row">
                    <div>
                      <label htmlFor="title">
                        {" "}
                        Name:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={selectedItem.name}
                        onKeyPress={handleKeyPressname}
                        required
                        maxLength={20}
                        minLength={1}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="title"> User Name:</label>
                      <input
                        type="text"
                        id="name"
                        value={selectedItem.user_name}
                        onKeyPress={handleKeyPressname}
                        readOnly
                        maxLength={20}
                        minLength={1}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            user_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="email">
                        Email:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        value={selectedItem.email}
                        onBlur={handleBlur}
                        style={{ height: "30px" }}
                        maxLength={30}
                        readOnly
                        required
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div>
                      <label htmlFor="empno">Employee_no:</label>
                      <input
                        type="number"
                        id="employee_no"
                        style={{ height: "30px" }}
                        value={selectedItem.employee_no}
                        readOnly
                        maxLength={10}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            employee_no: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="dob">
                        Date of Birth:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="dob"
                        max={currentDate}
                        required
                        value={formatDate(selectedItem.dob)}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            dob: formatDate(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="phone">
                        Phone:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="phone_no"
                        value={selectedItem.phone_no}
                        minLength={10}
                        maxLength={10}
                        required
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            phone_no: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="designation">
                        Designation/Role
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="designation"
                        style={{
                          width: "106%",
                          height: "80%",
                          borderRadius: "5px",
                        }}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            designation: e.target.value,
                          })
                        }
                        value={selectedItem.role_id}
                        required
                      >
                        <option value="">Select </option>
                        {roletypes.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.role.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="doj">
                        Date of Joining:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="doj"
                        max={currentDate}
                        required
                        value={formatDate(selectedItem.doj)}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            doj: formatDate(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="height">
                        {" "}
                        Height:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="height"
                        minLength={2}
                        onKeyDown={handleDecimal}
                        value={selectedItem.height}
                        required
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            height: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="weight">
                        {" "}
                        Weight:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="weight"
                        minLength={2}
                        required
                        onKeyDown={handleDecimal}
                        value={selectedItem.weight}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            weight: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="bmi">
                        {" "}
                        BMI:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="bmi"
                        minLength={2}
                        required
                        onKeyDown={handleDecimal}
                        value={selectedItem.bmi}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            bmi: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="add">
                        Address:<span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        id="address"
                        onKeyPress={handleKeyPress}
                        maxLength={250}
                        required
                        value={selectedItem.address}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="history"> Medical History:</label>
                      <input
                        type="text"
                        id="medical_history"
                        value={selectedItem.medical_history}
                        minLength={2}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            medical_history: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="image">New Medical History:</label>
                      <div className="file-input-container">
                        <input
                          type="file"
                          id="file"
                          style={{ width: "175px" }}
                          accept="pdf/*"
                          onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onloadend = () => {
                                setSelectedItem({
                                  ...selectedItem,
                                  report: reader.result as string,
                                  reportFormat: file.type.split("/")[1],
                                });
                              };
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="existing-image">
                      <label>Existing Medical History :</label>
                      <a href={selectedItem.report} download>
                        {selectedItem.user_name}_report.pdf
                      </a>
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="image">New Image:</label>
                      <div className="file-input-container">
                        <input
                          type="file"
                          id="image"
                          style={{ width: "175px" }}
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                              const validImageTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/gif",
                                "image/webp",
                              ]; // Add any other image types you want to support
                              if (!validImageTypes.includes(file.type)) {
                                alert("Please upload a valid image file.");
                                e.target.value = ""; // Clear the file input field
                                return;
                              }
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onloadend = () => {
                                setSelectedItem({
                                  ...selectedItem,
                                  image: reader.result as string, // Set image URL to data URL
                                  imageFile: file,
                                  imageformat: file.type.split("/")[1],
                                });
                              };
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="existing-image">
                      <label>Existing Image :</label>
                      {selectedItem.image && (
                        <img
                          src={selectedItem.image}
                          alt="Existing Image"
                          style={{
                            width: "100px",
                            height: "100px",
                            marginLeft: "30%",
                          }} // Adjust dimensions as needed
                        />
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="submit-button"
                  onClick={editsubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <Hourglass
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="hourglass-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={["white", "white"]}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Team;
