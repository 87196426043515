import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import rightarrow from "../../assets/arrowright.svg";
import exportsvg from "../../assets/product/export.svg";
import logo from "../../assets/subscriber/logo.svg";
import dnd from "../../assets/dnd.svg";
import active from "../../assets/subscriber/active.svg";
import inactive from "../../assets/subscriber/inactive.svg";
import dot from "../../assets/subscriber/dot.svg";
import { callApi } from "../utils/api";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import ReactPaginate from "react-paginate";
import "./style/finance.css";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";

interface Finance {
  id: number;
  user_idfk: string;
  plan_idfk: string;
  task_idfk: string;
  partially_paid: string;
  user_name: string;
  user_status: string;
  user_number: string;
  plan_name: string;
  bussiness_type: string;
  payment_date: string;
  payment_amt: string;
  profile: string;
}

const Finance: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activeButton") || "All Status";
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Current page starts from 0
  const [itemsPerPage] = useState(15);
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [financedata, setFinancedata] = useState<Finance[]>([]);
  const [originalFinancedata, setOriginalFinancedata] = useState<Finance[]>([]);

  useEffect(() => {
    localStorage.setItem("activeButton", activeButton);
  }, [activeButton]);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      getFinance(storedBusiness);
    }

    const storedname = localStorage.getItem("selectedname");
    if (storedname) {
      setSearchTerm(storedname);
    }
    localStorage.removeItem("selectedname");
  }, []);

  const getFinance = (businessType: string) => {
    setLoading(true);
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "finance_list.php", req)
      .then((response) => {
        if (response.data.success === true) {
          setFinancedata(response.data.responseJson);
          setOriginalFinancedata(response.data.responseJson);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
        setLoading(false);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);

    if (buttonName === "All Status") {
      setFinancedata(originalFinancedata);
    } else if (buttonName === "Today") {
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().split("T")[0];
      const filteredData = originalFinancedata.filter(
        (item) => item.payment_date === currentDateString
      );
      setFinancedata(filteredData);
    } else if (buttonName === "This Week") {
      const currentDate = new Date();
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      ); // End of current week
      const startDateString = startOfWeek.toISOString().split("T")[0];
      const endDateString = endOfWeek.toISOString().split("T")[0];
      const filteredData = originalFinancedata.filter((item) => {
        return (
          item.payment_date >= startDateString &&
          item.payment_date <= endDateString
        );
      });
      setFinancedata(filteredData);
    } else if (buttonName === "Expired") {
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().split("T")[0];
      const filteredData = originalFinancedata.filter(
        (item) => item.payment_date < currentDateString
      );
      setFinancedata(filteredData);
    } else if (buttonName === "Partially") {
      setLoading(true);
      const req = {
        bussiness_type: localStorage.getItem("selectedBusiness"),
      };
      callApi("POST", "finance_list_partial.php", req)
        .then((response) => {
          setFinancedata(response.data.responseJson);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Network error:", error);
          setLoading(false);
        });
    }
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const downloadExcel = () => {
    const todayDate: any = new Date();
    const formattedDate =
      todayDate.getDate().toString().padStart(2, "0") -
      (todayDate.getMonth() + 1).toString().padStart(2, "0") -
      todayDate.getFullYear();
    const flattenedData = filteredData.map((file: any, index: any) => {
      return {
        id: index,
        user_id: file.user_idfk,
        plan_id: file.plan_idfk,
        task_id: file.task_idfk,
        partially_paid: file.partially_paid,
        user_name: file.user_name,
        user_status: file.user_status,
        user_number: file.user_number,
        plan_name: file.plan_name,
        payment_date: file.payment_date,
        payment_amt: file.payment_amt,
      };
    });
    const headers = [
      "id",
      "user_id",
      "plan_id",
      "task_id",
      "partially_paid",
      "user_name",
      "user_status",
      "user_number",
      "plan_name",
      "payment_date",
      "payment_amt",
    ];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    XLSX.writeFile(wb, `Finance.xlsx`);
  };

  const filteredData =
    financedata?.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // Get current items to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const totalItems = filteredData.length;
  const rangeEnd = Math.min((currentPage + 1) * itemsPerPage, totalItems);
  const emptyObject: any = {};
  return (
    <>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <div className="finance-page">
        <div className="content">
          <p style={{ marginTop: "1px", marginBottom: "1px" }}>Finance</p>
          <div className="dashboard-container">
            <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
            <p>
              <img src={rightarrow} alt="Arrow Right" />
            </p>
            <p style={{ marginRight: "10px" }}>Finance</p>
            <div className="buttons-container">
              <button className="export-button">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={downloadExcel}
                >
                  <img
                    src={exportsvg}
                    alt="Export"
                    style={{ marginRight: "5px", marginLeft: "12px" }}
                  />
                  <span style={{ color: "purple" }}>Export</span>
                </div>
              </button>
            </div>
          </div>
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            buttontext={"Finance"}
            selecteddata={emptyObject}
          />
          <div className="card-and-search-container">
            {/* Cardmodal */}
            <div className="cardmodal-sub">
              <button
                className={activeButton === "All Status" ? "active" : ""}
                onClick={() => handleButtonClick("All Status")}
              >
                All Status
              </button>
              <button
                className={activeButton === "Today" ? "active" : ""}
                onClick={() => handleButtonClick("Today")}
              >
                Today
              </button>
              <button
                className={activeButton === "This Week" ? "active" : ""}
                onClick={() => handleButtonClick("This Week")}
              >
                This Week{" "}
              </button>
              <button
                className={activeButton === "Expired" ? "active" : ""}
                onClick={() => handleButtonClick("Expired")}
              >
                Expired
              </button>
              <button
                className={activeButton === "Partially" ? "active" : ""}
                onClick={() => handleButtonClick("Partially")}
              >
                Partially
              </button>
            </div>
            {/* Filter input */}
            <div className="search-field-fin">
              <input
                type="text"
                placeholder="Search Customer.."
                className="search-input-fin"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            {/* <div>
              <button className="filter-button">
                <img src={filtersvg} alt="Filter" className="filter-icon" />
                Filter
              </button>
            </div> */}
          </div>
          <div className="card-container1">
            {currentItems.length === 0 ? (
              <div>No data available</div>
            ) : (
              currentItems.map((card) => (
                <div
                  key={card.id}
                  className={`card1 ${
                    selectedCardId === card.id ? "selected-card" : ""
                  }`}
                >
                  <img
                    src={card.profile}
                    alt="Subscriber Logo"
                    className="subscriber-logo"
                  />
                  <p className="subscriber-name">{card.user_name}</p>
                  {card.user_status === "1" && (
                    <img src={active} alt="Active" className="status-icon" />
                  )}
                  {card.user_status === "2" && (
                    <img
                      src={inactive}
                      alt="Inactive"
                      className="status-icon"
                    />
                  )}
                  {card.user_status === "0" && (
                    <img src={dnd} alt="Inactive" className="status-icon" />
                  )}
                  <img src={dot} alt="dot" className="dot-icon" />
                  <div className="row">
                    <div className="column">
                      <p
                        style={{
                          color: "rgba(102, 112, 133, 1)",
                          marginTop: "2px",
                          marginBottom: "5px",
                        }}
                      >
                        Mobile
                      </p>
                      <p className="mobile" style={{ marginLeft: "-5px" }}>
                        {card.user_number}
                      </p>
                    </div>
                    <div className="column">
                      <p
                        style={{
                          color: "rgba(102, 112, 133, 1)",
                          marginTop: "2px",
                          marginBottom: "5px",
                        }}
                      >
                        Payable
                      </p>
                      <p className="balance">{card.payment_amt}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStart}-{rangeEnd} from {totalItems}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Finance;
