import React, { useEffect, useState, useRef } from "react";
import Modal from "../layout/modal";
import "../pages/style/membership.css";
import view from "../../assets/membership/view.svg";
import line from "../../assets/membership/line.svg";
import rightarrow from "../../assets/arrowright.svg";
import exportsvg from "../../assets/product/export.svg";
import deletesvg from "../../assets/product/delete.svg";
import editsvg from "../../assets/product/edit.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import ReactPaginate from "react-paginate";
import closeIcon from "../../assets/dashboard/close.svg";
import { callApi } from "../utils/api";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import plus from "../../assets/Header/plus.svg";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import BalanceAmtModal from "../layout/BalanceAmtModal";
import { log } from "console";

interface member {
  id: number;
  plan_name: string;
  duration: string;
  price: string;
  offer_price: string;
  plan_type: string;
  offer_enddate: string;
  offer_percentage: number;
  description: string;
  save_status: string;
  bussiness_type: string;
  image: string;
}
interface plan {
  id: string;
  plan_name: string;
  duration: string;
  price: string;
  offer_price: string;
  offer: string;
  plan_type: string;
  offer_enddate: string;
  description: string;
  save_status: string;
  bussiness_type: string;
  image: string;
}
interface task {
  task_id: number;
  task: string;
  days: string;
  payment: string;
  description: string;
}

interface payment {
  total_payment: string;
}
interface day {
  day: any;
}

const Membership: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [selectedplan, setselectedplan] = useState("");
  const [plandata, setplandata] = useState<plan[]>([]);
  const [totalpayemntdata, settotalpayment] = useState<payment[]>([]);
  const [totalday, settotalday] = useState<day[]>([]);
  const [editedTask, setEditedTask] = useState<task | null>(null);
  const [editedTaskData, setEditedTaskData] = useState<any>(null);
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [planExistUser, setPlanExistUser] = useState([]);
  const [isPlanExist, setIsPlanExist] = useState(false);
  const [isPlanExistEdit, setIsPlanExistEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(() => {
    return sessionStorage.getItem("activeButton") || "All Products";
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isValidDays, setIsValidDays] = useState(true);
  const [isNewTaskFormOpen, setIsNewTaskFormOpen] = useState(false);
  const [lastTaskDays, setLastTaskDays] = useState<number>(0);
  const [taskData, setTaskData] = useState({
    title: "",
    days: "",
    add_payment: "",
    description: "",
  });
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [tasks, setTasks] = useState<task[]>([]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [membershipdata, setmembershipdata] = useState<member[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [taskviewmodal, settaskviewmodal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [userId, setUserId] = useState<string>("");
  const [individualPlan, setIndividualPlan] = useState({
    offer_price: "",
    id: "",
    price: "",
    total_task_payment: "",
    duration: "",
  });
  const [showBalanceAmtModal, setShowBalanceAmtModal] = useState(false);
  const [balanceTaskAmt, setBalanceTaskAmt] = useState("");
  const [editedTaskPayment, setEditedTaskPayment] = useState("");
  const [pendingPayment, setPendingPayment] = useState("");

  useEffect(() => {}, [taskData]);
  useEffect(() => {
    if (isEditingTask && divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isEditingTask]);
  useEffect(() => {
    if (selectedItem && selectedBusiness) {
      taskget(selectedBusiness);
    }
  }, [selectedItem, selectedBusiness]);
  useEffect(() => {
    console.log("use individualPlan ____________", individualPlan);
  }, [individualPlan]);
  useEffect(() => {
    sessionStorage.setItem("activeButton", activeButton);
    return () => {
      sessionStorage.removeItem("activeButton");
    };
  }, [activeButton]);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    let storedplan = localStorage.getItem("selectedplanid");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      console.log("Selected Business:", storedBusiness);
      getmembership(storedBusiness);
      getplanselect(storedBusiness);
      taskget(storedBusiness);
    }
    setIsNewTaskFormOpen(false);

    const storedname = localStorage.getItem("selectedname");
    if (storedname) {
      setSearchTerm(storedname);
    }
    localStorage.removeItem("selectedname");

    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      console.log("user id", storedUserId);
      setUserId(storedUserId);
    }
    handleButtonClick(activeButton);
  }, []);
  const getmembership = (businessType: string) => {
    setLoading(true);
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "membership_select.php", req)
      .then((response) => {
        console.log(" Response:", response.data.responseJson);
        setmembershipdata(response.data.responseJson);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleKeyDown = (event: any) => {
    // Allow only numeric characters (0-9), backspace, and delete
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const taskget = (businessType: string) => {
    let storedplanid = localStorage.getItem("selectedplanid");

    if (selectedItem) {
      const req = {
        bussiness_type: businessType,
        plan_id: selectedItem.id,
      };
      callApi("POST", "individual_plan_select.php", req)
        .then((response) => {
          setIndividualPlan(response.data.responseJson[0]);
          setTasks(response.data.responseJson[0].task);
          const tasksData = response.data.responseJson[0].task;
          const maxDays = Math.max(
            ...tasksData.map((task: task) => parseInt(task.days))
          );
          setLastTaskDays(maxDays);
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
  };

  const gettotalpayment = (planid: string) => {
    const req = {
      plan_id: planid,
    };
    callApi("POST", "total_task_payment.php", req)
      .then((response) => {
        const data = response.data;
        settotalpayment(data.response.data.total_payment);
        settotalday(data.response.data.day);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  useEffect(() => {
    console.log("totalpaymentdata_________", totalpayemntdata);
  }, [totalpayemntdata]);

  function formatDate(dateString: any) {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  const getplanselect = (businessType: string) => {
    setLoading(true);

    const req = {
      bussiness_type: businessType,
      plan_status: "1",
    };

    callApi("POST", "plan_select.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;

        const selectedPlanData = responseData.find(
          (plan: any) => plan.id === selectedplan
        );

        console.log("avvaavv", selectedplan);
        if (selectedPlanData) {
          setplandata([selectedPlanData]);
          setLoading(false);

          console.log("Found plan:", selectedPlanData);
        } else {
          console.log("Plan not found");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleAddNewTask = () => {
    setIsNewTaskFormOpen(true);
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s-]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (type: any) => {
    setIsModalOpen(false);
    if (type == "savedraft") {
      const storedBusiness = localStorage.getItem("selectedBusiness");
      if (storedBusiness) {
        setSelectedBusiness(storedBusiness);
        getmembership(storedBusiness);
        getplanselect(storedBusiness);
        taskget(storedBusiness);
      }
      handleButtonClick("draft");
    }
  };

  const handleClose = async () => {
    setIsTaskModalOpen(false);
    await gettotalpayment(individualPlan.id);
    console.log("individualPlan___c", individualPlan);
    const totalTaskPayment =
      (taskData.add_payment ? Number(taskData.add_payment) : 0) +
      (individualPlan.total_task_payment
        ? Number(individualPlan.total_task_payment)
        : 0);

    if (
      individualPlan &&
      individualPlan.offer_price != "" &&
      individualPlan.offer_price != "0"
    ) {
      if (Number(individualPlan.offer_price) == Number(totalTaskPayment)) {
        setShowBalanceAmtModal(false);
        setIsTaskModalOpen(false);
      } else if (
        Number(individualPlan.offer_price) > Number(totalTaskPayment)
      ) {
        setShowBalanceAmtModal(true);
        const balanceAmt: any =
          Number(individualPlan.offer_price) - Number(totalTaskPayment);
        setBalanceTaskAmt(balanceAmt);
      }
    } else if (
      individualPlan &&
      individualPlan.price != "" &&
      individualPlan.price != "0"
    ) {
      if (Number(individualPlan.price) == Number(totalTaskPayment)) {
        setShowBalanceAmtModal(false);
        setIsTaskModalOpen(false);
      } else if (Number(individualPlan.price) > Number(totalTaskPayment)) {
        setShowBalanceAmtModal(true);
        const balanceAmt: any =
          Number(individualPlan.price) - Number(totalTaskPayment);
        setBalanceTaskAmt(balanceAmt);
      }
    } else {
      setShowBalanceAmtModal(false);
      setIsTaskModalOpen(false);
      console.log("close___>");
    }
    console.log(
      "balanceAmt___",
      balanceTaskAmt,
      totalTaskPayment,
      individualPlan.offer_price,
      individualPlan.price
    );
  };

  const hideBalanceAmtModal = () => {
    setShowBalanceAmtModal(false);
    setIsNewTaskFormOpen(true);
  };

  const BalanceSaveDraft = () => {
    setShowBalanceAmtModal(false);
    setIsTaskModalOpen(false);
    closeModal("savedraft");
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handletaskedit = (task: any) => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setEditedTaskData(task);
    setEditedTaskPayment(task.payment);
    setIsEditingTask(true);
  };

  const handleEditTaskSubmit = () => {
    setLoading(true);
    const offerPrice = selectedItem.offer_price
      ? selectedItem.offer_price
      : selectedItem.price;

    const totalPayment = tasks.reduce(
      (acc, curr) => acc + parseFloat(curr.payment),
      0
    );

    const newPayment = parseFloat(editedTaskData.payment);

    if (newPayment > offerPrice) {
      const shouldClear = window.confirm(
        "Total payment exceeds offer price. Cannot add payment."
      );
      if (shouldClear) {
        setEditedTaskData({ ...editedTaskData, add_payment: "" });
        setLoading(false);
        return;
      }
      return;
    }
    if (!editedTaskData.task || !editedTaskData.description) {
      showError("Error", "Please fill out all fields.");
      return;
    }
    if (editedTaskData.days === "0") {
      showError("Error..!", "Day cannot be 0");
      return;
    }

    const originalTask = tasks.find(
      (task: any) => task.id === editedTaskData.id
    );
    console.log(
      "..................",
      selectedItem.duration,
      editedTaskData.days
    );
    const durationdata = selectedItem.duration;
    const daysdata = editedTaskData.days;
    if (daysdata > durationdata) {
      showError("Error..!", "Entered days cannot exceed duration");
      setEditedTaskData({ ...editedTaskData, days: "" });
      setLoading(false);
      return;
    }

    // Check if the days value has changed
    if (originalTask && originalTask.days !== editedTaskData.days) {
      // Check for duplicate day value
      const isDuplicateDay = tasks.some(
        (task: any) =>
          task.days === editedTaskData.days && task.id !== editedTaskData.id
      );

      if (isDuplicateDay) {
        const shouldClear = window.confirm(
          "This day already exists. Do you want to clear it?"
        );
        if (shouldClear) {
          setEditedTaskData({ ...editedTaskData, days: "" });
          setLoading(false);
          return;
        }
        setLoading(false);
        return; // Exit the function after handling the duplicate
      }
    }

    const storedBusiness = localStorage.getItem("selectedBusiness");
    const price = individualPlan.offer_price
      ? Number(individualPlan.offer_price)
      : Number(individualPlan.price);
    const pendingPayment = price - Number(totalpayemntdata);
    const existTaskAmout = Number(totalpayemntdata) - Number(editedTaskPayment);
    const totalTaskAmt =
      Number(existTaskAmout) + Number(editedTaskData.payment);
    const planSaveStatus =
      totalTaskAmt <
      (individualPlan.offer_price
        ? Number(individualPlan.offer_price)
        : Number(individualPlan.price))
        ? "0"
        : "1";
    console.log("llll", pendingPayment, existTaskAmout, totalTaskAmt);
    if (
      totalTaskAmt >
      (individualPlan.offer_price
        ? Number(individualPlan.offer_price)
        : Number(individualPlan.price))
    ) {
      const shouldClear = window.confirm(
        "The Task amount is greater than the plan amount."
      );
      if (shouldClear) {
        setLoading(false);
      }
      return;
    }
    console.log(
      "________",
      totalpayemntdata,
      editedTaskPayment,
      individualPlan.offer_price,
      individualPlan.price
    );

    // Constructing the payload with the required changes
    const req = {
      task_id: editedTaskData.id, // Replace id with task_id
      plan_id: editedTaskData.plan_id,
      task: editedTaskData.task,
      days: editedTaskData.days,
      payment: editedTaskData.payment,
      description: editedTaskData.description,
      bussiness_type: storedBusiness,
      updated_by: userId,
      plan_save_status: planSaveStatus,
    };
    console.log("totalpaymentdata_________1", totalpayemntdata);

    // Sending the request to edit the task
    callApi("POST", "task_edit.php", req)
      .then(async (response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          console.log(
            "exist____________",
            individualPlan,
            price,
            pendingPayment
          );
          taskget(selectedBusiness);
          setLoading(false);
          const totalTaskPayment =
            (editedTaskData.add_payment
              ? Number(editedTaskData.add_payment)
              : 0) +
            (response.data.total_task_payment
              ? Number(response.data.total_task_payment)
              : 0);
          await setTimeout(() => {
            const price = individualPlan.offer_price
              ? Number(individualPlan.offer_price)
              : Number(individualPlan.price);
            const pendingPayment: any = price - Number(totalTaskPayment);
            console.log(
              "new____________",
              individualPlan,
              price,
              pendingPayment
            );
            setPendingPayment(pendingPayment);
          }, 2000);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        setLoading(false);
      });

    setIsEditingTask(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    if (id === "days") {
      const durationDay = parseInt(individualPlan.duration);
      const enteredDays = parseInt(value);

      if (enteredDays > durationDay) {
        window.alert("Entered days cannot exceed duration");
        setLoading(false);
        return;
      }
      if (value === "0") {
        showError("Error..!", "Day cannot be 0");
        return;
      }
    }

    setTaskData({
      ...taskData,
      [id]: value,
    });
  };

  const showAlert = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };

  const handleAddNew = () => {
    if (
      !taskData.title ||
      !taskData.days ||
      !taskData.add_payment ||
      !taskData.description
    ) {
      showError("Error", "Please fill out all fields.");
      return;
    }
    let storedplanid = localStorage.getItem("selectedplanid");
    console.log("[[", storedplanid);
    const offerPrice = individualPlan.offer_price
      ? parseFloat(individualPlan.offer_price)
      : parseFloat(individualPlan.price);

    const totalPayment = tasks.reduce(
      (acc, curr) => acc + parseFloat(curr.payment),
      0
    );

    const newPayment = parseFloat(taskData.add_payment);

    if (totalPayment + newPayment > offerPrice) {
      showError(
        "Error..!",
        "Total payment exceeds offer price. Cannot add payment."
      );
      setTaskData({ ...taskData, add_payment: "" });
      return;
    }

    const isDuplicateDay = tasks.some((task) => task.days === taskData.days);
    if (isDuplicateDay) {
      const shouldClear = window.confirm("This Day is already exists");
      if (shouldClear) {
        setTaskData({ ...taskData, days: "" });
        setLoading(false);
      }
      return;
      setLoading(false);
    }
    if (!taskData.title) {
      toast.error("Please fill Title");
      return;
    }
    if (!taskData.days) {
      toast.error("Please fill Days");
      return;
    }
    if (!taskData.add_payment) {
      toast.error("Please fill Payment Field");
      return;
    }
    if (!taskData.description) {
      toast.error("Please fill Description");
      return;
    }
    //addnew
    const totalPay = Number(totalpayemntdata) + Number(taskData.add_payment);
    const planSaveStatus =
      Number(totalPay) <
      (individualPlan.offer_price
        ? Number(individualPlan.price)
        : Number(individualPlan.price))
        ? "0"
        : "1";
    setLoading(true);
    const req = {
      task: taskData.title,
      days: taskData.days,
      plan_id: individualPlan.id,
      payment: taskData.add_payment,
      description: taskData.description,
      createdby: userId,
      bussiness_type: selectedBusiness,
      plan_save_status: planSaveStatus,
    };
    callApi("POST", "task_add.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setTaskData({
            title: "",
            days: "",
            add_payment: "",
            description: "",
          });
          const newTask = response.data.responseJson;
          setTasks([...tasks, newTask]);
          setLoading(false);
          gettotalpayment(individualPlan.id);
          taskget(selectedBusiness);
          setIsNewTaskFormOpen(false);
          const price = selectedItem.offer_price
            ? Number(selectedItem.offer_price)
            : Number(selectedItem.price);
          const pendingPayment: any = price - Number(totalpayemntdata);
          setPendingPayment(pendingPayment);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const offerPrice = selectedItem.offer_price
      ? selectedItem.offer_price
      : selectedItem.price;

    const totalPayment = tasks.reduce(
      (acc, curr) => acc + parseFloat(curr.payment),
      0
    );

    const newPayment = parseFloat(taskData.add_payment);
    console.log("wwwwwwwww", totalPayment, newPayment, offerPrice);

    if (totalPayment + newPayment > offerPrice) {
      const shouldClear = window.confirm(
        "Total payment exceeds offer price. Cannot add payment."
      );
      if (shouldClear) {
        setTaskData({ ...taskData, add_payment: "" });
        setLoading(false);
      }
      return;
    }

    const isDuplicateDay = tasks.some((task) => task.days === taskData.days);
    if (isDuplicateDay) {
      const shouldClear = window.confirm("This Day is already exists");
      if (shouldClear) {
        setTaskData({ ...taskData, days: "" });
        setLoading(false);
      }
      return;
      setLoading(false);
    }
    const totalPay = Number(totalpayemntdata) + Number(taskData.add_payment);
    const planSaveStatus =
      Number(totalPay) <
      (individualPlan.offer_price
        ? Number(individualPlan.price)
        : Number(individualPlan.price))
        ? "0"
        : "1";

    const req = {
      createdby: localStorage.getItem("userId"),
      bussiness_type: selectedBusiness,
      plan_id: selectedplan,
      task: taskData.title,
      payment: taskData.add_payment,
      days: taskData.days,
      description: taskData.description,
      plan_save_status: planSaveStatus,
    };
    callApi("POST", "task_add.php", req)
      .then(async (response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          gettotalpayment(individualPlan.id);
          setTaskData({
            title: "",
            add_payment: "",
            description: "",
            days: "",
          });
          taskget(selectedBusiness);
          console.log("gettotalpayment___", individualPlan.total_task_payment);
          const totalTaskPayment =
            (taskData.add_payment ? Number(taskData.add_payment) : 0) +
            (individualPlan.total_task_payment
              ? Number(individualPlan.total_task_payment)
              : 0);
          setTimeout(() => {
            console.log("individualPlan___c", individualPlan);
            console.log(
              "balanceAmt___",
              balanceTaskAmt,
              totalTaskPayment,
              individualPlan.offer_price,
              individualPlan.price,
              totalTaskPayment
            );
            if (
              individualPlan &&
              individualPlan.offer_price != "" &&
              individualPlan.offer_price != "0"
            ) {
              if (
                Number(individualPlan.offer_price) == Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(false);
              } else if (
                Number(individualPlan.offer_price) > Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(true);
                const balanceAmt: any =
                  Number(individualPlan.offer_price) - Number(totalTaskPayment);
                setBalanceTaskAmt(balanceAmt);
                return;
              }
            } else if (
              individualPlan &&
              individualPlan.price != "" &&
              individualPlan.price != "0"
            ) {
              if (Number(individualPlan.price) == Number(totalTaskPayment)) {
                setShowBalanceAmtModal(false);
              } else if (
                Number(individualPlan.price) > Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(true);
                const balanceAmt: any =
                  Number(individualPlan.price) - Number(totalTaskPayment);
                setBalanceTaskAmt(balanceAmt);
                return;
              }
            } else {
              setShowBalanceAmtModal(false);
              console.log("close___>");
              setTaskData(response.data.responseJson);
              setIsTaskModalOpen(false);
              window.location.reload();
            }
          }, 2000);
          await setTimeout(() => {
            const price = individualPlan.offer_price
              ? Number(individualPlan.offer_price)
              : Number(individualPlan.price);
            const pendingPayment: any = price - Number(totalTaskPayment);
            console.log(
              "new____________",
              individualPlan,
              price,
              pendingPayment
            );
            setPendingPayment(pendingPayment);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleEdit = (item: any) => {
    const storedBusiness: any = localStorage.getItem("selectedBusiness");
    gettotalpayment(item.id);
    console.log("data", item);
    setselectedplan(item);
    setSelectedItem({
      ...item,
    });
    taskget(storedBusiness);
    if (item.plan_assign_count == 0) {
      setIsEditModalOpen(true);
      setIsPlanExist(false);
      setIsPlanExistEdit(false);
      setPlanExistUser([]);
    } else {
      setIsPlanExist(true);
      setIsPlanExistEdit(true);
      setPlanExistUser(item.plan_exist_user);
    }
  };
  const copyPlan = () => {
    setIsPlanExist(false);
    setIsPlanExistEdit(false);
    const storedUserId = localStorage.getItem("userId");
    callApi("POST", "plan_copy.php", {
      plan_id: selectedItem.id,
      created_by: storedUserId,
    })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          closeEditModal();
          getmembership(selectedBusiness);
          closePlaneExist();
        } else {
          setLoading(false);
          showError("Failed", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };
  const continueEdit = () => {
    setIsPlanExist(false);
    setIsPlanExistEdit(false);
    setIsEditModalOpen(true);
  };
  const closePlaneExist = () => {
    setIsPlanExist(false);
    setIsPlanExistEdit(false);
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setIsNewTaskFormOpen(false);
  };

  const handleEditTask = (selectedplans: any) => {
    console.log("veeresh", selectedplans.id);
    // closeEditModal();
    setIsTaskModalOpen(false);
    setselectedplan(selectedplans.id);
    taskget(selectedBusiness);
    setIsEditingTask(false);
    handleEditSubmit();
  };

  const handleEditSubmit = () => {
    setLoading(true);
    if (
      parseFloat(selectedItem.offer_price) >= parseFloat(selectedItem.price)
    ) {
      showError("Error", "Offer price must be less than the price.");
      setLoading(false);
      return;
    }
    if (selectedItem.duration < totalday) {
      showError("Error", "Duration cannot be less than last added task day");
      setLoading(false);
      return;
    }
    const validPlanName = /^[a-zA-Z0-9\s-]+$/;

    const validDuration = /^\d+$/; // Only digits allowed
    const validPrice = /^\d+(\.\d{1,2})?$/; // Decimal number with up to 2 decimal places
    const validOfferPrice = /^\d+(\.\d{1,2})?$/;

    // Check validation for each field
    if (!validPlanName.test(selectedItem.plan_name)) {
      showError(
        "Error..!",
        "Plan name can only contain letters, numbers, and spaces."
      );
      setLoading(false);
      return;
    }
    if (!validDuration.test(selectedItem.duration)) {
      showError("Error..!", "Duration must be a valid number.");
      setLoading(false);
      return;
    }
    if (!validPrice.test(selectedItem.price)) {
      showError(
        "Error..!",
        "Price must be a valid number with up to 2 decimal places."
      );
      setLoading(false);
      return;
    }

    const totalPrice = selectedItem.price;
    const offerPrice = selectedItem.offer_price;
    const totalPayment = String(totalpayemntdata);
    console.log("pppp", {
      offerPrice: offerPrice,
      offerPriceType: typeof offerPrice,
      totalPayment: totalPayment,
      totalPaymentType: typeof totalPayment,
      totalPrice: totalPrice,
      totalPriceType: typeof totalPrice,
    });

    // Check if offerPrice is not null or undefined, and use it for comparison if it exists
    if (offerPrice != null && offerPrice !== undefined && offerPrice !== "") {
      if (parseFloat(offerPrice) < parseFloat(totalPayment)) {
        showError("Error", "Offer price cannot be less than Total payment");
        setLoading(false);
        return;
      }
    } else if (parseFloat(totalPrice) < parseFloat(totalPayment)) {
      showError("Error", "Price cannot be less than Total payment");
      setLoading(false);
      return;
    }

    if (selectedItem.imageFile) {
      const allowedFormats = ["jpeg", "jpg", "png", "gif"]; // Add more formats if needed
      const fileExtension = selectedItem.imageFile.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        showError(
          "Error",
          "Unsupported image format. Please upload an image in JPEG, JPG, PNG, or GIF format."
        );
        setLoading(false);
        return;
      }
    }

    if (
      validPlanName.test(selectedItem.plan_name) &&
      validDuration.test(selectedItem.duration) &&
      validPrice.test(selectedItem.price)
    ) {
      const editedPlan = {
        ...selectedItem,
        plan_id: selectedItem.id,
        created_by: userId,
        subplan_type: selectedItem.plan_type,
      };
      if (!editedPlan.imageFile) {
        editedPlan.image = "";
      }

      delete editedPlan.imageFile;
      callApi("POST", "plan_edit.php", editedPlan)
        .then(async (response) => {
          if (response.data.message === "Plan Name Already Exists.") {
            alert(response.data.message);
            setLoading(false);
            return;
          }
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            setLoading(false);
            closeEditModal();
            setIsTaskModalOpen(true);
            await taskget(selectedBusiness);
            await getplanselect(selectedBusiness);
            closeEditModal();
            await getmembership(selectedBusiness);
            const price = selectedItem.offer_price
              ? Number(selectedItem.offer_price)
              : Number(selectedItem.price);
            const pendingPayment: any = price - Number(totalpayemntdata);
            setPendingPayment(pendingPayment);
          }
        })
        .catch((error) => {
          console.error("Error updating product:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const handleeditdraftsubmit = () => {
    setLoading(true);
    if (
      parseFloat(selectedItem.offer_price) >= parseFloat(selectedItem.price)
    ) {
      showError("Error", "Offer price must be less than the price.");
      setLoading(false);
      return;
    }
    const validPlanName = /^[a-zA-Z0-9\s]+$/;
    const validDuration = /^\d+$/; // Only digits allowed
    const validPrice = /^\d+(\.\d{1,2})?$/; // Decimal number with up to 2 decimal places
    const validOfferPrice = /^\d+(\.\d{1,2})?$/;

    // Check validation for each field
    if (!validPlanName.test(selectedItem.plan_name)) {
      showError(
        "Error..!",
        "Plan name can only contain letters, numbers, and spaces."
      );
      return;
    }
    if (!validDuration.test(selectedItem.duration)) {
      showError("Error..!", "Duration must be a valid number.");
      return;
    }
    if (!validPrice.test(selectedItem.price)) {
      showError(
        "Error..!",
        "Price must be a valid number with up to 2 decimal places."
      );
      return;
    }

    if (selectedItem.imageFile) {
      const allowedFormats = ["jpeg", "jpg", "png", "gif"]; // Add more formats if needed
      const fileExtension = selectedItem.imageFile.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        showError(
          "Error",
          "Unsupported image format. Please upload an image in JPEG, JPG, PNG, or GIF format."
        );
        setLoading(false);
        return;
      }
    }

    const editedPlan = {
      ...selectedItem,
      plan_id: selectedItem.id,
      save_status: "0",
    };
    if (!editedPlan.imageFile) {
      editedPlan.image = "";
    }

    delete editedPlan.imageFile;
    callApi("POST", "plan_edit.php", editedPlan)
      .then((response) => {
        if (response.data.message === "Plan Name Already Exists.") {
          alert(response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          closeEditModal();
          getmembership(selectedBusiness);
        }
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };
  const handletaskview = (item: any) => {
    setSelectedItem(item);
    settaskviewmodal(true);

    console.log("lllllllll", item, selectedBusiness);
    taskget(selectedBusiness);
  };

  const handleDelete = (item: any) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      // Proceed with deletion
      const req = {
        plan_id: item.id,
        update_id: localStorage.getItem("userId"),
      };
      callApi("POST", "plan_delete.php", req)
        .then((response) => {
          getmembership(selectedBusiness);
          setPlanExistUser(response.data.response);
          if (response.data.count > 0) {
            setIsPlanExist(true);
          } else {
            setIsPlanExist(false);
            showSuccess("Success..!", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
        });
    } else {
      // Cancel deletion
      console.log("Deletion canceled.");
    }
  };

  const handledeletetask = (item: any) => {
    console.log("taskdelete--", item);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Task?"
    );
    if (isConfirmed) {
      setLoading(true);
      const req = {
        plan_id: item.plan_id,
        task_id: item.id,
        updated_by: userId,
      };
      callApi("POST", "task_delete.php", req)
        .then((response) => {
          if (response.data.success === true) {
            taskget(selectedBusiness);
            showSuccess("Success..!", response.data.message);
            gettotalpayment(individualPlan.id);
            setLoading(false);
            const price = selectedItem.offer_price
              ? Number(selectedItem.offer_price)
              : Number(selectedItem.price);
            const pendingPayment: any = price - Number(totalpayemntdata);
            setPendingPayment(pendingPayment);
          }
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
        });
    } else {
      // Cancel deletion
      console.log("Deletion canceled.");
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const downloadExcel = () => {
    const todayDate: any = new Date();
    const formattedDate =
      todayDate.getDate().toString().padStart(2, "0") -
      (todayDate.getMonth() + 1).toString().padStart(2, "0") -
      todayDate.getFullYear();
    const flattenedData = filteredData.map((file: any, index: any) => {
      return {
        ID: index + 1,
        plan_name: file.plan_name,
        duration: file.duration,
        price: file.price,
        offer_price: file.offer_price,
        plan_type: file.plan_type,
        offer_enddate: file.offer_enddate,
        offer_percentage: file.offer_percentage,
        description: file.description,
        save_status: file.save_status,
      };
    });
    const headers = [
      "ID",
      "plan_name",
      "duration",
      "price",
      "offer_price",
      "plan_type",
      "offer_enddate",
      "offer_percentage",
      "description",
      "save_status",
    ];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    // const fileName = `Files ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, `Membership.xlsx`);
  };
  // Filtered data based on search term
  const filteredData = membershipdata
    ? membershipdata
        .filter((item) =>
          Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
        .filter((item) => {
          if (filterCriteria === "Active") return item.save_status === "Active";
          if (filterCriteria === "draft") return item.save_status === "Draft";

          return true;
        })
    : [];
  const bussinessname = localStorage.getItem("Businessname") || "";

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const rangeEnd = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredData.length
  );
  const totalItems = filteredData.length;
  const emptyObject: any = {};
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="products-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Membership</p>
        <div className="dashboard-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Membership</p>
          <div className="buttons-container">
            <button className="export-button" onClick={downloadExcel}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={exportsvg}
                  alt="Export"
                  style={{ marginRight: "5px", marginLeft: "12px" }}
                />
                <span style={{ color: "purple" }}>Export</span>
              </div>
            </button>
            <button className="add-button1" onClick={openModal}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={plus}
                  alt="Add Product"
                  style={{ marginRight: "5px" }}
                />
                <span style={{ color: "white" }}>Add Membership</span>
              </div>
            </button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          buttontext={"Membership"}
          selecteddata={emptyObject}
        />

        <div className="card-and-search-container">
          {/* Cardmodal */}
          <div className="cardmodal">
            <button
              className={activeButton === "All Products" ? "active" : ""}
              onClick={() => handleButtonClick("All Products")}
            >
              All Plans
            </button>
            <button
              className={activeButton === "Active" ? "active" : ""}
              onClick={() => handleButtonClick("Active")}
            >
              Active
            </button>
            <button
              className={activeButton === "draft" ? "active" : ""}
              onClick={() => handleButtonClick("draft")}
            >
              draft
            </button>
          </div>
          {/* Filter input */}
          <div className="search-field-mem">
            <input
              type="text"
              placeholder="Search Plan.."
              className="search-input-mem"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {/* <div>
            <button className="filter-button">
              <img src={filtersvg} alt="Filter" className="filter-icon" />
              Filter
            </button>
          </div> */}
        </div>

        {/* Table */}
        <div className="table-container">
          <table className="custom-table-mem">
            <thead>
              <tr>
                <th>SNO</th>
                <th>Plan</th>
                <th>Plan Name</th>
                <th>Duration</th>
                <th>offer price</th>
                <th>Price</th>
                <th>Percentage</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length === 0 ? (
                <div>No data available</div>
              ) : (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {rangeStart + index}
                    </td>
                    <td>
                      {" "}
                      <img
                        src={item.image}
                        style={{ width: "50px", height: "50px" }}
                        alt={item.plan_name}
                      />
                    </td>
                    <td> {item.plan_name}</td>
                    <td className="text-with-gradient">{item.duration}</td>
                    <td>{item.offer_price ? item.offer_price : "-"}</td>
                    <td>{item.price}</td>
                    <td>{item.offer_percentage}</td>
                    <td>{item.offer_enddate ? item.offer_enddate : "-"}</td>
                    <td>
                      <img
                        src={view}
                        alt="Edit"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={() => handletaskview(item)}
                      />
                      <img
                        src={editsvg}
                        alt="Edit"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={() => handleEdit(item)}
                      />
                      <img
                        src={deletesvg}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-and-info">
          {/* Showing items information */}
          <div className="items-info">
            Showing {rangeStart}-{rangeEnd} from {totalItems}
          </div>

          {/* Pagination */}
          <div className="pagination">
            <ReactPaginate
              previousLabel={
                <img
                  src={pageleft}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              nextLabel={
                <img
                  src={pageright}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {isPlanExist && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button
                className="close-button"
                onClick={() => closePlaneExist()}
              >
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>
                {!isPlanExistEdit
                  ? "The Plan can't be deleted because it is assigned to the below users."
                  : "The Plan is assigned to the below users, you can able to edit only title and describtion, otherwise click copy plan button to dublicate this plan"}
              </h2>
              <div className="">
                {planExistUser.length > 0 &&
                  planExistUser.map((user: any, index: any) => (
                    <>
                      <p>
                        <span>{index + 1}</span> {user.name}
                      </p>
                    </>
                  ))}
              </div>

              {!isPlanExistEdit ? (
                <>
                  <button
                    type="submit"
                    className="submit-button"
                    onClick={() => setIsPlanExist(false)}
                    disabled={loading}
                  >
                    {loading ? (
                      <Hourglass
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["white", "white"]}
                      />
                    ) : (
                      "Ok"
                    )}
                  </button>
                </>
              ) : (
                <>
                  <div style={{ display: "flex" }}>
                    <button
                      type="submit"
                      className="submit-button"
                      onClick={() => copyPlan()}
                    >
                      Copy Plan
                    </button>
                    <button
                      type="submit"
                      className="submit-button"
                      onClick={() => continueEdit()}
                    >
                      Continue Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {isEditModalOpen && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closeEditModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Membership</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="plan_name">
                      Plan Name:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="plan_name"
                      value={selectedItem.plan_name}
                      onKeyPress={handleKeyPress}
                      // maxLength={50}
                      minLength={1}
                      required
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          plan_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="duration">
                      Duration:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="duration"
                      value={selectedItem.duration}
                      required
                      min={0}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          duration: e.target.value,
                        })
                      }
                      readOnly={
                        selectedItem.plan_assign_count > 0 ? true : false
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="price">
                      Price:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="price"
                      min={0}
                      readOnly={
                        selectedItem.plan_assign_count > 0 ? true : false
                      }
                      value={selectedItem.price}
                      required
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          price: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="offer_price">Offer Price:</label>
                    <input
                      type="number"
                      id="offer_price"
                      readOnly={
                        selectedItem.plan_assign_count > 0 ? true : false
                      }
                      min={0}
                      value={selectedItem.offer_price}
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          offer_price: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="input-row">
                  {selectedItem.offer_price && (
                    <div>
                      <label htmlFor="offer_enddate">Offer End Date:</label>
                      <input
                        type="date"
                        id="offer_enddate"
                        readOnly={
                          selectedItem.plan_assign_count > 0 ? true : false
                        }
                        value={formatDate(selectedItem.offer_enddate)}
                        min={currentDate}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            offer_enddate: formatDate(e.target.value),
                          })
                        }
                      />
                    </div>
                  )}
                  <div>
                    <label htmlFor="description">Description:</label>
                    <textarea
                      id="description"
                      minLength={3}
                      value={selectedItem.description}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          description: e.target.value,
                        })
                      }
                      rows={3}
                      cols={40}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="plantype">
                      Plan Type:<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      style={{
                        width: "106%",
                        height: "80%",
                        borderRadius: "5px",
                      }}
                      id="plantype"
                      value={selectedItem.plan_type}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          plan_type: e.target.value,
                          subplan_type: e.target.value,
                        })
                      }
                      required
                      disabled={
                        selectedItem.plan_assign_count > 0 ? true : false
                      }
                    >
                      <option value="">Select plantype</option>
                      <option value="1">PT</option>
                      <option value="2">Home PT</option>
                      <option value="3">1 Month</option>
                      <option value="4">3 Month</option>
                      <option value="5">6 Month</option>
                      <option value="6">12 Month</option>
                      <option value="7">3 + 3</option>
                      <option value="8">6 + 6</option>
                      <option value="9">1 + 1</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="image">New Image:</label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="image"
                        readOnly={false}
                        style={{ width: "172px" }}
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const validImageTypes = [
                              "image/jpeg",
                              "image/png",
                              "image/gif",
                              "image/webp",
                            ]; // Add any other image types you want to support
                            if (!validImageTypes.includes(file.type)) {
                              alert("Please upload a valid image file.");
                              e.target.value = ""; // Clear the file input field
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              setSelectedItem({
                                ...selectedItem,
                                image: reader.result as string, // Set image URL to data URL
                                imageFile: file,
                                imageformat: file.type.split("/")[1],
                              });
                            };
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <div className="existing-image">
                    <label>Existing Image :</label>
                    {selectedItem.image && (
                      <img
                        src={selectedItem.image}
                        alt="Existing Image"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginLeft: "30%",
                        }} // Adjust dimensions as needed
                      />
                    )}
                  </div>
                </div>
              </div>
              {activeButton !== "draft" && (
                <button
                  className="submit-button1"
                  onClick={() => handleEditTask(selectedItem)}
                  disabled={loading}
                  style={{ marginLeft: "85%" }}
                >
                  {loading ? (
                    <Hourglass
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="hourglass-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={["white", "white"]}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              )}
              {activeButton === "draft" && (
                <div className="button-row">
                  <>
                    <button
                      className="update"
                      onClick={() => handleEditTask(selectedItem)}
                      disabled={loading}
                    >
                      {loading ? (
                        <Hourglass
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="hourglass-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          colors={["white", "white"]}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </>
                </div>
              )}
            </div>
          </div>
        )}
        {isTaskModalOpen && (
          <div className="task-edit-modal">
            <div className="task-edit-modal-content">
              <button className="close-button" onClick={handleClose}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Task</h2>
              <div className="new-card">
                <img
                  src={selectedItem.image}
                  alt="Existing Image"
                  style={{ width: "80px", height: "80px" }} // Adjust dimensions as needed
                />
                <div className="content-right">
                  <div className="row" style={{ fontSize: "14px" }}>
                    <div className="column" style={{ textAlign: "left" }}>
                      <p>
                        <strong>Title:</strong> {selectedItem.plan_name}
                      </p>
                      <p>
                        <strong>Duration:</strong> {selectedItem.duration} Days
                      </p>
                    </div>
                    <div className="column" style={{ textAlign: "left" }}>
                      <p>
                        <strong>Offer Price:</strong> {selectedItem.offer_price}
                      </p>
                      <p
                        className={
                          selectedItem.offer_price ? "strike-through" : ""
                        }
                      >
                        <strong>Price:</strong> {selectedItem.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {pendingPayment != "" ? (
                <p>
                  (Pending Payment -{" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.99999 1V0H13V1H8.32866C9.22627 1.72635 9.83888 2.791 9.97253 4H13V5H9.97253C9.72381 7.24998 7.81627 9 5.49999 9H3.85162L9.82539 14.1204L9.1746 14.8796L2.1746 8.87963C2.01573 8.74346 1.95846 8.52277 2.03105 8.32653C2.10365 8.13029 2.29076 8 2.49999 8H5.49999C7.26323 8 8.72193 6.69615 8.96455 5H1.99999V4H8.96455C8.72193 2.30385 7.26323 1 5.49999 1H1.99999Z"
                      fill="#000000"
                    />
                  </svg>
                  {pendingPayment})
                </p>
              ) : (
                <p></p>
              )}
              {selectedItem && selectedItem.plan_assign_count == 0 && (
                <>
                  <button
                    type="submit"
                    className="submit-button"
                    onClick={handleAddNewTask}
                    style={{ marginBottom: "20px" }}
                  >
                    New Task
                  </button>
                </>
              )}

              {isNewTaskFormOpen && (
                <>
                  <h4>Task</h4>
                  <div className="new-task-card">
                    <form onSubmit={handleSubmit}>
                      <div className="task-columns">
                        <div className="task-column">
                          <input
                            id="title"
                            type="text"
                            placeholder="Title*"
                            className="outlined-input"
                            value={taskData.title}
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            minLength={0}
                            maxLength={30}
                            required
                          />
                          <input
                            id="days"
                            type="text"
                            placeholder="Day *"
                            className="outlined-input"
                            value={taskData.days}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            required
                            min="0"
                          />
                          <input
                            id="add_payment"
                            type="text"
                            value={taskData.add_payment}
                            onKeyDown={handleKeyDown}
                            minLength={0}
                            placeholder="Add Payment"
                            className="outlined-input"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="task-column">
                          <textarea
                            id="description"
                            placeholder="Description"
                            className="outlined-input"
                            onChange={handleChange}
                            value={taskData.description}
                            required
                          />
                        </div>
                      </div>
                      <div className="button-row">
                        <button
                          type="button"
                          className="save-button"
                          onClick={handleAddNew}
                          disabled={!isValidDays || loading}
                        >
                          Add New
                        </button>
                        <button
                          type="submit"
                          className="submit-button"
                          disabled={loading}
                        >
                          {loading ? (
                            <Hourglass
                              visible={true}
                              height="20"
                              width="20"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={["white", "white"]}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}

              {tasks.map(
                (taskItem, index) =>
                  // Add a conditional check to ensure taskItem is not undefined or null
                  taskItem && (
                    <div key={index} className="card-above-task">
                      <div className="row">
                        <div className="column" style={{ textAlign: "left" }}>
                          <h3>Day</h3>
                          <p>{taskItem.days}</p>
                        </div>

                        <div className="column">
                          <h3>{taskItem.task}</h3>
                          <p className="task-description">
                            {taskItem.description}
                          </p>
                        </div>

                        <div className="column">
                          {taskItem.payment !== "0" && (
                            <button className="green-bg">
                              {taskItem.payment}
                            </button>
                          )}
                        </div>

                        <img
                          src={editsvg}
                          alt="Edit"
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => handletaskedit(taskItem)}
                        />
                        {selectedItem &&
                          selectedItem.plan_assign_count == 0 && (
                            <>
                              <img
                                src={deletesvg}
                                alt="Delete"
                                style={{ cursor: "pointer" }}
                                onClick={() => handledeletetask(taskItem)}
                              />
                            </>
                          )}
                      </div>
                    </div>
                  )
              )}
              {isEditingTask && editedTaskData && (
                <div ref={divRef}>
                  <div className="input-row">
                    <div>
                      <label htmlFor="addno">
                        Task:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        id="title"
                        type="text"
                        value={editedTaskData.task}
                        onKeyDown={handleKeyPress}
                        maxLength={30}
                        onChange={(e) =>
                          setEditedTaskData((prev: any) => ({
                            ...prev!,
                            task: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="addno">Day:</label>
                      <input
                        id="days"
                        type="text"
                        onKeyDown={handleKeyDown}
                        minLength={0}
                        maxLength={15}
                        value={editedTaskData.days}
                        onChange={(e) =>
                          setEditedTaskData((prev: any) => ({
                            ...prev!,
                            days: e.target.value,
                          }))
                        }
                        readOnly={
                          selectedItem.plan_assign_count > 0 ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div>
                      <label htmlFor="addno">Payment:</label>
                      <input
                        id="add_payment"
                        type="text"
                        onKeyDown={handleKeyDown}
                        minLength={0}
                        readOnly={
                          selectedItem.plan_assign_count > 0 ? true : false
                        }
                        value={editedTaskData.payment}
                        onChange={(e) =>
                          setEditedTaskData((prev: any) => ({
                            ...prev!,
                            payment: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="addno">
                        Description:<span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        id="description"
                        value={editedTaskData.description}
                        onChange={(e) =>
                          setEditedTaskData((prev: any) => ({
                            ...prev!,
                            description: e.target.value,
                          }))
                        }
                        required
                        rows={5}
                        cols={40}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="submit-button"
                    onClick={handleEditTaskSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <Hourglass
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["white", "white"]}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {taskviewmodal && (
          <div className="task-edit-modal-1">
            <div
              className="task-edit-modal-content"
              style={{ padding: "20px" }}
            >
              <button
                className="close-button"
                onClick={() => settaskviewmodal(false)}
              >
                <img src={closeIcon} alt="Close" />
              </button>
              <div className="new-card">
                <img
                  src={selectedItem.image}
                  alt="Existing Image"
                  style={{ width: "80px", height: "80px" }}
                />
                <div className="content-right">
                  <div className="row" style={{ fontSize: "14px" }}>
                    <div className="column" style={{ textAlign: "left" }}>
                      <p>
                        <strong>Title:</strong> {selectedItem.plan_name}
                      </p>
                      <p>
                        <strong>Duration:</strong> {selectedItem.duration} Days
                      </p>
                    </div>

                    <div className="column" style={{ textAlign: "left" }}>
                      <p>
                        <strong>Offer Price:</strong> {selectedItem.offer_price}
                      </p>
                      <p
                        className={
                          selectedItem.offer_price ? "strike-through" : ""
                        }
                      >
                        <strong>Price:</strong> {selectedItem.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {tasks.map(
                (taskItem, index) =>
                  taskItem && (
                    <div key={index} className="card-above-task-1">
                      <div className="row">
                        <div
                          className="column "
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            alignContent: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            flexDirection: "column",
                          }}
                        >
                          <h3 style={{ marginBottom: "0" }}>Day</h3>
                          <p>{taskItem.days}</p>
                        </div>

                        <div className="column">
                          <h3>{taskItem.task}</h3>
                          <p className="task-description">
                            {taskItem.description}
                          </p>
                        </div>

                        <div
                          className="column"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            alignContent: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            flexDirection: "column",
                          }}
                        >
                          {taskItem.payment !== "0" && (
                            <button className="green-bg">
                              {taskItem.payment}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
      <BalanceAmtModal
        showBalanceAmtModal={showBalanceAmtModal}
        hideBalanceAmtModal={hideBalanceAmtModal}
        BalanceSaveDraft={BalanceSaveDraft}
        balanceTaskAmt={balanceTaskAmt}
      />
    </>
  );
};

export default Membership;
