import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import "../../components/pages/style/subscribers.css";
import rightarrow from "../../assets/arrowright.svg";
import plus from "../../assets/Header/plus.svg";
import deleteicon from "../../assets/user/delete.svg";
import exportsvg from "../../assets/product/export.svg";
import notintrested from "../../assets/leads/notinterested.svg";
import waiting from "../../assets/leads/waiting.svg";
import confirm from "../../assets/leads/confirm.svg";
import logo from "../../assets/subscriber/logo.svg";
import active from "../../assets/subscriber/active.svg";
import inactive from "../../assets/subscriber/inactive.svg";
import dnd from "../../assets/dnd.svg";
import dot from "../../assets/subscriber/dot.svg";
import threedot from "../../assets/three-dot.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import ReactPaginate from "react-paginate";
import line from "../../assets/user/line.svg";
import Avatar from "../../assets/user/avatar.svg";
import line2 from "../../assets/user/line2.svg";
import mail from "../../assets/user/mail.svg";
import phone from "../../assets/user/phone.svg";
import location from "../../assets/user/location.svg";
import calender from "../../assets/subscriber/calender.svg";
import number from "../../assets/subscriber/number.svg";
import blood from "../../assets/subscriber/blood.svg";
import height from "../../assets/height.svg";
import weight from "../../assets/weight.svg";
import bmi from "../../assets/bmi.svg";
import history from "../../assets/medical-history.svg";
import gender from "../../assets/subscriber/gender.svg";
import right from "../../assets/user/rightarrow.svg";
import editsvg from "../../assets/product/edit.svg";
import outofstocksvg from "../../assets/product/outofstock.svg";
import grp from "../../assets/subscriber/grp.svg";
import time from "../../assets/subscriber/time.svg";
import note from "../../assets/subscriber/note.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { callApi } from "../utils/api";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rightarrow1 from "../../assets/sidebar/menu-right-arrow.svg";
import groupimg from "../../assets/subscriber/groupimg.svg";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import Select from "react-select";
import sendNotification from "../utils/FcmService";

interface subscriber {
  duration: string;
  offer_enddate: string;
  price: string;
  plan_type: string;
  plan_name: string;
  id: number;
  name: string;
  user_name: string;
  email: string;
  phone_no: string;
  active_status: string;
  balance: number;
  blood_group: string;
  image: string;
  plan: any;
  profile: string;
  addmission_no: string;
  address: string;
  expiry_date: string;
  height: string;
  weight: string;
  bmi: string;
  medical_history: string;
  report: string;
}
interface group {
  id: number;
  name: string;
  session: string;
  batch: string;
  bussiness_type: string;
  active_status: string;
  created_dt: string;
  image: string;
}

interface user {
  id: number;
  user_name: string;
  full_name: string;
  email: string;
  phone_no: string;
  address: string;
  gender: string;
  occupation: string;
  admission_no: string;
  doj: string;
  blood_group: string;
  dob: string;
  active_status: string;
  image: string;
  password: string;
  height: string;
  weight: string;
  bmi: string;
  medical_history: string;
  report: string;
}

interface Product {
  id: string;
  product_name: string;
  purchase_by: string;
  qty: string;
  paid_amount: string;
  date: string;
  size: string;
  description: string;
  image: string;
  status: string;
}

interface ListItem {
  id: string;
  group_id: string;
  subscriber: string;
  phoneno: string;
  balace: string;
  bussiness_type: string;
  plan_expiry: string;
  active_status: string;
  created_dt: string;
  created_by: string | null;
  updated_by: string | null;
}

interface upcoming {
  id: number;
  user_idfk: string;
  plan_idfk: string;
  task_idfk: string;
  partially_paid: string;
  user_name: string;
  user_status: string;
  user_number: string;
  plan_name: string;
  bussiness_type: string;
  payment_date: string;
  payment_amt: string;
  task_name: string;
  task_descriprtion: string;
}

interface attendance {
  id: number;
  user_name: string;
  user_id: string;
  bussiness_type: string;
  phone_no: string;
  image: string;
  plan_expiry: string;
  attendance_status: number;
  balance: string;
  active_status: string;
}

interface member {
  id: number;
  plan_name: string;
  duration: string;
  price: string;
  offer_price: string;
  plan_type: string;
  offer_enddate: string;
  offer_percentage: number;
  description: string;
  save_status: string;
  bussiness_type: string;
  image: string;
  plan_id: string[];
}

interface task {
  task: string;
  days: string;
  payment: string;
  description: string;
  plan_id: string[];
  payment_amt: string;
}

interface leads {
  name: string;
  phone_no: string;
  email: string;
  status: string;
  bussiness_type: string;
}

const Subscribers: React.FC = () => {
  const currentDate3 = new Date();
  const year = currentDate3.getFullYear();
  const [loading, setLoading] = useState(false);
  const [leadmodal, setleadmodal] = useState(false);
  const [editingGroup, setEditingGroup] = useState(false);
  const month = (currentDate3.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate3.getDate().toString().padStart(2, "0");
  const initialDate = `${year}-${month}-${day}`;
  const [groupName, setGroupName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [activeButton, setActiveButton] = useState("All Status");
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activesubscribeButtonName") || "All Status";
  });
  const [activemodalButton, setActivemodalButton] = useState("");
  const [presentcount, setpresentcount] = useState("");
  const [absentcount, setabsentcount] = useState("");
  const [groupid, setgroupcardid] = useState("");
  const [rotation, setRotation] = useState(0);
  const [unpaidrotation, setUnpaidRotation] = useState(0);
  const [completedrotation, setcompletedrotation] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Current page starts from 0
  const [itemsPerPage] = useState(15);
  const [itemsPerPageatt] = useState(9);
  const [showSecondColumn, setShowSecondColumn] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState({ plan: "" });
  const [modalContent, setModalContent] = useState("Info");
  const [isMultiSelectFocused, setIsMultiSelectFocused] = useState(false);
  const [membershipdata, setmembershipdata] = useState<member[]>([]);
  const [attendancecontent, setattendancecontent] = useState("1");
  const [showgroup, setShowgroup] = useState(false);
  const [showattendence, setShowAttendance] = useState(false);
  const [showlead, setShowlead] = useState(false);
  const [showpt, setShowpt] = useState(false);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [showgroupcolumn, setshowgroupcolumn] = useState(false);
  const [showptcolumn, setshowptcolumn] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [cardData, setcardData] = useState<subscriber[]>([]);
  const [cardDatapt, setcardDatapt] = useState<subscriber[]>([]);
  const [plandataa, setplandata] = useState<subscriber[]>([]);
  const [exsubscribers, setexsubscribers] = useState<subscriber[]>([]);
  const [datagroup, setdatagroup] = useState<group[]>([]);
  const [userdata, setuserdata] = useState<user[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [groupmem, setGroupMem] = useState<group[]>([]);
  const [listitem, setlistitem] = useState<ListItem[]>([]);
  const [planlist, setplanlist] = useState<string>("");
  const [upcomingdata, setFinupcomingData] = useState<upcoming[]>([]);
  const [unpaidpay, setunpaidpayment] = useState<upcoming[]>([]);
  const [fincompleted, setfincompleted] = useState<upcoming[]>([]);
  const [attendancelist, setattendancelist] = useState<attendance[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(""); // Selected payment mode
  const [partialPaymentAmount, setPartialPaymentAmount] = useState("");
  const [partialPaymentDate, setPartialPaymentDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [absentIds, setAbsentIds] = useState([]);
  const [presentids, setpresentids] = useState([]);
  const [tasks, setTasks] = useState<task[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [selectedlead, setselectedlead] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isThreeDotModalOpen, setIsThreeDotModalOpen] = useState(false);
  const [leadsdata, setleadsdata] = useState<leads[]>([]);
  const [selectedPayment, setSelectedPayment] = useState({
    id: "",
    user_idfk: "",
    plan_idfk: "",
    task_idfk: "",
    partially_paid: "",
    user_name: "",
    user_status: "",
    user_number: "",
    plan_name: "",
    bussiness_type: "",
    payment_date: "",
    payment_amt: "",
    task_name: "",
    task_descriprtion: "",
  });

  useEffect(() => {
    console.log("sub daata", cardData);
  }, [cardData]);
  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      getsubscribers(storedBusiness);
      getgroup(storedBusiness);
      getmembership(storedBusiness);
      taskget(storedBusiness);
    }
    getleads();
    getsubscriberspt();
    const storedname = localStorage.getItem("selectedname");
    if (storedname) {
      setSearchTerm(storedname); // Set the searchTerm state with storedName
    }
    localStorage.removeItem("selectedname");

    const getCurrentDate = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    console.log("jan jan", selectedDate);
    getattendance(selectedDate, storedBusiness);
    const activeSubscribeBtn: any = sessionStorage.getItem(
      "activesubscribeButtonName"
    );
    setActiveButton(activeSubscribeBtn);
    handleButtonClick(activeSubscribeBtn);
  }, []);

  const taskget = (businessType: string) => {
    let storedplanid = localStorage.getItem("selectedplanid");
    console.log("id", storedplanid);

    const req = {
      bussiness_type: businessType,
      plan_id: storedplanid,
    };
    callApi("POST", "individual_plan_select.php", req)
      .then((response) => {})
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getleads = () => {
    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "lead_select.php", req)
      .then((response) => {
        setleadsdata(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const closegroupedit = () => {
    setEditingGroup(false);
  };
  const opengroupedit = (cardgroup: any) => {
    setEditingGroup(true);
    const groupdata = cardgroup.find((card: any) => card.id === selectedCardId);
    setSelectedItem(groupdata);
    console.log("groupdata", groupdata);
  };
  const getmembership = (businessType: string) => {
    setLoading(true);

    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "membership_select.php", req)
      .then((response) => {
        console.log(" Response:", response.data.responseJson);
        setmembershipdata(response.data.responseJson);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleDecimal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = event;
    const { value } = currentTarget;

    if (
      (!/^[\dA-Za-z]$/.test(key) &&
        key !== "Backspace" &&
        key !== "Delete" &&
        key !== ".") ||
      (key === "." && value.includes("."))
    ) {
      event.preventDefault();
    }
  };

  const getuser = (card: any) => {
    const req = {
      id: card,
    };
    callApi("POST", "user_info.php", req)
      .then((response) => {
        setuserdata(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const finupcoming = (card: any, plan: any) => {
    const req = {
      user_id: card,
      bussiness_type: selectedBusiness,
      plan_id: plan,
    };
    callApi("POST", "finance_single_user.php", req)
      .then((response) => {
        if (response.data && response.data.responseJson) {
          const payments = response.data.responseJson;
          const currentDate = new Date();
          const upcomingPayments = payments.filter((payment: any) => {
            const paymentDate = new Date(payment.payment_date);
            return paymentDate > currentDate;
          });
          const unpaidPayments = payments.filter((payment: any) => {
            const paymentDate = new Date(payment.payment_date);
            return paymentDate <= currentDate;
          });
          setFinupcomingData(upcomingPayments);
          setunpaidpayment(unpaidPayments);
        } else {
          console.error("Invalid API response:", response);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getfincomplete = (card: any, plan: any) => {
    const req = {
      user_id: card,
      bussiness_type: selectedBusiness,
      plan_id: plan,
    };
    callApi("POST", "finance_single_user_complete.php", req)
      .then((response) => {
        setfincompleted(response.data.responseJson);
        console.log("xxx", response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getmember = (card: any, plan: any) => {
    const planIds: string[] = plan.split(",");

    const req = {
      user_id: card,
      bussiness_type: selectedBusiness,
      plan_id: planIds,
    };
    callApi("POST", "customer_plan_select.php", req)
      .then((response) => {
        if (response.data.success === true) {
          setTasks(response.data.responseJson[0].task);
          const plans = response.data.responseJson;

          setplandata(plans);
        } else {
          setTasks([]);
          setplandata([]);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getattendance = (selecteddate: any, stored: any) => {
    setLoading(true);

    const req = {
      bussiness_type: stored,
      date: selecteddate,
    };
    callApi("POST", "attendance_list.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          setLoading(false);

          console.log("No attendance data available");
          // Handle the case when no data is found, e.g., display a message to the user
        } else {
          setattendancelist(responseData);
          setpresentcount(response.data.Present_Count);
          setabsentcount(response.data.Absent_Count);
          const absentIds = responseData
            .filter((entry: any) => entry.attendance_status === 0)
            .map((entry: any) => entry.id);
          setAbsentIds(absentIds);
          const presentids = responseData
            .filter((entry: any) => entry.attendance_status === 1)
            .map((entry: any) => entry.id);
          setpresentids(presentids);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleKeyDown = (event: any) => {
    // Allow only numeric characters (0-9), backspace, and delete
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const getpurchase = (card: any) => {
    const req = {
      user_id: card,
      bussiness_type: selectedBusiness,
    };
    callApi("POST", "purchase_history.php", req)
      .then((response) => {
        setProducts(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getgrplist = (card: any) => {
    setLoading(true);

    const req = {
      group_id: card,
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "group_member.php", req)
      .then((response) => {
        setGroupMem([response.data.responseJson.group]);
        setlistitem(response.data.responseJson.list);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getsubscribers = (businessType: string) => {
    setLoading(true);
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "get_all_subscribers.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
          setLoading(false);
        } else {
          setcardData(responseData);
          const plans = responseData.map((subscriber: any) => subscriber.plan);
          setplanlist(plans);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getsubscriberspt = () => {
    setLoading(true);

    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "get_all_pt_subscriber.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
          setLoading(false);
        } else {
          setcardDatapt(responseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getexsubscribers = (cardid: any, businessType: string) => {
    setLoading(true);

    const req = {
      group_id: cardid,
      bussiness_type: businessType,
    };
    callApi("POST", "exiting_members.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
          setLoading(false);
        } else {
          setexsubscribers(responseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getgroup = (businessType: string) => {
    setLoading(true);

    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "group_select.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        if (responseData.length === 0) {
          console.log("No data available");
          setLoading(false);
        } else {
          setdatagroup(responseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleEditGroup = (group: any) => {
    setEditingGroup(group);
    setGroupName(group.name);
  };

  const handleGroupNameChange = (event: any) => {
    setGroupName(event.target.value);
  };
  // Add similar functions for other input fields

  // Function to handle submitting the edited group data
  const handleSubmitEdit = () => {
    setEditingGroup(false);
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };
  const validateEmail = (email: any) => {
    const regex = /^[^\s@]+@[^\s@]+\.(com|in)$/;
    return regex.test(email);
  };
  const handleBlur = (event: any) => {
    const { id, value } = event.target;
    if (id === "email" && !validateEmail(value)) {
      alert("Please enter a valid email address.");
      // Optionally, you can clear the input or display an error message.
      // Example: setFormData({ ...formData, [id]: '' });
    }
  };

  const closeEditModal = () => {
    setleadmodal(false);
  };

  const handlefullpaymentSubmit = () => {
    setLoading(true);
    const storedUserId = localStorage.getItem("userId");

    const paymentDate = new Date(selectedPayment.payment_date);
    const day = paymentDate.getDate().toString().padStart(2, "0");
    const month = (paymentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = paymentDate.getFullYear();

    const formattedPaymentDate = `${day}-${month}-${year}`;

    const req = {
      id: selectedPayment.id,
      pay_status: selectedOption,
      user_idfk: selectedPayment.user_idfk,
      plan_idfk: selectedPayment.plan_idfk,
      task_idfk: selectedPayment.task_idfk,
      partially_paid: selectedPayment.partially_paid,
      user_name: selectedPayment.user_name,
      user_status: selectedPayment.user_status,
      user_number: selectedPayment.user_number,
      plan_name: selectedPayment.plan_name,
      bussiness_type: localStorage.getItem("selectedBusiness"),
      payment_date: formattedPaymentDate,
      paid_amt: selectedPayment.payment_amt,
      task_name: selectedPayment.task_name,
      task_descriprtion: selectedPayment.task_descriprtion,
      updated_by: localStorage.getItem("userId"),
      pay_mode: selectedPaymentMode,
    };
    callApi("POST", "collect_payment.php", req)
      .then((response) => {
        handleCloseModal();
        showSuccess("Success..!", response.data.message);
        setLoading(false);
        getnotification(selectedPayment, selectedPayment.user_status);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const handlepartialpaymentsubmit = () => {
    setLoading(true);
    const currentPaymentAmt = parseFloat(selectedPayment.payment_amt);

    const updatedPaymentAmt =
      currentPaymentAmt - parseFloat(partialPaymentAmount);

    const paymentDate = new Date(partialPaymentDate);
    const day = paymentDate.getDate().toString().padStart(2, "0");
    const month = (paymentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = paymentDate.getFullYear();

    // Construct the formatted date string
    const formattedPaymentDate = `${day}-${month}-${year}`;

    const req = {
      id: selectedPayment.id,
      pay_status: selectedOption,
      pay_mode: selectedPaymentMode,
      user_idfk: selectedPayment.user_idfk,
      plan_idfk: selectedPayment.plan_idfk,
      task_idfk: selectedPayment.task_idfk,
      partially_paid: selectedPayment.partially_paid,
      user_name: selectedPayment.user_name,
      user_status: selectedPayment.user_status,
      user_number: selectedPayment.user_number,
      plan_name: selectedPayment.plan_name,
      bussiness_type: localStorage.getItem("selectedBusiness"),
      payment_date: formattedPaymentDate,
      paid_amt: partialPaymentAmount,
      task_name: selectedPayment.task_name,
      task_descriprtion: selectedPayment.task_descriprtion,
      balance_amt: updatedPaymentAmt,
      updated_by: localStorage.getItem("userId"),
    };
    callApi("POST", "collect_payment.php", req)
      .then((response) => {
        handleCloseModal();
        showSuccess("Success..!", response.data.message);
        getmember(selectedCardId, selectedPayment.user_idfk);
        setLoading(false);
        getnotification(selectedPayment, selectedPayment.user_status);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getnotification = (formdata: any, subids: any) => {
    console.log("vvvvvvvvvvv", subids);
    const userIdArray = Array.isArray(formdata.user_idfk)
      ? formdata.user_idfk
      : [formdata.user_idfk];

    const req = {
      id: userIdArray,
      status: subids,
    };
    callApi("POST", "notification.php", req)
      .then((response) => {
        console.log("e3434343f''''''''''''", response.data);
        if (response.data.success === true) {
          let authoritys_token = response.data.responseJson.token;
          for (let i = 0; i < authoritys_token.length; i++) {
            console.log("authoritys_token", authoritys_token[i]);
            sendNotification(
              authoritys_token[i],
              formdata.plan_name,
              formdata.paid_amt
            );
          }
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  function formatDate(dateString: any) {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  const handleDotIconClick = (userId: any) => {
    // Display confirmation dialog

    const confirmed = window.confirm(
      "Are you sure you want to delete this user from the group?"
    );

    // If user confirms deletion
    if (confirmed) {
      setLoading(true);

      console.log("Clicked dot icon for user ID:", userId);
      const req = {
        group_list_id: userId.id,
      };
      callApi("POST", "group_user_delete.php", req)
        .then((response) => {
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            getgrplist(groupid);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    } else {
      // If user cancels deletion
      console.log("Deletion cancelled by user.");
    }
  };

  const handleDateChange = (date: any) => {
    const selectedDate = new Date(date);

    // Extract year, month, and day components
    const year = selectedDate.getFullYear();
    // January is 0 in JavaScript, so add 1 to get the correct month
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); // Pad single-digit months with leading zero
    const day = selectedDate.getDate().toString().padStart(2, "0"); // Pad single-digit days with leading zero

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    setSelectedDate(date);
    localStorage.setItem(formattedDate, "selecteddate");
    getattendance(formattedDate, selectedBusiness);
  };
  const openModal = (selecteditem: any) => {
    setselectedlead(selecteditem);
    setIsModalOpen(true);
    closeEditModal();
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const editsubmit = () => {
    setLoading(true);
    const edited = {
      ...selectedItem,
      customer_id: selectedItem.id,
    };

    if (!selectedItem.imageFile) {
      edited.image = "";
    }

    callApi("POST", "edit_user.php", edited)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          closeThreeDotModal();
          getsubscribers(selectedBusiness);
        }
      })
      .catch((error) => {
        console.error("Error updating banner:", error);
      });
  };

  const handleSearch = (term: string) => {
    console.log(".....", term);
    setSearchTerm(term);
    setCurrentPage(0);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const handleCollectButtonClick = (payment: any) => {
    setSelectedPayment(payment);
    setShowPaymentModal(true);
    console.log("Clicked collect button for payment:", payment);
  };

  const handleMultiSelectChange = (selectedsub: any) => {
    const subscribers = selectedsub.map((subscriber: any) => ({
      subscriber_id: subscriber.value,
    }));

    console.log("selected options", subscribers);
    setSelectedItem({
      ...selectedItem,
      subscribers,
    });
    // Handle selected options
  };

  const handlegroupeditsubmit = () => {
    setLoading(true);

    const storedUserId = localStorage.getItem("userId");
    const { image, ...selectedItemWithoutImage } = selectedItem;
    const req = {
      ...selectedItemWithoutImage,
      created_by: storedUserId,
      group_id: selectedItem.id,
      group_name: selectedItem.name,
    };
    callApi("POST", "group_edit.php", req)
      .then((response) => {
        handleCloseModal();
        showSuccess("Success..!", response.data.message);
        getgroup(selectedBusiness);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
    setEditingGroup(false);
  };

  const handlenotinterested = (item: any) => {
    setLoading(true);
    const storedUserId = localStorage.getItem("userId");
    const req = {
      id: selectedItem.id,
      status: "1",
      bussiness_type: localStorage.getItem("selectedBusiness"),
      updated_by: storedUserId,
    };
    callApi("POST", "lead_not_interest.php", req)
      .then((response) => {
        handleCloseModal();
        getleads();
        setLoading(false);
        closeEditModal();
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
    setEditingGroup(false);
  };

  // Function to handle modal close event
  const handleCloseModal = () => {
    setShowPaymentModal(false);
  };
  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedOption(event.target.value);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "50%",
      height: "3rem",
      backgroundColor: "#f0f0f0",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "50%",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "lightblue", // Set your desired background color for selected values
      maxWidth: "90%", // Set maximum width to ensure it doesn't overflow
      overflow: "hidden", // Hide any overflow content
      whiteSpace: "nowrap", // Prevent wrapping of content
      textOverflow: "ellipsis", // Show ellipsis for truncated content
    }),
  };

  const handleleadEdit = (item: any) => {
    setSelectedItem({
      ...item,
    });
    setleadmodal(true);
  };

  const handleButtonClick = (buttonName: string) => {
    sessionStorage.setItem("activesubscribeButtonName", buttonName);
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);

    if (buttonName === "Group") {
      setShowgroup(true);
      setShowAttendance(false);
      setShowlead(false);
      setShowSecondColumn(false);
      setShowpt(false);
    } else if (buttonName === "PT") {
      setShowpt(true);
      setShowAttendance(false);
      setShowgroup(false);
      setShowlead(false);
      setShowSecondColumn(false);
    } else if (buttonName === "Attendence") {
      setShowAttendance(true);
      setShowgroup(false);
      setShowlead(false);
      setShowSecondColumn(false);
      setShowpt(false);
    } else if (buttonName === "Leads") {
      setShowAttendance(false);
      setShowgroup(false);
      setShowlead(true);
      setShowSecondColumn(false);
      setShowpt(false);
    } else {
      setShowgroup(false);
      setShowAttendance(false);
      setShowlead(false);
      setShowSecondColumn(false);
      setShowpt(false);
    }
  };
  const handleThreeDotClick1 = (card: any) => {
    if (card) {
      if (card.attendance_status === 1) {
        const confirmAbsent = window.confirm(
          `Do you want mark as absent ${card.user_name} ?`
        );
        if (confirmAbsent) {
          setLoading(true);

          const updatedAbsentIds = [...absentIds, card.id];
          const storedUserId = localStorage.getItem("userId");
          const updatedPresentIds = presentids.filter((id) => id !== card.id);
          const req = {
            present_id: updatedPresentIds,
            absent_id: updatedAbsentIds,
            bussiness_type: selectedBusiness,
            marked_by: storedUserId,
          };

          callApi("POST", "mark_attendance.php", req)
            .then((response) => {
              const responseData = response.data.responseJson;
              getattendance(selectedDate, selectedBusiness);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Network error:", error);
            });
        }
      } else if (card.attendance_status === 0) {
        const confirmPresent = window.confirm(
          `Do you want to mark present ${card.user_name} ?`
        );
        if (confirmPresent) {
          setLoading(true);

          const updatedPresentIds = [...presentids, card.id];
          const storedUserId = localStorage.getItem("userId");
          const updatedAbsentIds = absentIds.filter((id) => id !== card.id);

          const req = {
            present_id: updatedPresentIds,
            absent_id: updatedAbsentIds,
            bussiness_type: selectedBusiness,
            marked_by: storedUserId,
          };

          callApi("POST", "mark_attendance.php", req)
            .then((response) => {
              const responseData = response.data.responseJson;
              getattendance(selectedDate, selectedBusiness);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Network error:", error);
            });
        }
      } else {
        alert(`${card.user_name} is already marked.`);
        setLoading(false);
      }
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleThreeDotClick = (card: any) => {
    console.log("three dot", card);
    setIsThreeDotModalOpen(true);
    setSelectedItem(card);
    setShowSecondColumn(false);
  };
  const closeThreeDotModal = () => {
    setIsThreeDotModalOpen(false);
  };
  const handleCardClick = (card: any) => {
    setSelectedCardId(card.id);
    setSelectedUserInfo(card);
    setShowSecondColumn(true);
    setshowgroupcolumn(false);
    setshowptcolumn(false);
    if (activeButton === "PT") {
      setshowptcolumn(true);
      setShowSecondColumn(false);
    }
    setSelectedCardId(card.id === selectedCardId ? null : card.id);
    getuser(card.id);
    finupcoming(card.id, card.plan);
    getfincomplete(card.id, card.plan);
    getpurchase(card.id);
    getmember(card.id, card.plan);
    setModalContent("Info");
    setActivemodalButton("Info");
    if (selectedCardId === card.id) {
      setShowSecondColumn(false);
      setshowptcolumn(false);
    }
  };

  const handlegroupCardClick = (card: any) => {
    setSelectedCardId(card.id);
    setShowSecondColumn(false);
    setshowptcolumn(false);
    setshowgroupcolumn(true);
    setSelectedCardId(card.id === selectedCardId ? null : card.id);
    getgrplist(card.id);
    setModalContent("grpInfo");
    setgroupcardid(card.id);
    getexsubscribers(card.id, selectedBusiness);
    if (selectedCardId === card.id) {
      setshowgroupcolumn(false);
    }
  };

  const handlemodalButtonClick = (buttonName: string) => {
    setActivemodalButton(buttonName);
    if (buttonName === "Info") {
      setModalContent("Info");
    } else if (buttonName === "finance") {
      setModalContent("finance");
    } else if (buttonName === "membership") {
      setModalContent("membership");
    } else if (buttonName === "products") {
      setModalContent("products");
    } else if (buttonName === "Members") {
      setModalContent("Members");
    } else if (buttonName === "grpInfo") {
      setModalContent("grpInfo");
    }
  };
  const handlemodalAttendanceClick = (buttonName: string) => {
    if (buttonName === "1") {
      setattendancecontent("1");
    } else if (buttonName === "0") {
      setattendancecontent("0");
    }
  };
  const handleCloseButtonClick = () => {
    setShowSecondColumn(false);
    setshowgroupcolumn(false);
    setshowptcolumn(false);
  };
  const handleAccordionClick = (sectionNumber: number) => {
    if (sectionNumber === 1) {
      setRotation(rotation === 90 ? 0 : 90);
    }
    if (sectionNumber === 2) {
      setUnpaidRotation(unpaidrotation === 90 ? 0 : 90);
    }
    if (sectionNumber === 3) {
      setcompletedrotation(completedrotation === 90 ? 0 : 90);
    }
    const accordionContent = document.getElementById(
      `accordion-content-${sectionNumber}`
    );
    accordionContent?.classList.toggle("active");
  };
  const handleEdit = (item: any) => {
    setSelectedItem({
      ...item,
    });
  };
  const handleMemberPlanDelete = (detail: any) => {
    const storedUserId = localStorage.getItem("userId");
    const storedBusiness = localStorage.getItem("selectedBusiness");
    console.log("detail", detail, selectedUserInfo);
    let planIds = selectedUserInfo.plan.split(",").map(Number);
    let updatedPlanIds = planIds.filter((id: any) => id != detail.id);
    let existingPlansString = updatedPlanIds.join(",");
    const req = {
      user_id: selectedCardId,
      exiting_plans: existingPlansString,
      removed_plan: detail.id,
      update_id: storedUserId,
    };
    console.log("request", req);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this plan?"
    );
    if (isConfirmed) {
      setLoading(true);
      callApi("POST", "remove_plan.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          if (storedBusiness) {
            getmember(selectedCardId, selectedPayment.user_idfk);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    } else {
      console.log("Deletion canceled.");
      setLoading(false);
    }
  };

  const handleDelete = (item: any) => {
    const storedUserId = localStorage.getItem("userId");

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      setLoading(true);

      const req = {
        group_id: item,
        deleted_by: storedUserId,
      };
      callApi("POST", "group_delete.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          getgroup(selectedBusiness);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    } else {
      console.log("Deletion canceled.");
      setLoading(false);
    }
  };
  const handleleadDelete = (item: any) => {
    const storedUserId = localStorage.getItem("userId");

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      setLoading(true);

      const req = {
        id: item,
        status: "2",
      };
      callApi("POST", "lead_not_interest.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          getleads();
          setLoading(false);
          closeEditModal();
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    } else {
      console.log("Deletion canceled.");
      setLoading(false);
    }
  };
  const handlePaymentModeChange = (e: any) => {
    setSelectedPaymentMode(e.target.value);
  };

  const handlePartialPaymentDateChange = (e: any) => {
    setPartialPaymentDate(e.target.value);
  };

  const handlePartialPaymentAmountChange = (e: any) => {
    setPartialPaymentAmount(e.target.value);
  };

  const downloadExcel = () => {
    if (activeButton === "All Status") {
      if (currentItems) {
        const todayDate: any = new Date();
        const flattenedData = cardData.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.name,
            Status: file.active_status,
            Mobile: file.phone_no,
            Balance: file.balance,
            email: file.email,
            blood_grp: file.blood_grp,
            admission_no: file.admission_no,
            dob: file.dob,
            occupation: file.occupation,
          };
        });
        const headers = [
          "ID",
          "name",
          "Status",
          "Mobile",
          "Balance",
          "email",
          "blood_grp",
          "admission_no",
          "dob",
          "occupation",
        ];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `Subscribers.xlsx`);
      }
    }
    if (
      activeButton === "Active" ||
      activeButton === "In-Active" ||
      activeButton === "DND"
    ) {
      if (currentItems) {
        const todayDate: any = new Date();
        const flattenedData = currentItems.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.name,
            Status: file.active_status,
            Mobile: file.phone_no,
            Balance: file.balance,
            email: file.email,
            blood_grp: file.blood_grp,
            admission_no: file.admission_no,
            dob: file.dob,
            occupation: file.occupation,
          };
        });
        const headers = [
          "ID",
          "name",
          "Status",
          "Mobile",
          "Balance",
          "email",
          "blood_grp",
          "admission_no",
          "dob",
          "occupation",
        ];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `Subscribers.xlsx`);
      }
    }
    if (activeButton === "Group") {
      if (currentItemsgrp) {
        const todayDate: any = new Date();
        const flattenedData = currentItemsgrp.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.name,
            Session: file.session,
            batch: file.batch,
            created_dt: file.created_dt,
          };
        });
        const headers = ["ID", "name", "Session", "batch", "created_dt"];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `group.xlsx`);
      }
    }
    if (activeButton === "Attendence") {
      if (currentItemsatt) {
        const todayDate: any = new Date();
        const flattenedData = filterattendance.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.user_name,
            Business_type: file.business_type,
            Phone_no: file.phone_no,
            attendance_status:
              file.attendance_status === 0 ? "Absent" : "Present",
          };
        });
        const headers = [
          "ID",
          "name",
          "Business_type",
          "phone_no",
          "attendance_status",
        ];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `Attendance.xlsx`);
      }
    }
    if (activeButton === "PT") {
      if (currentItemspt) {
        const todayDate: any = new Date();
        const flattenedData = currentItemspt.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.name,
            Status: file.active_status,
            Mobile: file.phone_no,
            Balance: file.balance,
            email: file.email,
          };
        });
        const headers = ["ID", "name", "Status", "Mobile", "Balance", "email"];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `PT.xlsx`);
      }
    }
    if (activeButton === "Leads") {
      if (currentItemslead) {
        const todayDate: any = new Date();
        const flattenedData = currentItemslead.map((file: any, index: any) => {
          return {
            ID: index + 1,
            name: file.name,
            Status: file.status,
            Mobile: file.phone_no,
            email: file.email,
          };
        });
        const headers = ["ID", "name", "Status", "Mobile", "email"];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
          header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        // const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, `Leads.xlsx`);
      }
    }
  };
  const modalBody = (
    <div>
      {modalContent === "Info" && (
        <div className="user-details">
          <div>
            <img src={mail} alt="mail" className="mail-user" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.email}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={phone} alt="phone" className="phone-user" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.phone_no}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={location} alt="location" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.address}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={number} alt="number" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.admission_no}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={calender} alt="calender" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.dob}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={gender} alt="gender" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.gender}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={blood} alt="blood" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.blood_group}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={height} alt="height" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.height} cm
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={weight} alt="weight" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.weight} kg
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={bmi} alt="bmi" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.bmi}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={history} alt="blood" className="location-icon" />
            <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
              {userdata.find((card) => card.id)?.medical_history}
            </p>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div>
            <img src={history} alt="report" className="location-icon" />
            <a
              style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}
              href={userdata.find((card) => card.id)?.report}
              download
            >
              {userdata.find((card) => card.id)?.user_name}_report.pdf
            </a>
          </div>
          <img src={line2} alt="line2" className="line2" />
          <div style={{ position: "relative" }}>
            <img
              src={userdata.find((card) => card.id)?.image}
              alt="Your Image"
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "30px",
                marginTop: "30px",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                padding: "5px",
              }}
            >
              Proof
            </div>
          </div>
        </div>
      )}
      {modalContent === "finance" && (
        <div className="accordion-sub">
          <button
            className="accordion-sub-btn"
            onClick={() => handleAccordionClick(1)}
          >
            <div className="accordion-sub-text">Upcoming</div>
            <div
              className="accordion-sub-arrow"
              style={{ transform: `rotate(${rotation}deg)` }}
            >
              <img src={right} alt="" />
            </div>
          </button>
          <div className="accordion-sub-card">
            <div className="accordion-sub-content" id="accordion-content-1">
              {upcomingdata && upcomingdata.length > 0 ? (
                upcomingdata.map((payment, index) => (
                  <div key={index} className="acc-sub-content">
                    <h4 style={{ marginBottom: "5px" }}>{payment.task_name}</h4>
                    <div className="acc-sub-content1">
                      <p>{payment.task_descriprtion}</p>
                      <button
                        className="btn "
                        onClick={() => handleCollectButtonClick(payment)}
                      >
                        Collect-{payment.payment_amt}{" "}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="acc-sub-content">
                  <p>No data available</p>
                </div>
              )}
            </div>
          </div>
          <button
            className="accordion-sub-btn"
            onClick={() => handleAccordionClick(2)}
          >
            <div className="accordion-sub-text">Unpaid</div>
            <div
              className="accordion-sub-arrow"
              style={{ transform: `rotate(${unpaidrotation}deg)` }}
            >
              <img src={right} alt="" />
            </div>
          </button>
          <div className="accordion-sub-card">
            <div className="accordion-sub-content" id="accordion-content-2">
              {unpaidpay && unpaidpay.length > 0 ? (
                unpaidpay.map((payment, index) => (
                  <div key={index} className="acc-sub-content">
                    <h4 style={{ marginBottom: "5px" }}>{payment.task_name}</h4>
                    <div className="acc-sub-content1">
                      <p>{payment.task_descriprtion}</p>
                      <button
                        className="btn"
                        onClick={() => handleCollectButtonClick(payment)}
                      >
                        Collect-{payment.payment_amt}{" "}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="acc-sub-content">
                  <p>No data available</p>
                </div>
              )}
            </div>
          </div>
          <button
            className="accordion-sub-btn"
            onClick={() => handleAccordionClick(3)}
          >
            <div className="accordion-sub-text">Completed</div>
            <div
              className="accordion-sub-arrow"
              style={{ transform: `rotate(${completedrotation}deg)` }}
            >
              <img src={right} alt="" />
            </div>
          </button>

          <div className="accordion-sub-card">
            <div className="accordion-sub-content" id="accordion-content-3">
              {fincompleted && fincompleted.length > 0 ? (
                fincompleted.map((payment, index) => (
                  <div key={index} className="acc-sub-content">
                    <h4 style={{ marginBottom: "5px" }}>{payment.task_name}</h4>
                    <div className="acc-sub-content1">
                      <p>{payment.task_descriprtion}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="acc-sub-content">
                  <p>No data available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {modalContent === "membership" && (
        <>
          {plandataa.map((card) => (
            <div className="mem-card" key={card.id}>
              <div className="card-header" style={{ display: "ruby-text" }}>
                <h4>{card.plan_name}</h4>
                <button className="deletebtn" style={{ float: "right" }}>
                  <img
                    src={deleteicon}
                    alt="delete"
                    onClick={() => handleMemberPlanDelete(card)}
                  />
                </button>
              </div>
              <div className="card-body">
                <div className="membership-info">
                  <div className="membership-left">
                    <p>
                      Duration: <b>{card.duration}</b>
                    </p>
                    <p>
                      Expires On: <b>{card.expiry_date}</b>
                    </p>
                  </div>
                  <div className="membership-right">
                    <p>
                      Total Due: <b>{card.price}</b>
                    </p>
                    <p>
                      Plan Type: <b>{card.plan_type}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {tasks.length === 0 ? (
            <p>No Data Available</p>
          ) : (
            <>
              {tasks.map((item, index) => (
                <div className="task-card" key={index}>
                  <div className="row">
                    <div
                      className="column"
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>{item.days}</p>
                    </div>
                    <div className="column">
                      <p>{item.description}</p>
                    </div>
                    <div
                      className="column"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {parseFloat(item.payment_amt) !== 0 &&
                        item.payment_amt !== "Paid" && (
                          <button
                            className="btn collect-btn"
                            onClick={() => handleCollectButtonClick(item)}
                          >
                            {item.payment_amt}
                          </button>
                        )}
                      {item.payment_amt === "Paid" && (
                        <button className="btn collect-btn" disabled>
                          Paid
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
      {modalContent === "products" && (
        <div className="product-card">
          {products && products.length > 0 ? (
            products.map((item, index) => (
              <div className="row" key={index}>
                <div
                  className="column-20"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={item.image}
                    alt="Product"
                  />
                </div>
                <div
                  className="column-60"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    fontSize: "10px",
                  }}
                >
                  <p>{item.product_name}</p>
                  <p>{item.size}</p>
                  <p>{item.qty}</p>
                  <p>{item.date}</p>
                </div>
                <div
                  className="column-20"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p>{item.paid_amount}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No purchase history</p>
          )}
        </div>
      )}
      {modalContent === "Members" && (
        <div className="members-container">
          {listitem.map((item, index) => (
            <div className="member-card" key={index}>
              <div>
                <img
                  src={logo}
                  alt="Subscriber Logo"
                  className="subscriber-logo1"
                />
                <img
                  src={threedot}
                  alt="Options"
                  className="options-icon"
                  onClick={() => handleDotIconClick(item)}
                />
                <p className="subscriber-name">{item.subscriber}</p>
                {item.active_status === "Active" && (
                  <img src={active} alt="Active" className="status-icon" />
                )}
                {item.active_status === "inactive" && (
                  <img src={inactive} alt="Inactive" className="status-icon" />
                )}

                <img src={dot} alt="dot" className="dot-icon" />
                <div className="row">
                  <div className="column">
                    <p
                      style={{
                        color: "rgba(102, 112, 133, 1)",
                        marginTop: "2px",
                        marginBottom: "5px",
                      }}
                    >
                      Mobile
                    </p>
                    <p className="mobile">{item.phoneno}</p>
                  </div>
                  <div className="column">
                    <p
                      style={{
                        color: "rgba(102, 112, 133, 1)",
                        marginTop: "2px",
                        marginBottom: "5px",
                      }}
                    >
                      Balance
                    </p>
                    <p className="balance">{item.balace}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {modalContent === "grpInfo" && (
        <div>
          {groupmem.map((group, index) => (
            <div className="user-details" key={index}>
              <div>
                <img src={note} alt="note" className="mail-user" />
                <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
                  {group.session}
                </p>
              </div>
              <img src={line2} alt="line2" className="line2" />
              <div>
                <img src={time} alt="time" className="phone-user" />
                <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
                  {group.batch}
                </p>
              </div>
              <img src={line2} alt="line2" className="line2" />
              <div>
                <img src={grp} alt="grp" className="location-icon" />
                <p style={{ color: "rgba(3, 2, 41, 1)", marginLeft: "5px" }}>
                  {group.name}
                </p>
              </div>
              <img src={line2} alt="line2" className="line2" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
  const filteredData =
    !showgroup && !showattendence && !showlead && !showpt
      ? cardData
          .filter((item) =>
            Object.values(item).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
          .filter((item) => {
            if (filterCriteria === "All Status") return true;
            if (filterCriteria === "Active")
              return item.active_status === "Active";
            if (filterCriteria === "In-Active")
              return item.active_status === "inactive";
            if (filterCriteria === "DND") return item.active_status === "DND";

            return true;
          })
      : [];
  console.log("filtereddata", filteredData);

  const filterattendance = attendancelist.filter((item) => {
    // Apply search filter
    const searchTermMatches = Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Apply attendance status filter
    let statusMatches = true;
    if (attendancecontent === "1") {
      statusMatches = item.attendance_status === 1;
    } else if (attendancecontent === "0") {
      statusMatches = item.attendance_status === 0;
    }

    // Combine search and status filters
    return searchTermMatches && statusMatches;
  });

  const filterpt = cardDatapt.filter((item) => {
    const searchTermMatches = Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    return searchTermMatches;
  });

  const filterlead = leadsdata.filter((item) => {
    const searchTermMatches = Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    return searchTermMatches;
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const pageCountatt = Math.ceil(filterattendance.length / itemsPerPageatt);
  const pageCountgrp = Math.ceil(datagroup.length / itemsPerPage);
  const pageCountpt = Math.ceil(datagroup.length / itemsPerPage);
  const pageCountlead = Math.ceil(leadsdata.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // subscriber
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const totalItems = filteredData.length;
  const rangeEnd = Math.min((currentPage + 1) * itemsPerPage, totalItems);

  // Leads
  const indexOfLastItemlead = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItemlead = indexOfLastItemlead - itemsPerPage;
  const currentItemslead = filterlead.slice(
    indexOfFirstItemlead,
    indexOfLastItemlead
  );

  const rangeStartlead = currentPage * itemsPerPage + 1;
  const totalItemslead = filterlead.length;
  const rangeEndlead = Math.min(
    (currentPage + 1) * itemsPerPage,
    totalItemslead
  );
  // group
  const indexOfLastItemgrp = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItemgrp = indexOfLastItemgrp - itemsPerPage;
  const currentItemsgrp = datagroup.slice(
    indexOfFirstItemgrp,
    indexOfLastItemgrp
  );

  const rangeStartgrp = currentPage * itemsPerPage + 1;
  const totalItemsgrp = datagroup.length;
  const rangeEndgrp = Math.min((currentPage + 1) * itemsPerPage, totalItemsgrp);

  //attendance
  const indexOfLastItematt = (currentPage + 1) * itemsPerPageatt;
  const indexOfFirstItematt = indexOfLastItematt - itemsPerPageatt;
  const currentItemsatt = filterattendance.slice(
    indexOfFirstItematt,
    indexOfLastItematt
  );

  const rangeStartatt = currentPage * itemsPerPageatt + 1;
  const totalItemsatt = filterattendance.length;
  const rangeEndatt = Math.min(
    (currentPage + 1) * itemsPerPageatt,
    totalItemsatt
  );

  //PT
  const indexOfLastItempt = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItempt = indexOfLastItempt - itemsPerPage;
  const currentItemspt = filterpt.slice(indexOfFirstItempt, indexOfLastItempt);

  const rangeStartpt = currentPage * itemsPerPage + 1;
  const totalItemspt = cardDatapt.length;
  const rangeEndpt = Math.min((currentPage + 1) * itemsPerPage, totalItemspt);

  const activeTab =
    activeButton === "Leads"
      ? "Leads"
      : activeButton === "Group"
      ? "Group"
      : "Subscriber";
  console.log("activeTab___________________________", activeTab);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="subscriber-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Subscribers</p>
        <div className="dashboard-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Subscriber's List</p>
          <div className="buttons-container">
            <button className="export-button" onClick={downloadExcel}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={exportsvg}
                  alt="Export"
                  style={{ marginRight: "5px", marginLeft: "12px" }}
                />
                <span style={{ color: "purple" }}>Export</span>
              </div>
            </button>
            {activeButton !== "Attendence" &&
              activeButton !== "Group" &&
              activeButton !== "PT" &&
              activeButton !== "Leads" && (
                <button className="add-button1" onClick={openModal}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={plus}
                      alt="Add Product"
                      style={{ marginRight: "5px" }}
                    />
                    <span style={{ color: "white" }}>Add Subscriber</span>
                  </div>
                </button>
              )}
            {activeButton === "Leads" && (
              <button className="add-button1" onClick={openModal}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={plus}
                    alt="Add Product"
                    style={{ marginRight: "5px" }}
                  />
                  <span style={{ color: "white" }}>Add Leads</span>
                </div>
              </button>
            )}
            {activeButton === "Group" && (
              <button className="add-button1" onClick={openModal}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={plus}
                    alt="Add Product"
                    style={{ marginRight: "5px" }}
                  />
                  <span style={{ color: "white" }}>Add Group</span>
                </div>
              </button>
            )}
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          buttontext={activeTab}
          selecteddata={selectedlead}
        />

        <div className="card-and-search-container">
          {/* Cardmodal */}
          <div className="cardmodal-sub">
            <button
              className={activeButton === "All Status" ? "active" : ""}
              onClick={() => handleButtonClick("All Status")}
            >
              All Status
            </button>
            <button
              className={activeButton === "Active" ? "active" : ""}
              onClick={() => handleButtonClick("Active")}
            >
              Active
            </button>
            <button
              className={activeButton === "In-Active" ? "active" : ""}
              onClick={() => handleButtonClick("In-Active")}
            >
              In-Active
            </button>
            <button
              className={activeButton === "DND" ? "active" : ""}
              onClick={() => handleButtonClick("DND")}
            >
              DND
            </button>
            <button
              className={activeButton === "Group" ? "active" : ""}
              onClick={() => handleButtonClick("Group")}
            >
              Group
            </button>
            {selectedBusiness !== "3" && (
              <button
                className={activeButton === "PT" ? "active" : ""}
                onClick={() => handleButtonClick("PT")}
              >
                PT
              </button>
            )}
            <button
              className={activeButton === "Attendence" ? "active" : ""}
              onClick={() => handleButtonClick("Attendence")}
            >
              Attendence
            </button>
            <button
              className={activeButton === "Leads" ? "active" : ""}
              onClick={() => handleButtonClick("Leads")}
            >
              Leads
            </button>
          </div>
          {/* Filter input */}
          <div className="search-field2">
            <input
              type="text"
              placeholder="Search.."
              className="search-input2"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* <div>
      <button className="filter-button">
        <img src={filtersvg} alt="Filter" className="filter-icon" />
        Filter
      </button>
    </div> */}
        </div>
        {activeButton !== "Attendence" &&
          activeButton !== "Group" &&
          activeButton !== "PT" &&
          activeButton !== "Leads" && (
            <div className="row">
              <div className="column">
                <div
                  className={`card-container1 ${
                    showSecondColumn
                      ? "four-cards-per-row"
                      : "five-cards-per-row"
                  }`}
                >
                  {currentItems.map((card) => (
                    <div
                      key={card.id}
                      className={`card1 ${
                        selectedCardId === card.id ? "selected-card" : ""
                      }`}
                      onClick={() => handleCardClick(card)}
                    >
                      <img
                        src={threedot}
                        alt="More Options"
                        className="three-dot-icon"
                        onClick={() => handleThreeDotClick(card)}
                      />
                      <img
                        src={card.profile}
                        alt="Subscriber Logo"
                        className="subscriber-logo"
                      />
                      <p className="subscriber-name">{card.user_name}</p>
                      {card.active_status === "Active" && (
                        <img
                          src={active}
                          alt="Active"
                          className="status-icon"
                        />
                      )}
                      {card.active_status === "inactive" && (
                        <img
                          src={inactive}
                          alt="Inactive"
                          className="status-icon"
                        />
                      )}
                      {card.active_status === "DND" && (
                        <img src={dnd} alt="Inactive" className="status-icon" />
                      )}
                      <img src={dot} alt="dot" className="dot-icon" />
                      <div className="row">
                        <div className="column">
                          <p
                            style={{
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                          >
                            Mobile
                          </p>
                          <p className="mobile">{card.phone_no}</p>
                        </div>
                        <div className="column">
                          <p
                            style={{
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                          >
                            Balance
                          </p>
                          <p className="balance">{card.balance}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={`column ${showSecondColumn ? "visible" : "hidden"}`}
              >
                {selectedCardId !== null && (
                  <div className="user-modal1">
                    <div className="modal-header">
                      <button
                        className="closebtn"
                        onClick={handleCloseButtonClick}
                      >
                        <img src={closeIcon} alt="Close" />
                      </button>
                      <div className="avatar-container">
                        <img
                          src={
                            cardData.find((card) => card.id === selectedCardId)
                              ?.profile
                          }
                          alt="Avatar"
                          className="avatar-sub"
                          style={{
                            borderRadius: "50%",
                            height: "80px",
                            width: "80px",
                            marginLeft: "-30px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-content">
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "2px",
                          fontSize: "x-large",
                          marginBottom: "3px",
                        }}
                      >
                        {
                          cardData.find((card) => card.id === selectedCardId)
                            ?.user_name
                        }
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "3px",
                          color:
                            cardData.find((card) => card.id === selectedCardId)
                              ?.active_status === "Active"
                              ? "green"
                              : "red",
                        }}
                      >
                        {
                          cardData.find((card) => card.id === selectedCardId)
                            ?.active_status
                        }
                      </p>
                      <img src={line} alt="line" />
                      <div className="cardmodal-sub-usermodal">
                        <button
                          className={
                            activemodalButton === "Info" ? "active" : ""
                          }
                          onClick={() => handlemodalButtonClick("Info")}
                        >
                          Info
                        </button>
                        <button
                          className={
                            activemodalButton === "finance" ? "active" : ""
                          }
                          onClick={() => handlemodalButtonClick("finance")}
                        >
                          Finance
                        </button>
                        <button
                          className={
                            activemodalButton === "membership" ? "active" : ""
                          }
                          onClick={() => handlemodalButtonClick("membership")}
                        >
                          Membership
                        </button>
                        <button
                          className={
                            activemodalButton === "products" ? "active" : ""
                          }
                          onClick={() => handlemodalButtonClick("products")}
                        >
                          History
                        </button>
                      </div>
                      {modalBody}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {showgroup && (
          <div className="row-group">
            <div className="column-group">
              <div
                className={`card-container2 ${
                  showgroupcolumn ? "two-cards-per-row" : "four-cards-per-row"
                }`}
              >
                {currentItemsgrp.map((card) => (
                  <div
                    key={card.id}
                    className={`card-group ${
                      selectedCardId === card.id ? "selected-card" : ""
                    }`}
                    onClick={() => handlegroupCardClick(card)}
                  >
                    <h4 className="card-title">{card.name}</h4>
                    <p className="session-batch">Session: {card.session}</p>
                    <p className="session-batch">Batch: {card.batch}</p>
                    <img
                      src={groupimg}
                      alt="Right Arrow"
                      className="group-img"
                    />
                    <img
                      src={rightarrow1}
                      alt="Right Arrow"
                      className="right-arrow1"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className="column-group"
              style={{ display: showgroupcolumn ? "block" : "none" }}
            >
              {selectedCardId !== null && (
                <div className="user-modal1">
                  <div className="modal-header-sub">
                    <button
                      className="closebtn"
                      onClick={handleCloseButtonClick}
                    >
                      <img src={closeIcon} alt="Close" />
                    </button>
                    <div className="avatar-container">
                      <img src={Avatar} alt="Avatar" className="avatar" />
                    </div>
                    <button className="deletebtn">
                      <img
                        src={deleteicon}
                        alt="delete"
                        onClick={() => handleDelete(selectedCardId)}
                      />
                    </button>
                  </div>
                  <div className="modal-content-sub">
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "2px",
                        fontSize: "x-large",
                        marginBottom: "3px",
                      }}
                    >
                      {
                        datagroup.find((card) => card.id === selectedCardId)
                          ?.name
                      }
                    </p>
                    <img src={line} alt="line" />
                    <div className="cardmodal-sub-usermodal">
                      <button
                        className={
                          activemodalButton === "groInfo" ? "active" : ""
                        }
                        onClick={() => handlemodalButtonClick("grpInfo")}
                      >
                        Info
                      </button>
                      <button
                        className={
                          activemodalButton === "Members" ? "active" : ""
                        }
                        onClick={() => handlemodalButtonClick("Members")}
                      >
                        Members
                      </button>
                    </div>
                    <div>
                      <img
                        src={editsvg}
                        alt="Edit"
                        style={{
                          marginRight: "5px",
                          cursor: "pointer",
                          marginLeft: "100%",
                        }}
                        onClick={() => opengroupedit(datagroup)}
                      />
                    </div>
                    {modalBody}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showattendence && (
          <div>
            <div className="attendance-container">
              <div className="attendance-column">
                {/* Cardmodal */}
                <div className="cardmodal-sub">
                  <button
                    className={attendancecontent === "1" ? "active" : ""}
                    onClick={() => handlemodalAttendanceClick("1")}
                  >
                    Present
                  </button>
                  <button
                    className={attendancecontent === "0" ? "active" : ""}
                    onClick={() => handlemodalAttendanceClick("0")}
                  >
                    Absent
                  </button>
                </div>
                <div className="card-container1  four-cards-per-row">
                  {currentItemsatt.map((card) => (
                    <div
                      key={card.id}
                      className={`card1 ${
                        selectedCardId === card.id ? "selected-card" : ""
                      }`}
                      style={{ width: "20%" }}
                    >
                      <img
                        src={threedot}
                        alt="More Options"
                        className="three-dot-icon"
                        onClick={() => handleThreeDotClick1(card)}
                      />
                      <img
                        src={logo}
                        alt="Subscriber Logo"
                        className="subscriber-logo"
                      />
                      <p className="subscriber-name">{card.user_name}</p>
                      {card.active_status === "Active" && (
                        <img
                          src={active}
                          alt="Active"
                          className="status-icon"
                        />
                      )}
                      {card.active_status === "inactive" && (
                        <img
                          src={inactive}
                          alt="Inactive"
                          className="status-icon"
                        />
                      )}
                      {card.active_status === "DND" && (
                        <img src={dnd} alt="Inactive" className="status-icon" />
                      )}
                      <img src={dot} alt="dot" className="dot-icon" />
                      <div className="row">
                        <div className="column">
                          <p
                            style={{
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                          >
                            Mobile
                          </p>
                          <p className="mobile">{card.phone_no}</p>
                        </div>
                        <div className="column">
                          <p
                            style={{
                              color: "rgba(102, 112, 133, 1)",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                          >
                            Balance
                          </p>
                          <p className="balance">{card.balance}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="attendance-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "45px",
                  }}
                >
                  <Calendar
                    value={currentDate}
                    calendarType="US"
                    onChange={handleDateChange}
                  />
                </div>
                <div className="cal-btn">
                  <button
                    style={{
                      backgroundColor: "green",
                      marginRight: "20px",
                      width: "150px", // Adjust width as needed
                      height: "40px",
                      border: "none",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Present - {presentcount}
                  </button>
                  <button
                    style={{
                      backgroundColor: "red",
                      width: "150px", // Adjust width as needed
                      height: "40px",
                      border: "none",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Absent - {absentcount}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showpt && (
          <div className="row">
            <div className="column">
              <div
                className={`card-container1 ${
                  showptcolumn ? "four-cards-per-row" : "five-cards-per-row"
                }`}
              >
                {currentItemspt.map((card) => (
                  <div
                    key={card.id}
                    className={`card1 ${
                      selectedCardId === card.id ? "selected-card" : ""
                    }`}
                    onClick={() => handleCardClick(card)}
                  >
                    <img
                      src={threedot}
                      alt="More Options"
                      className="three-dot-icon"
                      onClick={() => handleThreeDotClick(card)}
                    />
                    <img
                      src={card.profile}
                      alt="Subscriber Logo"
                      className="subscriber-logo"
                    />
                    <p className="subscriber-name">{card.user_name}</p>
                    {card.active_status === "Active" && (
                      <img src={active} alt="Active" className="status-icon" />
                    )}
                    {card.active_status === "inactive" && (
                      <img
                        src={inactive}
                        alt="Inactive"
                        className="status-icon"
                      />
                    )}
                    {card.active_status === "DND" && (
                      <img src={dnd} alt="Inactive" className="status-icon" />
                    )}
                    <img src={dot} alt="dot" className="dot-icon" />
                    <div className="row">
                      <div className="column">
                        <p
                          style={{
                            color: "rgba(102, 112, 133, 1)",
                            marginTop: "2px",
                            marginBottom: "5px",
                          }}
                        >
                          Mobile
                        </p>
                        <p className="mobile">{card.phone_no}</p>
                      </div>
                      <div className="column">
                        <p
                          style={{
                            color: "rgba(102, 112, 133, 1)",
                            marginTop: "2px",
                            marginBottom: "5px",
                          }}
                        >
                          Balance
                        </p>
                        <p className="balance">{card.balance}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`column ${showptcolumn ? "visible" : "hidden"}`}>
              {selectedCardId !== null && (
                <div className="user-modal1">
                  <div className="modal-header">
                    <button
                      className="closebtn"
                      onClick={handleCloseButtonClick}
                    >
                      <img src={closeIcon} alt="Close" />
                    </button>
                    <div className="avatar-container">
                      <img
                        src={
                          cardData.find((card) => card.id === selectedCardId)
                            ?.profile
                        }
                        alt="Avatar"
                        className="avatar-sub"
                        style={{
                          borderRadius: "50%",
                          height: "80px",
                          width: "80px",
                          marginLeft: "-30px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-content">
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "2px",
                        fontSize: "x-large",
                        marginBottom: "3px",
                      }}
                    >
                      {
                        cardData.find((card) => card.id === selectedCardId)
                          ?.user_name
                      }
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "3px",
                        color:
                          cardData.find((card) => card.id === selectedCardId)
                            ?.active_status === "Active"
                            ? "green"
                            : "red",
                      }}
                    >
                      {
                        cardData.find((card) => card.id === selectedCardId)
                          ?.active_status
                      }
                    </p>
                    <img src={line} alt="line" />
                    <div className="cardmodal-sub-usermodal">
                      <button
                        className={activemodalButton === "Info" ? "active" : ""}
                        onClick={() => handlemodalButtonClick("Info")}
                      >
                        Info
                      </button>
                      <button
                        className={
                          activemodalButton === "finance" ? "active" : ""
                        }
                        onClick={() => handlemodalButtonClick("finance")}
                      >
                        Finance
                      </button>
                      <button
                        className={
                          activemodalButton === "membership" ? "active" : ""
                        }
                        onClick={() => handlemodalButtonClick("membership")}
                      >
                        Membership
                      </button>
                      <button
                        className={
                          activemodalButton === "products" ? "active" : ""
                        }
                        onClick={() => handlemodalButtonClick("products")}
                      >
                        History
                      </button>
                    </div>
                    {modalBody}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showlead && (
          <div className="lead-card">
            <div className="table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>SNo</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItemslead.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.phone_no}</td>
                      <td>{item.email}</td>
                      <td>
                        {item.status === "Not Interested" && (
                          <img
                            src={notintrested}
                            alt="In Stock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {item.status === "waiting" && (
                          <img
                            src={waiting}
                            alt="Low Stock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {item.status === "Completed" && (
                          <img
                            src={confirm}
                            alt="outofstock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                      </td>
                      <td>
                        <button
                          className="add-button1"
                          onClick={() => handleleadEdit(item)}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{ color: "white", marginLeft: "10px" }}
                            >
                              Change Status
                            </span>
                          </div>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeButton !== "Attendence" &&
          activeButton !== "Group" &&
          activeButton !== "PT" &&
          activeButton !== "Leads" && (
            <div className="pagination-and-info">
              {/* Showing items information */}
              <div className="items-info">
                Showing {rangeStart}-{rangeEnd} from {totalItems}
              </div>

              {/* Pagination */}
              <div className="pagination">
                <ReactPaginate
                  previousLabel={
                    <img
                      src={pageleft}
                      alt="In Stock"
                      style={{ marginRight: "5px" }}
                    />
                  }
                  nextLabel={
                    <img
                      src={pageright}
                      alt="In Stock"
                      style={{ marginRight: "5px" }}
                    />
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        {activeButton === "Attendence" && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStartatt}-{rangeEndatt} from {totalItemsatt}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountatt}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
        {activeButton === "PT" && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStartpt}-{rangeEndpt} from {totalItemspt}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountgrp}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
        {activeButton === "Group" && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStartgrp}-{rangeEndgrp} from {totalItemsgrp}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountgrp}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
        {activeButton === "Leads" && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStartlead}-{rangeEndlead} from {totalItemslead}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountlead}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
        {showPaymentModal && (
          <div className="payment-modal">
            <button className="closebtn" onClick={handleCloseModal}>
              <img src={closeIcon} alt="Close" />
            </button>
            <div>
              <label>Payment</label>
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="">Select Payment</option>
                <option value="1">Full Payment</option>
                <option value="2">Partial Payment</option>
              </select>
              <label>Payment Mode</label>
              <select
                value={selectedPaymentMode}
                onChange={handlePaymentModeChange}
              >
                <option value="">Select Payment Mode</option>
                <option value="ONLINE">Online</option>
                <option value="CASH">Cash</option>
              </select>
            </div>
            {selectedOption === "1" && ( // Render when Full Payment is selected
              <div>
                <button
                  className="submit-button"
                  onClick={handlefullpaymentSubmit}
                >
                  Submit
                </button>
              </div>
            )}
            {selectedOption === "2" && ( // Render when Partial Payment is selected
              <div>
                <label>Amount</label>
                <input
                  style={{ marginRight: "-29px", width: "96%" }}
                  type="text"
                  value={partialPaymentAmount}
                  onChange={handlePartialPaymentAmountChange}
                />
                <label>Next Payment Date</label>
                <input
                  type="date"
                  style={{ width: "96%" }}
                  value={partialPaymentDate}
                  onChange={handlePartialPaymentDateChange}
                />

                <button
                  className="submit-button"
                  onClick={handlepartialpaymentsubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
        {editingGroup && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closegroupedit}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Group</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="title"> Title:</label>
                    <input
                      type="text"
                      id="name"
                      value={selectedItem.name}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="designationbtype">Business type:</label>
                    <input
                      type="text"
                      id="designationbtype"
                      readOnly
                      value={selectedBusiness}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="email">Batch:</label>
                    <input
                      type="text"
                      id="batch"
                      value={selectedItem.batch} // Use the existing data from selectedItem
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          batch: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Session:</label>
                    <input
                      type="text"
                      id="batch"
                      value={selectedItem.session} // Use the existing data from selectedItem
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          batch: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div>
                  {" "}
                  <div>
                    <label htmlFor="subscribers">
                      Subscribers:<span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          backgroundColor: "#f0f0f0",
                        }),

                        multiValue: (provided: any) => ({
                          ...provided,
                          backgroundColor: "lightblue", // Set maximum width to ensure it doesn't overflow
                          overflow: "hidden", // Hide any overflow content
                          whiteSpace: "nowrap", // Prevent wrapping of content
                          textOverflow: "ellipsis", // Show ellipsis for truncated content
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          width: "30%",
                        }),

                        menu: (base) => ({
                          ...base,
                          marginTop: "0", // Remove default margin
                          borderRadius: "5px",
                          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #ccc",
                          width: "45%",
                          height: isMultiSelectFocused ? "auto" : "30px",
                          overflowY: "auto",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      options={exsubscribers.map((subscriber: any) => ({
                        value: subscriber.id,
                        label: subscriber.user_name,
                      }))}
                      onFocus={() => setIsMultiSelectFocused(true)}
                      onBlur={() => setIsMultiSelectFocused(false)}
                      onChange={handleMultiSelectChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={handlegroupeditsubmit}
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
        {isThreeDotModalOpen && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closeThreeDotModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Subscriber</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="title">
                      {" "}
                      Name:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={selectedItem.name}
                      onKeyPress={handleKeyPressname}
                      required
                      maxLength={20}
                      minLength={1}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="title"> User Name:</label>
                    <input
                      type="text"
                      id="name"
                      value={selectedItem.user_name}
                      onKeyPress={handleKeyPressname}
                      readOnly
                      maxLength={20}
                      minLength={1}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="gender">
                      Gender:<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      style={{
                        width: "106%",
                        height: "80%",
                        borderRadius: "5px",
                      }}
                      id="gender"
                      value={selectedItem.gender}
                      required
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          gender: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="T">Other</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="email">Email:</label>
                    <input
                      type="text"
                      id="email"
                      value={selectedItem.email}
                      readOnly
                      onBlur={handleBlur}
                      maxLength={30}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="title">
                      {" "}
                      Occupation:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="occupation"
                      value={selectedItem.occupation}
                      onKeyPress={handleKeyPressname}
                      required
                      maxLength={20}
                      minLength={1}
                    />
                  </div>
                  <div>
                    <label htmlFor="blood">
                      Blood Group:<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="blood"
                      required
                      value={selectedItem.blood_grp}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          blood_group: e.target.value,
                          blood_grp: e.target.value,
                        })
                      }
                      style={{
                        width: "106%",
                        height: "50px",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      {/* Add more blood groups as needed */}
                    </select>
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="phone">
                      Phone:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="phone_no"
                      required
                      value={selectedItem.phone_no}
                      minLength={10}
                      maxLength={10}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          phone_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="empno">Addmission No</label>
                    <input
                      type="text"
                      id="admission_no"
                      value={selectedItem.admission_no}
                      readOnly
                      onKeyDown={handleKeyDown}
                      maxLength={10}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          admission_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="add">
                      Address:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      onKeyPress={handleKeyPress}
                      maxLength={250}
                      required
                      value={selectedItem.address}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="dob">
                      Date of Birth:
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="dob"
                      max={currentDate}
                      value={selectedItem.dob}
                      required
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          dob: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="height">
                      Height :<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="height"
                      required
                      onKeyDown={handleDecimal}
                      minLength={2}
                      value={selectedItem.height}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          height: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="weight">
                      Weight in kg:
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="weight"
                      required
                      minLength={2}
                      onKeyDown={handleDecimal}
                      value={selectedItem.weight}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          weight: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="height">
                      BMI:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="bmi"
                      minLength={2}
                      required
                      onKeyDown={handleDecimal}
                      value={selectedItem.bmi}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          bmi: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="history">
                      Medical History:
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="medical_history"
                      minLength={2}
                      required
                      value={selectedItem.medical_history}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          medical_history: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="file">New Medical Report:</label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="file"
                        style={{ width: "175px" }}
                        accept="pdf/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              setSelectedItem({
                                ...selectedItem,
                                report: reader.result as string,
                                reportFormat: file.type.split("/")[1],
                              });
                            };
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="existing-image">
                    <label>Existing Medical Report :</label>
                    {selectedItem.report && (
                      <a href={selectedItem.report} download>
                        {selectedItem.user_name}_report.pdf
                      </a>
                    )}
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="image">New Image:</label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="image"
                        style={{ width: "175px" }}
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const validImageTypes = [
                              "image/jpeg",
                              "image/png",
                              "image/gif",
                              "image/webp",
                            ]; // Add any other image types you want to support
                            if (!validImageTypes.includes(file.type)) {
                              alert("Please upload a valid image file.");
                              e.target.value = ""; // Clear the file input field
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              setSelectedItem({
                                ...selectedItem,
                                image: reader.result as string, // Set image URL to data URL
                                imageFile: file,
                                imageformat: file.type.split("/")[1],
                              });
                            };
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="existing-image">
                    <label>Existing Image :</label>
                    {selectedItem.image && (
                      <img
                        src={selectedItem.image}
                        alt="Existing Image"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginLeft: "30%",
                        }} // Adjust dimensions as needed
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={editsubmit}
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
        {leadmodal && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="deletebtn">
                <img
                  src={deleteicon}
                  alt="delete"
                  style={{ marginLeft: "-15px", marginRight: "-15px" }}
                  onClick={() => handleleadDelete(selectedItem.id)}
                />
              </button>
              <button className="close-button" onClick={closeEditModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Leads</h2>
              <div className="modal-footer">
                <div className="button-row">
                  <button
                    type="submit"
                    className="submit-button"
                    style={{ marginRight: "10px", backgroundColor: "red" }}
                    onClick={() => handlenotinterested(selectedItem)}
                  >
                    Not Interested
                  </button>
                  <button
                    type="submit"
                    className="submit-button"
                    style={{
                      fontFamily: "Nunito, sans-serif",
                      fontSize: "13px",
                    }}
                    onClick={() => {
                      openModal(selectedItem);
                      setActiveButton("active");
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Subscribers;
