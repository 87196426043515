import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import login from "../../assets/login1.svg";
import logo from "../../assets/login-logo.svg";
import { useNavigate } from "react-router-dom";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleChangePassword = () => {
    const storedUserId = localStorage.getItem("userId");
    setLoading(true);
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      setPasswordError("New password and confirm password are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    // Make API call to change password
    const req = {
      id: localStorage.getItem("idid"),
      password: newPassword,
    };

    callApi("POST", "change_password.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", "Password changed successfully");
          navigate("/");
          setLoading(false);
        } else {
          showError("Error..!", "Failed to change password");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.error("An error occurred");
        setLoading(false);
      });
  };
  const handleBack = () => {
    navigate("/"); // Navigate to the home page or any other appropriate page
  };

  return (
    <div className="login-grid">
      <div className="login-form">
        <div className="back-button">
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="welcome-text">
          <img src={logo} alt="logo" className="logo" />
          <p style={{ fontSize: "40px", marginTop: "10px" }}>Change Password</p>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-field">
            <TextField
              id="new-password"
              label="New Password"
              variant="standard"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>
          <div className="form-field">
            <TextField
              id="confirm-password"
              label="Confirm Password"
              variant="standard"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              error={!!passwordError}
              helperText={passwordError}
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>
          <div className="form-field">
            <Button
              variant="contained"
              onClick={handleChangePassword}
              fullWidth
              style={{
                background: "linear-gradient(180deg, #883DCF 0%, #451F69 100%)",
                color: "white",
              }}
              disabled={loading}
            >
              {loading ? (
                <Hourglass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "white"]}
                />
              ) : (
                "Change Password"
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="login-image">
        <img src={login} alt="loginbg" />
      </div>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
