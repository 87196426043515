// Sidebar.tsx
import React, { useEffect, useState } from "react";
import { FaBars, FaTimes, FaHome, FaUser, FaCog } from "react-icons/fa";
import "./Sidebar.css";
import leftarrow from "../../assets/sidebar/left-arrow.svg";
import rightarrow from "../../assets/sidebar/right-arrow.svg";
import dashboardsvg from "../../assets/sidebar/dashboard.svg";
import logosvg from "../../assets/sidebar/logo.svg";
import logoicon from "../../assets/sidebar/logoicon.svg";
import dashboardblacksvg from "../../assets/sidebar/dashboard-black.svg";
import productsvg from "../../assets/sidebar/product.svg";
import productblack from "../../assets/sidebar/productblack.svg";
import menurightarrow from "../../assets/sidebar/menu-right-arrow.svg";
import subwhite from "../../assets/sidebar/subscriberwhite.svg";
import subblack from "../../assets/sidebar/subscriberblack.svg";
import finwhite from "../../assets/sidebar/finwhite.svg";
import finblack from "../../assets/sidebar/finblack.svg";
import teamblack from "../../assets/sidebar/teamblack.svg";
import teamwhite from "../../assets/sidebar/teamwhite.svg";
import memblack from "../../assets/sidebar/memblack.svg";
import memwhite from "../../assets/sidebar/memwhite.svg";
import banwhite from "../../assets/sidebar/banwhite.svg";
import banblack from "../../assets/sidebar/banblack.svg";
import setwhite from "../../assets/sidebar/settwhite.svg";
import setblack from "../../assets/sidebar/settblack.svg";
import notblack from "../../assets/sidebar/notblack.svg";
import notwhite from "../../assets/sidebar/notwhite.svg";
import user from "../../assets/sidebar/user.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { callApi } from "../utils/api";
import { toast } from "react-toastify";
import { notification } from "antd";
import { showSuccess } from "../utils/sweetAlert";
import avatarmale from "../../assets/sidebar/ab3cefaf-b0ea-401e-8aaf-05ba662c438f.jpg";

interface notification {
  id: string;
  user_idfk: string;
  user_name: string;
  plan_idfk: string;
  plan_name: string | null;
  task_idfk: string;
  task_name: string;
  product_id: string;
  product_name: string | null;
  product_qty: string;
  paid_amt: string;
  pay_mode: string;
  bussiness_type: string;
  message: string;
  created_dt: string;
}

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState<string>(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    return storedMenu ? storedMenu : "Dashboard";
  });
  const [notificationdata, setnotification] = useState<notification[]>([]);
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [todaynoti, setTodayNoti] = useState<notification[]>([]);
  const [earlier, setearlier] = useState<notification[]>([]);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
    }
    localStorage.setItem("activeMenu", activeMenu);
  }, [activeMenu]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu: string) => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    sessionStorage.setItem("activesubscribeButtonName", "All Status");
    setActiveMenu(menu);
    if (menu === "Notifications") {
      setIsNotificationsModalOpen(true);
      getnotifications(selectedBusiness);
    } else {
      setIsNotificationsModalOpen(false);
    }
    if (menu === "logout") {
      handlelogout();
    }
  };

  const getnotifications = (businessType: string) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "staff_notification.php", req)
      .then((response) => {
        const currentDate = new Date().toDateString(); // Get current date

        // Filter notifications based on whether their creation date matches the current date
        const todayNotifications = response.data.responseJson.filter(
          (notification: any) => {
            const createdDate = new Date(
              notification.created_dt
            ).toDateString();
            return createdDate === currentDate;
          }
        );

        const earlier = response.data.responseJson.filter(
          (notification: any) => {
            const createdDate = new Date(
              notification.created_dt
            ).toDateString();
            return createdDate < currentDate;
          }
        );

        setearlier(earlier);

        setTodayNoti(todayNotifications);

        // Update all notifications in state
        setnotification(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const handlelogout = () => {
    setIsConfirmationModalOpen(false);

    let req = {
      id: localStorage.getItem("userId"),
    };

    callApi("POST", "logout.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", "Logged Out successfully");
          navigate("/");
          localStorage.clear();
          sessionStorage.clear();
        } else {
          toast.error("Failed to Log Out");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const closeNotificationsModal = () => {
    setIsNotificationsModalOpen(false);
  };

  const handleNotificationClick = () => {
    // Add your logic for handling notification card click here
    console.log("Notification card clicked");
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            {isOpen ? (
              <Link to="/layout/dashboard">
                <img
                  src={logosvg}
                  alt="Logo"
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            ) : (
              <Link to="/layout/dashboard">
                <img
                  src={logoicon}
                  alt="Logo"
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            )}
          </div>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isOpen ? (
              <img src={leftarrow} alt="open" className="arrow-icon" />
            ) : (
              <img src={rightarrow} alt="Close" className="arrow-icon" />
            )}
          </div>
        </div>
      </div>

      <ul className="sidebar-menu">
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Dashboard"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Dashboard" ? "#fff" : "black",
            }}
            to="/layout/dashboard"
            onClick={() => handleMenuClick("Dashboard")}
          >
            <img
              src={
                activeMenu === "Dashboard" ? dashboardsvg : dashboardblacksvg
              }
              alt="dashboard"
            />{" "}
            {isOpen && <span>Dashboard</span>}
            {isOpen && activeMenu !== "Dashboard" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Products"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Products" ? "#fff" : "black",
            }}
            to="/layout/products"
            onClick={() => handleMenuClick("Products")}
          >
            <img
              src={activeMenu === "Products" ? productsvg : productblack}
              alt="Products"
            />{" "}
            {isOpen && <span>Products</span>}
            {isOpen && activeMenu !== "Products" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Subscribers"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Subscribers" ? "#fff" : "black",
            }}
            to="/layout/subscribers"
            onClick={() => handleMenuClick("Subscribers")}
          >
            <img
              src={activeMenu === "Subscribers" ? subwhite : subblack}
              alt="Subscribers"
            />{" "}
            {isOpen && <span>Subscribers</span>}
            {isOpen && activeMenu !== "Subscribers" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Finance"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Finance" ? "#fff" : "black",
            }}
            to="/layout/Finance"
            onClick={() => handleMenuClick("Finance")}
          >
            <img
              src={activeMenu === "Finance" ? finwhite : finblack}
              alt="Finance"
            />{" "}
            {isOpen && <span>Finance</span>}
            {isOpen && activeMenu !== "Finance" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Team"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Team" ? "#fff" : "black",
            }}
            to="/layout/Team"
            onClick={() => handleMenuClick("Team")}
          >
            <img
              src={activeMenu === "Team" ? teamwhite : teamblack}
              alt="Team"
            />{" "}
            {isOpen && <span>User Management</span>}
            {isOpen && activeMenu !== "Team" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Membership"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Membership" ? "#fff" : "black",
            }}
            to="/layout/Membership"
            onClick={() => handleMenuClick("Membership")}
          >
            <img
              src={activeMenu === "Membership" ? memwhite : memblack}
              alt="Membership"
            />{" "}
            {isOpen && <span>Membership</span>}
            {isOpen && activeMenu !== "Membership" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Banner"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Banner" ? "#fff" : "black",
            }}
            to="/layout/Banner"
            onClick={() => handleMenuClick("Banner")}
          >
            <img
              src={activeMenu === "Banner" ? banwhite : banblack}
              alt="Banner"
            />{" "}
            {isOpen && <span>Banner</span>}
            {isOpen && activeMenu !== "Banner" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "Role"
                  ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                  : "#fff",
              color: activeMenu === "Role" ? "#fff" : "black",
            }}
            to="/layout/Role"
            onClick={() => handleMenuClick("Role")}
          >
            <img src={activeMenu === "Role" ? memwhite : memblack} alt="Role" />{" "}
            {isOpen && <span>Role</span>}
            {isOpen && activeMenu !== "Role" && (
              <img
                src={menurightarrow}
                alt="Right arrow"
                className="right-arrow"
                style={{ marginTop: "15px" }}
              />
            )}
          </NavLink>
        </li>

        <div className="sidebar-menu">
          <li>
            <div
              className="menu-button"
              style={{
                background:
                  activeMenu === "Notifications"
                    ? "linear-gradient(to bottom, rgba(136, 61, 207, 1), rgba(69, 31, 105, 1))"
                    : "#fff",
                color: activeMenu === "Notifications" ? "#fff" : "black",
              }}
              onClick={() => handleMenuClick("Notifications")}
            >
              <img
                src={activeMenu === "Notifications" ? notwhite : notblack}
                alt="dashboard"
              />{" "}
              {isOpen && <span>Notifications</span>}
            </div>
          </li>
          <li>
            <NavLink
              className="menu-button"
              to="/layout/settings"
              style={{
                backgroundColor: activeMenu === "Settings" ? "#642d97" : "#fff",
                color: activeMenu === "Settings" ? "#fff" : "black",
              }}
              onClick={() => handleMenuClick("Settings")}
            >
              <img
                src={activeMenu === "Settings" ? setwhite : setblack}
                alt="dashboard"
              />{" "}
              {isOpen && <span>Settings</span>}
            </NavLink>
          </li>
          <li>
            <button
              className="menu-button"
              style={{
                backgroundColor: activeMenu === "user" ? "#642d97" : "#fff",
                color: activeMenu === "user" ? "#fff" : "black",
              }}
              onClick={() => setIsConfirmationModalOpen(true)} // Open confirmation modal on click
            >
              <img src={avatarmale} alt="dashboard" style={{ width: "25px" }} />{" "}
              {isOpen && <span style={{ fontSize: "15px" }}>Log out</span>}
            </button>
          </li>
        </div>
      </ul>
      {isNotificationsModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Today</h2>
              <div className="close-icon" onClick={closeNotificationsModal}>
                <img src={closeIcon} alt="Close" />
              </div>
            </div>
            <div>
              <h2 style={{ color: "black" }}>Today</h2>

              {todaynoti.map((notification) => (
                <div
                  key={notification.id}
                  className="notification-card"
                  style={{ marginBottom: "15px" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          textAlign: "left",
                          marginBottom: "10px",
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        {notification.task_name}
                      </h3>
                      <p
                        style={{
                          color: "black",
                          marginTop: 0,
                          marginBottom: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {notification.message}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "black",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        {notification.created_dt}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Second card */}
            <div>
              <h2 style={{ color: "black" }}>Earlier</h2>
              {earlier.map((notification) => (
                <div
                  key={notification.id}
                  className="notification-card"
                  style={{ marginBottom: "15px" }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          textAlign: "left",
                          marginBottom: "10px",
                          color: "black",
                        }}
                      >
                        {notification.task_name}
                      </h3>
                      <p
                        style={{
                          textAlign: "left",
                          color: "black",
                          marginTop: 0,
                          marginBottom: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {notification.message}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "black",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        {notification.created_dt}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {isConfirmationModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirmation</h2>
              <div
                className="close-icon"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                <img src={closeIcon} alt="Close" />
              </div>
            </div>
            <div className="modal-body" style={{ color: "black" }}>
              <p style={{ textAlign: "center" }}>
                Are you sure you want to log out?
              </p>
            </div>
            <div className="modal-footer">
              <div className="button-row">
                <button
                  type="submit"
                  className="submit-button"
                  onClick={() => setIsConfirmationModalOpen(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="submit-button"
                  onClick={handlelogout}
                  style={{ fontFamily: "Nunito, sans-serif", fontSize: "13px" }}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
