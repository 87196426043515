import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/dashboard/close.svg";
import TaskModal from "./taskmodal";
import "./modal.css";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import sendNotification from "../utils/FcmService";
interface showBalanceModalProps {
    showBalanceAmtModal: boolean;
    hideBalanceAmtModal: () => void;
    BalanceSaveDraft: () => void;
    balanceTaskAmt: string;
}

const BalanceAmtModal: React.FC<showBalanceModalProps> = ({
    showBalanceAmtModal,
    hideBalanceAmtModal,
    BalanceSaveDraft,
    balanceTaskAmt
}) => {
    return (
        <>
            {showBalanceAmtModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button
                            className="close-button"
                            onClick={hideBalanceAmtModal}
                        >
                            <img src={closeIcon} alt="Close" />
                        </button>
                        <h2>
                            For this plan, the balance amount is {balanceTaskAmt ? balanceTaskAmt : ""} Do you want the task to be added to it? If not, the plan is kept in draft plan.
                        </h2>



                        <div style={{ display: "flex" }}>
                            <button type="submit" className="submit-button"
                                onClick={() => BalanceSaveDraft()}
                            >
                                Save to Draft
                            </button>
                            <button type="submit" className="submit-button"
                                onClick={() => hideBalanceAmtModal()}
                            >
                                Add task
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BalanceAmtModal;