import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import login from "../../assets/login1.svg";
import logo from "../../assets/login-logo.svg";
import { useNavigate } from "react-router-dom";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showError, showSuccess } from "../utils/sweetAlert";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Hourglass } from "react-loader-spinner";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    console.log("test test", storedUserId);

    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;

    if (email.trim() === "") {
      setEmailError("Mail ID is Required");
      valid = false;
    }
    if (valid) {
      const req = {
        email: email,
      };
      callApi("POST", "forgot_password.php", req)
        .then((response) => {
          const data = response.data;
          if (data.success === true) {
            showSuccess("Success..!", data.message);
            localStorage.setItem("email", email);
            console.log("Before navigate");
            navigate("/otpverify");
            setLoading(false);
          } else {
            showError("Error..!", data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error updating banner:", error);
        });
    }
  };

  const handleBack = () => {
    navigate("/"); // Navigate to the home page or any other appropriate page
  };

  return (
    <div className="login-grid">
      <div className="login-form">
        <div className="back-button">
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="welcome-text">
          <img src={logo} alt="logo" className="logo" />
          <p style={{ fontSize: "40px", marginTop: "10px" }}>Forgot Password</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <TextField
              id="email"
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!!emailError}
              helperText={emailError}
              InputLabelProps={{
                style: { color: "black", fontSize: "14px" },
              }}
              className="wide-text-field"
            />
          </div>
          <div className="form-field">
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                background: "linear-gradient(180deg, #883DCF 0%, #451F69 100%)",
                color: "white",
              }}
            >
              {/* {loading ? (
                <Hourglass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={["white", "white"]}
                />
              ) : ( */}
              GET OTP
              {/* )} */}
            </Button>
          </div>
        </form>
      </div>
      <div className="login-image">
        <img src={login} alt="loginbg" />
      </div>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
