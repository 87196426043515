import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import "../pages/style/products.css";
import rightarrow from "../../assets/arrowright.svg";
import plus from "../../assets/Header/plus.svg";
import exportsvg from "../../assets/product/export.svg";
import deletesvg from "../../assets/product/delete.svg";
import editsvg from "../../assets/product/edit.svg";
import instocksvg from "../../assets/product/instock.svg";
import lowstocksvg from "../../assets/product/lowstock.svg";
import outofstocksvg from "../../assets/product/outofstock.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import ReactPaginate from "react-paginate";
import product from "../../assets/product/product.jpg";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Hourglass } from "react-loader-spinner";
import sendNotification from "../utils/FcmService";

interface product {
  id: number;
  product_name: string;
  product_status: string;
  qty: number;
  price: number;
  size: string;
  description: string;
  offer: string;
  offer_price: number;
  low_qty: number;
  created_dt: string;
  image: string;
  status: string;
  added_date: string;
  purchase_by: string;
  paid_amount: string;
  date: string;
  declined_by: any;
  approved_by: string;
}

const Products: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activeButton") || "All Products";
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [reqdata, setreqdata] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [productreqcount, setproductreqcount] = useState("");

  const [allproduct, setallproduct] = useState<product[]>([]);
  const [allproductadmin, setproductadmin] = useState<product[]>([]);

  useEffect(() => {
    localStorage.setItem("activeButton", activeButton);
    return () => {
      localStorage.removeItem("activeButton");
    };
  }, [activeButton]);

  useEffect(() => {
    console.log("allproduct+++++_____", allproduct);
  }, [allproductadmin, allproduct]);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      console.log("Selected Business:", storedBusiness);
      getproductall(storedBusiness, activeButton);
    }
    const storedname = localStorage.getItem("selectedname");
    if (storedname) {
      setSearchTerm(storedname); // Set the searchTerm state with storedName
    }
    getproductreqcount();
    localStorage.removeItem("selectedname");
    console.log("activeButton____________________product", activeButton);
    handleButtonClick(activeButton);
  }, []);
  const getproductall = (businessType: string, buttonname: string) => {
    setLoading(true);
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const req = {
      bussiness_type: storedBusiness,
      from_date: "",
      to_date: "",
    };

    callApi("POST", "product_get_all.php", req)
      .then((response) => {
        const data = response.data.responseJson;

        if (data && data.length > 0) {
          if (buttonname === "In-Stock") {
            const filteredData = data.filter(
              (product: any) => product.status === "In-Stock"
            );
            setallproduct(filteredData);
          } else if (buttonname === "Low Stock") {
            const filteredData = data.filter(
              (product: any) => product.status === "Low Stock"
            );
            setallproduct(filteredData);
          } else if (buttonname === "All Products") {
            setallproduct(data);
          }
        } else {
          setallproduct([]); // Clear the products list if no data is found
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
        setLoading(false); // Ensure loading is set to false even if there's an error
      });
  };

  const handleApprove = (item: any, status: any) => {
    setLoading(true);
    const confirmed = window.confirm(
      "Are you sure you want to do this action..?"
    );
    if (confirmed) {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const req = {
        ...item,
        product_status: status,
        pay_mode: "Cash",
        ...(status === 2 ? { decline_by: userId } : { approved_by: userId }),
      };
      callApi("POST", "product_request_status.php", req)
        .then((response) => {
          if (response.data.success === true) {
            showSuccess("Success..!", response.data.message);
            getproductsold(selectedBusiness, "0");
            getproductreqcount();
            getnotification(item, status);
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getproductstock = (businessType: string, status: string) => {
    setLoading(true);
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const req = {
      bussiness_type: storedBusiness,
      stock_status: status,
    };
    callApi("POST", "product_select.php", req)
      .then((response) => {
        setallproduct(response.data.responseJson);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getproductsold = (businessType: string, status: string) => {
    setLoading(true);
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const req = {
      bussiness_type: storedBusiness,
      product_status: status,
    };
    callApi("POST", "product_sold.php", req)
      .then((response) => {
        setallproduct(response.data.responseJson);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getproductadmin = (businessType: string) => {
    setLoading(true);
    setproductadmin([]);
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const req = {
      bussiness_type: storedBusiness,
    };
    callApi("POST", "purchase_history_admin.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;
        setLoading(false);
        // if (activeButton === "Declined") {
        const filteredData = responseData.filter(
          (item: any) => item.status === "2"
        );
        console.log(";;;;;", filteredData);
        setproductadmin(filteredData);
        // } else {
        //   setproductadmin(responseData);
        // }
        console.log("activeButton______", activeButton);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getproductreqcount = () => {
    setLoading(true);
    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "subscribe_count.php", req)
      .then((response) => {
        setproductreqcount(response.data.product_request_count);
        console.log("count count", response.data.product_request_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };
  const openEditModal = (item: any) => {
    // Function to open edit modal
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    // Function to close edit modal
    setIsEditModalOpen(false);
  };

  function formatDate(dateString: any) {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  const editsubmit = () => {
    setLoading(true);
    if (!/^[a-zA-Z0-9\s]+$/.test(selectedItem.product_name.trim())) {
      showError(
        "Error",
        "Product name can only contain letters, numbers, and spaces"
      );
      setLoading(false);

      return;
    }

    if (selectedItem.qty <= 0) {
      showError("Error", "Quantity must be greater than zero");
      setLoading(false);
      return;
    }

    if (selectedItem.price <= 0) {
      showError("Error", "Price must be greater than zero");
      setLoading(false);

      return;
    }

    if (!selectedItem.description.trim()) {
      showError("Error", "Description is required");
      setLoading(false);

      return;
    }

    if (selectedItem.low_qty <= 0) {
      showError("Error", "Low quantity must be greater than zero");
      setLoading(false);

      return;
    }

    if (selectedItem.imageFile) {
      const allowedFormats = ["jpeg", "jpg", "png", "gif"]; // Add more formats if needed
      const fileExtension = selectedItem.imageFile.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        showError(
          "Error",
          "Unsupported image format. Please upload an image in JPEG, JPG, PNG, or GIF format."
        );
        setLoading(false);
        return;
      }
    }
    if (parseInt(selectedItem.offer_price) >= parseInt(selectedItem.price)) {
      alert("Offer price must be less than the regular price.");
      setLoading(false);

      return;
    }

    if (parseInt(selectedItem.qty) < parseInt(selectedItem.low_qty)) {
      alert("Quantity must not be less than the minimum quantity.");
      setLoading(false);

      return;
    }
    const storedBusiness = localStorage.getItem("selectedBusiness");

    const editedProduct = {
      ...selectedItem,
      product_id: selectedItem.id,
      bussiness_type: storedBusiness,
    };
    if (!editedProduct.imageFile) {
      editedProduct.image = ""; // or editedPlan.image = undefined;
    }

    callApi("POST", "product_edit.php", editedProduct)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);
          closeEditModal();
          getproductall(selectedBusiness, activeButton);
        }
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };
  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "All Products") {
      getproductall(selectedBusiness, buttonName);
    } else if (buttonName === "In-Stock") {
      getproductall(selectedBusiness, buttonName);
    } else if (buttonName === "Low Stock") {
      getproductall(selectedBusiness, buttonName);
    } else if (buttonName === "Sold") {
      getproductsold(selectedBusiness, "1");
    } else if (buttonName === "Requests") {
      getproductsold(selectedBusiness, "0");
    } else if (buttonName === "Declined") {
      getproductadmin(selectedBusiness);
    }
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(".....", event.target.value);
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    // Allow only numeric characters (0-9), backspace, and delete
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const handleEdit = (item: any) => {
    setSelectedItem({
      ...item,
    });
    setIsEditModalOpen(true);
  };

  const getnotification = (formdata: any, subids: any) => {
    console.log("vvvvvvvvvvv", subids);
    const userIdArray = Array.isArray(formdata.user_id)
      ? formdata.user_id
      : [formdata.user_id];

    const req = {
      id: userIdArray,
      status: subids,
    };
    callApi("POST", "notification.php", req)
      .then((response) => {
        console.log("e3434343f''''''''''''", response.data);
        if (response.data.success === true) {
          let authoritys_token = response.data.responseJson.token;
          for (let i = 0; i < authoritys_token.length; i++) {
            console.log("authoritys_token", authoritys_token[i]);
            sendNotification(
              authoritys_token[i],
              formdata.product_name,
              formdata.date
            );
          }
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleDelete = (item: any) => {
    // Ask for confirmation before deleting
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      setLoading(true);

      console.log("Deleting item:", item.id);
      const req = {
        product_id: item.id,
      };
      callApi("POST", "product_delete.php", req)
        .then((response) => {
          showSuccess("Success..!", response.data.message);
          getproductall(selectedBusiness, activeButton);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
        });
    }
  };

  const downloadExcel = () => {
    if (activeButton === "All Products") {
      const todayDate = new Date();
      const formattedDate =
        todayDate.getDate().toString().padStart(2, "0") +
        "-" +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        todayDate.getFullYear();

      type DataObject = {
        ID: number;
        Product: string;
        Stock: number;
        Status: string;
        Added: string;
        Price?: number;
        offer_price?: any;
        Description: any;
      };

      const flattenedData: DataObject[] = allproduct.map((file, index) => {
        let statusText = "";
        if (file.product_status === "1") {
          statusText = "In-Stock";
        } else if (file.product_status === "2") {
          statusText = "Low Stock";
        } else if (file.product_status === "3") {
          statusText = "Sold";
        }

        const data: DataObject = {
          ID: index + 1,
          Product: file.product_name,
          Stock: file.qty,
          Status: statusText,
          Added: file.added_date,
          offer_price: file.offer_price,
          Description: file.description,
        };

        return data;
      });

      const headers = [
        "ID",
        "Product",
        "Stock",
        "Status",
        "Added",
        "OfferPrice",
        "Description",
      ];

      const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
        header: headers,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Files");
      XLSX.writeFile(wb, `Products_${formattedDate}.xlsx`);
    }
    if (activeButton === "In-Stock" || activeButton === "Low Stock") {
      const todayDate = new Date();
      const formattedDate =
        todayDate.getDate().toString().padStart(2, "0") +
        "-" +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        todayDate.getFullYear();

      type DataObject = {
        ID: number;
        Product: string;
        Stock: number;
        Status: string;
        Added: string;
        Price?: number;
        offer_price?: any;
        Description: any;
      };

      const flattenedData: DataObject[] = filteredData.map((file, index) => {
        let statusText = "";
        if (file.product_status === "1") {
          statusText = "In-Stock";
        } else if (file.product_status === "2") {
          statusText = "Low Stock";
        } else if (file.product_status === "3") {
          statusText = "Sold";
        }

        const data: DataObject = {
          ID: index + 1,
          Product: file.product_name,
          Stock: file.qty,
          Status: statusText,
          Added: file.added_date,
          offer_price: file.offer_price,
          Description: file.description,
        };

        return data;
      });

      const headers = [
        "ID",
        "Product",
        "Stock",
        "Status",
        "Added",
        "OfferPrice",
        "Description",
      ];

      const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
        header: headers,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Files");
      XLSX.writeFile(wb, `Products_${formattedDate}.xlsx`);
    }
    if (activeButton === "Sold") {
      const todayDate = new Date();
      const formattedDate =
        todayDate.getDate().toString().padStart(2, "0") +
        "-" +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        todayDate.getFullYear();

      type DataObject = {
        ID: number;
        Product: string;
        Stock: number;
        paid_amount: string;
        Qty: any;
        purchase_by: string;
        approved_by: string;
        date: any;
      };

      const flattenedData: DataObject[] = filteredData.map((file, index) => {
        let statusText = "";
        if (file.product_status === "1") {
          statusText = "In-Stock";
        } else if (file.product_status === "2") {
          statusText = "Low Stock";
        } else if (file.product_status === "3") {
          statusText = "Sold";
        }

        const data: DataObject = {
          ID: index + 1,
          Product: file.product_name,
          Stock: file.qty,
          paid_amount: file.paid_amount,
          Qty: file.qty,
          purchase_by: file.purchase_by,
          approved_by: file.approved_by,
          date: file.date,
        };

        return data;
      });

      const headers = [
        "ID",
        "Product",
        "Stock",
        "paid_amount",
        "Qty",
        "purchase_by",
        "approved_by",
        "date",
      ];

      const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
        header: headers,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Files");
      XLSX.writeFile(wb, `Products_${formattedDate}.xlsx`);
    }
    if (activeButton === "Requests") {
      const todayDate = new Date();
      const formattedDate =
        todayDate.getDate().toString().padStart(2, "0") +
        "-" +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        todayDate.getFullYear();

      type DataObject = {
        ID: number;
        Product: string;
        quantity: number;
        Requested_by: string;
        Price: string;
        date: string;
      };

      const flattenedData: DataObject[] = filteredData.map((file, index) => {
        let statusText = "";
        if (file.product_status === "1") {
          statusText = "In-Stock";
        } else if (file.product_status === "2") {
          statusText = "Low Stock";
        } else if (file.product_status === "3") {
          statusText = "Sold";
        }

        const data: DataObject = {
          ID: index + 1,
          Product: file.product_name,
          quantity: file.qty,
          Price: file.paid_amount,
          Requested_by: file.purchase_by,
          date: file.date,
        };

        return data;
      });

      const headers = [
        "ID",
        "Product",
        "Quantity",
        "Price",
        "Requested_by",
        "date",
      ];

      const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
        header: headers,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Files");
      XLSX.writeFile(wb, `Products_${formattedDate}.xlsx`);
    }
    if (activeButton === "Declined") {
      const todayDate = new Date();
      const formattedDate =
        todayDate.getDate().toString().padStart(2, "0") +
        "-" +
        (todayDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        todayDate.getFullYear();

      type DataObject = {
        ID: number;
        Product: string;
        Stock: number;
        paid_amount: string;
        Qty: any;
        purchase_by: string;
        approved_by: string;
        date: any;
      };

      const flattenedData: DataObject[] = filteredData.map((file, index) => {
        let statusText = "";
        if (file.product_status === "1") {
          statusText = "In-Stock";
        } else if (file.product_status === "2") {
          statusText = "Low Stock";
        } else if (file.product_status === "3") {
          statusText = "Sold";
        }

        const data: DataObject = {
          ID: index + 1,
          Product: file.product_name,
          Stock: file.qty,
          paid_amount: file.paid_amount,
          Qty: file.qty,
          purchase_by: file.purchase_by,
          approved_by: file.approved_by,
          date: file.date,
        };

        return data;
      });

      const headers = [
        "ID",
        "Product",
        "Stock",
        "paid_amount",
        "Qty",
        "purchase_by",
        "approved_by",
        "date",
      ];

      const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
        header: headers,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, "Files");
      XLSX.writeFile(wb, `Products_${formattedDate}.xlsx`);
    }
  };

  const filteredData =
    allproduct?.filter((item) =>
      Object.values(item).some(
        (value) =>
          value != null &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];
  const filteredDataadmin =
    allproductadmin?.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const pageCountadmin = Math.ceil(filteredDataadmin.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // Get current items to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const rangeEnd = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredData.length
  );
  const totalItems = filteredData.length;

  // Approved
  const indexOfLastItemadmin = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItemadmin = indexOfLastItemadmin - itemsPerPage;
  const currentItemsadmin = filteredDataadmin.slice(
    indexOfFirstItemadmin,
    indexOfLastItemadmin
  );

  const rangeStartadmin = currentPage * itemsPerPage + 1;
  const rangeEndadmin = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredDataadmin.length
  );
  const totalItemsadmin = filteredDataadmin.length;

  // request
  const indexOfLastItemreq = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItemreq = indexOfLastItemreq - itemsPerPage;
  const currentItemsreq = filteredData.slice(
    indexOfFirstItemreq,
    indexOfLastItemreq
  );

  const rangeStartreq = currentPage * itemsPerPage + 1;
  const rangeEndreq = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredData.length
  );
  const totalItemsreq = filteredData.length;
  const emptyObject: any = {};
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="products-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Products</p>
        <div className="dashboard-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Product List</p>
          <div className="buttons-container">
            <button className="export-button" onClick={downloadExcel}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={exportsvg}
                  alt="Export"
                  style={{ marginRight: "5px", marginLeft: "12px" }}
                />
                <span style={{ color: "purple" }}>Export</span>
              </div>
            </button>
            <button className="add-button1" onClick={openModal}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={plus}
                  alt="Add Product"
                  style={{ marginRight: "5px" }}
                />
                <span style={{ color: "white" }}>Add Product</span>
              </div>
            </button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          buttontext={"Product"}
          selecteddata={emptyObject}
        />

        <div className="card-and-search-container">
          {/* Cardmodal */}
          <div className="cardmodal">
            <button
              className={activeButton === "All Products" ? "active" : ""}
              onClick={() => handleButtonClick("All Products")}
            >
              All Products
            </button>
            <button
              className={activeButton === "In-Stock" ? "active" : ""}
              onClick={() => handleButtonClick("In-Stock")}
            >
              In-Stock
            </button>
            <button
              className={activeButton === "Low Stock" ? "active" : ""}
              onClick={() => handleButtonClick("Low Stock")}
            >
              Low Stock
            </button>
            <button
              className={activeButton === "Sold" ? "active" : ""}
              onClick={() => handleButtonClick("Sold")}
            >
              Sold
            </button>
            <button
              className={activeButton === "Requests" ? "active" : ""}
              onClick={() => handleButtonClick("Requests")}
            >
              Requests
              <button
                style={{
                  borderRadius: "20px",
                  background:
                    "linear-gradient(to bottom,rgba(136, 61, 207, 1),rgba(69, 31, 105, 1))",
                  color: "white",
                }}
              >
                {productreqcount}
              </button>
            </button>

            <button
              className={activeButton === "Declined" ? "active" : ""}
              onClick={() => handleButtonClick("Declined")}
            >
              Declined
            </button>
          </div>
          {/* Filter input */}
          <div className="search-field1">
            <input
              type="text"
              placeholder="Search Product.."
              className="search-input1"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {/* <div className="date-input">
            <input
              type="date"
              onChange={(e) => setSelectedDate(e.target.value)}
              className="date-input-field"
            />
          </div> */}
          {/* <div>
      <button className="filter-button">
        <img src={filtersvg} alt="Filter" className="filter-icon" />
        Filter
      </button>
    </div> */}
        </div>

        {/* Table */}
        <div className="table-container">
          {(activeButton === "All Products" ||
            activeButton === "In-Stock" ||
            activeButton === "Low Stock") && (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>Product</th>
                  <th>Product Name</th>
                  <th style={{ width: "200px" }}>Description</th>
                  <th>Stock</th>
                  <th>Price</th>
                  <th>Offer Price</th>
                  <th>Status</th>
                  <th style={{ width: "12%" }}>Added On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan={7}>No data available</td>{" "}
                  </tr>
                ) : (
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {rangeStart + index}
                      </td>
                      <td>
                        <img
                          src={item.image}
                          alt={item.product_name}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>{item.product_name}</td>
                      <td
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                        }}
                      >
                        <p>{item.description}</p>
                      </td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td>{item.offer_price}</td>
                      <td>
                        {item.product_status === "1" && (
                          <img
                            src={instocksvg}
                            alt="In Stock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {item.product_status === "2" && (
                          <img
                            src={lowstocksvg}
                            alt="Low Stock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {item.product_status === "3" && (
                          <img
                            src={outofstocksvg}
                            alt="outofstock"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                      </td>
                      <td>{item.added_date}</td>
                      <td>
                        <img
                          src={editsvg}
                          alt="Edit"
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => openEditModal(item)}
                        />
                        <img
                          src={deletesvg}
                          alt="Delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(item)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
          {activeButton === "Sold" && (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Product</th>
                  <th style={{ width: "30%" }}>Product Name</th>
                  <th>Purchased By</th>
                  <th>Quantity</th>
                  <th>Paid Amount</th>
                  <th>Date&Time</th>
                  <th>Approved By</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan={4}>No data available</td>
                  </tr>
                ) : (
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {rangeStart + index}
                      </td>
                      <td>
                        {" "}
                        <img
                          src={item.image}
                          alt={item.product_name}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>
                        <span>{item.product_name}</span>
                      </td>
                      <td>{item.purchase_by}</td>
                      <td>{item.qty}</td>
                      <td>{item.paid_amount}</td>
                      <td>{item.date}</td>
                      <td>{item.approved_by}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
          {activeButton === "Requests" && (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Product</th>
                  <th style={{ width: "30%" }}>Product Name</th>
                  <th>Requested By</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Date&Time</th>
                  <th style={{ width: "25%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {rangeStart + index}
                    </td>
                    <td>
                      <img
                        src={item.image}
                        alt={item.product_name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      {" "}
                      <span>{item.product_name}</span>
                    </td>
                    <td>{item.purchase_by}</td>
                    <td>{item.qty}</td>
                    <td>{item.paid_amount}</td>
                    <td>{item.date}</td>
                    <td style={{ width: "22%" }}>
                      <button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          color: "white",
                          marginRight: "5px",
                          border: "none",
                          width: "100px",
                          height: "30px",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleApprove(item, 2)}
                      >
                        Decline
                      </button>
                      <button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "green",
                          color: "white",
                          border: "none",
                          width: "100px", // Adjust width as needed
                          height: "30px",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleApprove(item, 1)}
                      >
                        Approve
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {activeButton === "Declined" && (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Product</th>
                  <th>Product Name</th>
                  <th>Purchase By</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Declined By</th>
                </tr>
              </thead>
              <tbody>
                {currentItemsadmin.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {rangeStart + index}
                    </td>
                    <td>
                      <img
                        src={item.image}
                        alt={item.product_name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      {" "}
                      <span>{item.product_name}</span>
                    </td>
                    <td>{item.purchase_by}</td>
                    <td>{item.qty}</td>
                    <td>{item.paid_amount}</td>
                    <td>{item.declined_by || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {(activeButton === "All Products" ||
          activeButton === "In-Stock" ||
          activeButton === "Low Stock" ||
          activeButton === "Requests" ||
          activeButton === "Sold") && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStart}-{rangeEnd} from {totalItems}
            </div>

            {/* Pagination */}

            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}

        {activeButton === "Declined" && (
          <div className="pagination-and-info">
            {/* Showing items information */}
            <div className="items-info">
              Showing {rangeStartadmin}-{rangeEndadmin} from {totalItemsadmin}
            </div>

            {/* Pagination */}
            <div className="pagination">
              <ReactPaginate
                previousLabel={
                  <img
                    src={pageleft}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                nextLabel={
                  <img
                    src={pageright}
                    alt="In Stock"
                    style={{ marginRight: "5px" }}
                  />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountadmin}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
        {isEditModalOpen && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closeEditModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Product</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="title">
                      Product Name:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="product_name"
                      value={selectedItem.product_name} // Use the existing data from selectedItem
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          product_name: e.target.value,
                        })
                      }
                      required
                      maxLength={30}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div>
                    <label htmlFor="qty">
                      Quantity:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="qty"
                      onKeyDown={handleKeyDown}
                      min={0}
                      maxLength={10}
                      value={selectedItem.qty}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          qty: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="price">
                      Price:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="price"
                      min={0}
                      value={selectedItem.price}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          price: parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="price">Offer Price:</label>
                    <input
                      type="number"
                      id="offprice"
                      min={0}
                      value={selectedItem.offer_price}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          offer_price: parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="description">Description:</label>

                    <textarea
                      id="description"
                      value={selectedItem.description}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          description: e.target.value,
                        })
                      }
                      minLength={1}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="low_qty">
                      Low Quantity:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="low_qty"
                      value={selectedItem.low_qty}
                      onKeyDown={handleKeyDown}
                      min={0}
                      maxLength={10}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          low_qty: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="image">New Image:</label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="image"
                        style={{ width: "175px" }}
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const validImageTypes = [
                              "image/jpeg",
                              "image/png",
                              "image/gif",
                              "image/webp",
                            ]; // Add any other image types you want to support
                            if (!validImageTypes.includes(file.type)) {
                              alert("Please upload a valid image file.");
                              e.target.value = ""; // Clear the file input field
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              setSelectedItem({
                                ...selectedItem,
                                image: reader.result as string, // Set image URL to data URL
                                imageFile: file,
                                imageformat: file.type.split("/")[1],
                              });
                            };
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="existing-image">
                    <label>Existing Image :</label>
                    {selectedItem.image && (
                      <img
                        src={selectedItem.image}
                        alt="Existing Image"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginLeft: "30%",
                        }} // Adjust dimensions as needed
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={editsubmit}
                disabled={loading}
                style={{ marginTop: "0px" }}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Products;
