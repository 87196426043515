import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import "../pages/style/banner.css";
import rightarrow from "../../assets/arrowright.svg";
import plus from "../../assets/Header/plus.svg";
import deletesvg from "../../assets/product/delete.svg";
import editsvg from "../../assets/product/edit.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import ReactPaginate from "react-paginate";
import filtersvg from "../../assets/filter.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";

interface Role {
  role: string;
  bussiness_type: string;
}
interface BusinessType {
  id: string;
  bussiness_name: string;
}
const Role: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("All Products");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [roledata, setroledata] = useState<Role[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUserRoleExist, setIsUserRoleExist] = useState(false);
  const [roleExistUser, setRoleExistUser] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      console.log("Selected Business:", storedBusiness);
      getrole();
    }

    getbussiness();
  }, []);

  useEffect(() => {
    console.log("roleExistUser________", roleExistUser);
  }, [roleExistUser]);

  const getbussiness = () => {
    callApi("GET", "bussiness_type.php")
      .then((response) => {
        console.log("business Response:", response.data.responseJson);
        setBusinessTypes(response.data.responseJson);
        if (response.data.responseJson.length > 0) {
          setSelectedBusiness(response.data.responseJson[0].bussiness_name);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const bussinessname = localStorage.getItem("Businessname") || "";
  const getrole = () => {
    setLoading(true);

    const req = {
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    callApi("POST", "select_role.php", req)
      .then((response) => {
        setroledata(response.data.responseJson);
        setLoading(false);
        console.log("role", response);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);
  };

  const handleKeyPressname = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (item: any) => {
    setSelectedItem({
      ...item,
    });

    setIsEditModalOpen(true);
  };

  const handleDelete = (item: any) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      const req = {
        id: item.id,
        name: item.role,
      };
      callApi("POST", "role_delete.php", req)
        .then((response) => {
          if (response.data.responseCode == 200) {
            setRoleExistUser(response.data.response);
            getrole();
            setIsUserRoleExist(false);
            showSuccess("Success!", response.data.message);
          } else {
            if (response.data.count > 0) {
              setIsUserRoleExist(true);
              setRoleExistUser(response.data.response);
            } else {
              setIsUserRoleExist(false);
            }
          }

          // toast.success(response.data.message, { autoClose: 3000 });
        })
        .catch((error) => {
          console.error("Error deleting banner:", error);
        });
    } else {
      // Cancel deletion
      console.log("Deletion canceled.");
    }
  };
  const editsubmit = () => {
    setLoading(true);
    const editedrole = {
      id: selectedItem.id,
      name: selectedItem.role,
      bussiness_type: localStorage.getItem("selectedBusiness"),
    };
    console.log("Submitting edited product:", editedrole);

    callApi("POST", "role_update.php", editedrole)
      .then((response) => {
        showSuccess("Success..!", response.data.message);
        setLoading(false);
        closeEditModal();
        getrole();
      })
      .catch((error) => {
        console.error("Error updating banner:", error);
      });
  };

  const filteredData =
    roledata && roledata.length > 0
      ? roledata.filter((item) =>
          Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : [];

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const rangeEnd = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredData.length
  );
  const totalItems = filteredData.length;
  const emptyObject: any = {};
  return (
    <>
      <div className="products-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Role</p>
        <div className="dashboard-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Role</p>
          <div className="buttons-container">
            <div className="search-field1" style={{ marginRight: "10px" }}>
              <input
                type="text"
                placeholder="Search Role.."
                className="search-input1"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <button className="add-button1" onClick={openModal}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img src={plus} alt="Add Role" style={{ marginRight: "5px" }} />
                <span style={{ color: "white" }}>Add Role</span>
              </div>
            </button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          buttontext={"Role"}
          selecteddata={emptyObject}
        />

        {/* Table */}

        <div className="table-container">
          <table className="custom-table-ban">
            <thead>
              <tr>
                <th>SNo</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{rangeStart + index}</td>
                  <td>{item.role}</td>
                  <td>
                    <img
                      src={editsvg}
                      alt="Edit"
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() => handleEdit(item)}
                    />
                    <img
                      src={deletesvg}
                      alt="Delete"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-and-info">
          {/* Showing items information */}
          <div className="items-info">
            Showing {rangeStart}-{rangeEnd} from {totalItems}
          </div>

          {/* Pagination */}
          <div className="pagination">
            <ReactPaginate
              previousLabel={
                <img
                  src={pageleft}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              nextLabel={
                <img
                  src={pageright}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {isUserRoleExist && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button
                className="close-button"
                onClick={() => setIsUserRoleExist(false)}
              >
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>
                The Role can't be deleted because it is assigned to the below
                users.
              </h2>
              <div className="">
                {roleExistUser.length > 0 &&
                  roleExistUser.map((user: any, index: any) => (
                    <>
                      <p>
                        <span>{index + 1})</span> {user.name}
                      </p>
                    </>
                  ))}
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={() => setIsUserRoleExist(false)}
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Ok"
                )}
              </button>
            </div>
          </div>
        )}
        {isEditModalOpen && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closeEditModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Role</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="title">
                      Role:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={selectedItem.role}
                      onKeyPress={handleKeyPressname}
                      required
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          role: e.target.value,
                        })
                      }
                      maxLength={30}
                    />
                  </div>

                  <div>
                    <label htmlFor="qty">Bussiness Type:</label>
                    <input
                      type="text"
                      id="bussiness_type"
                      value={bussinessname}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          bussiness_type: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={editsubmit}
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
        {loading && (
          <div className="loader-overlay">
            <div className="loader-container">
              <Hourglass visible={true} height={50} width={50} />
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default Role;
