import React, { useEffect, useRef, useState } from "react";
import totalsubscriber from "../../assets/dashboard/totalsubscriber.svg";
import activesubscriber from "../../assets/dashboard/activesubscriber.svg";
import totalearnings from "../../assets/dashboard/totalearning.svg";
import uparrow from "../../assets/dashboard/uparrow.svg";
import downarrow from "../../assets/dashboard/downarrow.svg";
import totalpending from "../../assets/dashboard/totalpending.svg";
import lowstock from "../../assets/dashboard/lowstock.svg";
import totpending from "../../assets/dashboard/totpending.svg";
import actsub from "../../assets/dashboard/actsub.svg";
import renewal from "../../assets/dashboard/renewal.svg";
import renewalweek from "../../assets/dashboard/renewalweek.svg";
import Chart from "chart.js/auto";
import { callApi } from "../utils/api";
import "../pages/style/Dashboard.css";

interface dashboard {
  total_subscriber_count: string;
  total_subscriber_percentage: number;
  active_subscriber_count: string;
  active_subscriber_percentage: number;
  total_product_count: string;
  percentage_toal_product: number;
  lowqty_product_count: string;
  low_product_quarter_percentange: number;
  total_earning: string;
  percentage_toal_earning: number;
  pending_amt: string;
  percentage_toal_pending: number;
  percentage_total_renewal: number;
  percentage_total_renewal_last_week: number;
  total_renewal_amt: string;
  total_renewal_amt_week: string;
}

interface sellingproduct {
  id: string;
  product_name: string;
  product_status: string;
  qty: string;
  price: string;
  size: string;
  description: string;
  offer: string;
  offer_price: string;
  low_qty: string;
  created_dt: string;
  image: string;
  total_purchase: string;
}
interface BarChartData {
  labels: string[];
  data: number[];
}

interface barchart {}

const Dashboard: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart>();
  const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
  const doughnutChartRef = useRef<HTMLCanvasElement>(null);
  const doughnutChartInstance = useRef<
    Chart<"doughnut", number[], unknown> | undefined
  >();
  const [productOnePercentage, setProductOnePercentage] = useState<number>(0);
  const [productTwoPercentage, setProductTwoPercentage] = useState<number>(0);
  const [productThreePercentage, setProductThreePercentage] =
    useState<number>(0);
  const [productFourPercentage, setProductFourPercentage] = useState<number>(0);
  const [productFivePercentage, setProductFivePercentage] = useState<number>(0);
  const [productOneName, setProductOneName] = useState("");
  const [productTwoName, setProductTwoName] = useState("");
  const [productThreeName, setProductThreeName] = useState("");
  const [productFourName, setProductFourName] = useState("");
  const [productFiveName, setProductFiveName] = useState("");
  const [productOneAmt, setProductOneAmt] = useState("");
  const [productTwoAmt, setProductTwoAmt] = useState("");
  const [productThreeAmt, setProductThreeAmt] = useState("");
  const [productFourAmt, setProductFourAmt] = useState("");
  const [productFiveAmt, setProductFiveAmt] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [dashboarddata, setdashboarddata] = useState<dashboard[]>([]);
  const [sellingproduct, setsellingproduct] = useState<sellingproduct[]>([]);
  const [barChartData, setBarChartData] = useState<BarChartData>({
    labels: [],
    data: [],
  });

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      getsellingproduct(storedBusiness, "30");
      getdashboarddata(storedBusiness);
    }
    const fetchChartData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        const req = { bussiness_type: storedBusiness, year: currentYear };
        const response = await callApi("POST", "admin_earn_chart.php", req);
        const chartData = response.data.responseJson;

        console.log("API Response:", chartData);

        const labels = chartData.map((item: any) => item.month);
        const data = chartData.map((item: any) => +item.monthly_paid_amt);

        console.log("Labels:", labels);
        console.log("Data:", data);

        const backgroundColors = labels.map((label: any, index: any) => {
          const monthIndex = index + 1;
          return monthIndex === currentMonth
            ? "#FF6384"
            : data[index] < 0
            ? "red"
            : "grey";
        });

        console.log("Background Colors:", backgroundColors);

        setBarChartData({ labels, data });

        if (chartRef.current) {
          const ctx = chartRef.current.getContext("2d");
          if (ctx) {
            if (chartInstance.current) {
              chartInstance.current.destroy();
            }

            chartInstance.current = new Chart(ctx, {
              type: "bar",
              data: {
                labels,
                datasets: [
                  {
                    label: "Monthly Earnings",
                    data,
                    backgroundColor: backgroundColors,
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 0,
                    borderRadius: 10,
                    hoverBackgroundColor: "purple",
                  },
                ],
              },
              options: {
                scales: {
                  x: {
                    grid: { display: false },
                  },
                  y: {
                    display: true,
                    beginAtZero: false,
                    grid: { display: false },
                  },
                },
              },
            });
          }
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    const fetchpieChartData = async () => {
      try {
        const storedBusiness = localStorage.getItem("selectedBusiness");

        const req = {
          bussiness_type: storedBusiness,
        };
        const response = await callApi("POST", "admin_product_chart.php", req);
        const piechartData = response.data.responseJson;

        console.log("Received pie chart data:", piechartData);

        // Extracting data from the response
        const productOne = piechartData[0].percentage
          ? parseFloat(piechartData[0].percentage)
          : 0;
        const productTwo = piechartData[1].percentage
          ? parseFloat(piechartData[1].percentage)
          : 0;
        const productThree = piechartData[2].percentage
          ? parseFloat(piechartData[2].percentage)
          : 0;
        const productFour = piechartData[3].percentage
          ? parseFloat(piechartData[3].percentage)
          : 0;
        const productFive = piechartData[4].percentage
          ? parseFloat(piechartData[4].percentage)
          : 0;

        const productNameOne = piechartData[0].product_name
          ? piechartData[0].product_name
          : "";
        const productNameTwo = piechartData[1].product_name
          ? piechartData[1].product_name
          : "";
        const productNameThree = piechartData[2].product_name
          ? piechartData[2].product_name
          : "";
        const productNameFour = piechartData[3].product_name
          ? piechartData[3].product_name
          : "";
        const productNameFive = piechartData[4].product_name
          ? piechartData[4].product_name
          : "";

        const productAmtOne = piechartData[0].purchase_amt
          ? piechartData[0].purchase_amt
          : "";
        const productAmtTwo = piechartData[1].purchase_amt
          ? piechartData[1].purchase_amt
          : "";
        const productAmtThree = piechartData[2].purchase_amt
          ? piechartData[2].purchase_amt
          : "";
        const productAmtFour = piechartData[3].purchase_amt
          ? piechartData[3].purchase_amt
          : "";
        const productAmtFive = piechartData[4].purchase_amt
          ? piechartData[4].purchase_amt
          : "";

        setProductOnePercentage(productOne);
        setProductTwoPercentage(productTwo);
        setProductThreePercentage(productThree);
        setProductFourPercentage(productFour);
        setProductFivePercentage(productFive);

        setProductOneName(productNameOne);
        setProductTwoName(productNameTwo);
        setProductThreeName(productNameThree);
        setProductFourName(productNameFour);
        setProductFiveName(productNameFive);

        setProductOneAmt(productAmtOne);
        setProductTwoAmt(productAmtTwo);
        setProductThreeAmt(productAmtThree);
        setProductFourAmt(productAmtFour);
        setProductFiveAmt(productAmtFive);

        // Update the doughnut chart canvas size to fit its container
        const container = doughnutChartRef.current?.parentElement;
        if (container) {
          const containerWidth = container.clientWidth;
          const containerHeight = container.clientHeight;

          // Set canvas width and height to specific dimensions that fit the container
          doughnutChartRef.current.width = containerWidth * 0.8; // Adjust as needed
          doughnutChartRef.current.height = containerHeight * 0.8; // Adjust as needed
        }

        // Updating the pie chart dataset
        if (doughnutChartRef.current) {
          const ctx = doughnutChartRef.current.getContext("2d");
          if (ctx) {
            if (doughnutChartInstance.current) {
              doughnutChartInstance.current.destroy();
            }

            doughnutChartInstance.current = new Chart(ctx, {
              type: "doughnut",
              data: {
                labels: [
                  `${productNameOne}(${productAmtOne})`,
                  `${productNameTwo}(${productAmtTwo})`,
                  `${productNameThree}(${productAmtThree})`,
                  `${productNameFour}(${productAmtFour})`,
                  `${productNameFive}(${productAmtFive})`,
                ], // Labels for the dataset

                // labels: [productNameOne, productNameTwo, productNameThree, productNameFour, productNameFive], // Labels for the dataset
                datasets: [
                  {
                    label: "Dataset",
                    data: [
                      productOne,
                      productTwo,
                      productThree,
                      productFour,
                      productFive,
                    ], // Using received product percentage and remaining percentage
                    backgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#4cf299",
                      "#ff9066",
                      "#8280ff",
                    ],
                    hoverBackgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#4cf299",
                      "#ff9066",
                      "#8280ff",
                    ],
                  },
                ],
              },
              options: {
                responsive: false, // Disable responsiveness
                plugins: {
                  legend: {
                    display: true,
                    position: "right", // Position the legend on the right side
                  },
                },
              },
            });
          }
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchpieChartData();
    fetchChartData();
  }, [selectedBusiness]);

  useEffect(() => {}, [
    productOneAmt,
    productTwoAmt,
    productThreeAmt,
    productFourAmt,
    productFiveAmt,
  ]);

  useEffect(() => {}, [
    productOneName,
    productTwoName,
    productThreeName,
    productFourName,
    productFiveName,
  ]);

  useEffect(() => {}, [
    productOnePercentage,
    productTwoPercentage,
    productThreePercentage,
    productFourPercentage,
    productFivePercentage,
  ]);

  const getsellingproduct = (businessType: string, filterdata: any) => {
    const req = {
      bussiness_type: businessType,
      filter_days: filterdata,
    };
    callApi("POST", "most_selling_product.php", req)
      .then((response) => {
        setsellingproduct(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const getdashboarddata = (businessType: string) => {
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "admin_dashboard.php", req)
      .then((response) => {
        setdashboarddata(response.data.responseJson);
        console.log("dashboard", response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handlepagenavigate = (page: any) => {
    localStorage.setItem("activeMenu", page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const handleDataPeriodChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDataPeriod(event.target.value as "yearly" | "monthly");
  };

  const handlesortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("Selected sort value:", event.target.value);
    const filterdata = event.target.value;
    getsellingproduct(selectedBusiness, filterdata);
  };

  const filteredData = sellingproduct
    ? sellingproduct.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : sellingproduct;
  return (
    <div>
      {/* Card Container */}
      <div className="card-container">
        {dashboarddata.map((item, index) => (
          <>
            <div className="card" key={index}>
              <a
                href="/layout/subscribers"
                onClick={() => handlepagenavigate("Subscribers")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Total Subscribers</span>
                <img
                  src={totalsubscriber}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.total_subscriber_count}</h1>
                <span className="percentage-container">
                  {item.total_subscriber_percentage < 0 ? (
                    <img
                      src={downarrow}
                      alt="Down Arrow"
                      className="arrow-icon"
                    />
                  ) : (
                    <img src={uparrow} alt="Up Arrow" className="arrow-icon" />
                  )}
                  <span
                    className={`percentage ${
                      item.total_subscriber_percentage < 0 ? "negative" : ""
                    }`}
                  >
                    {item.total_subscriber_percentage}%
                  </span>{" "}
                  {item.total_subscriber_percentage < 0 ? "Down" : "Up"} from
                  last year
                </span>
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/subscribers"
                onClick={() => handlepagenavigate("Subscribers")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Active Subscribers</span>
                <img
                  src={actsub}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.active_subscriber_count}</h1>
                <span className="percentage-container">
                  {item.active_subscriber_percentage < 0 ? (
                    <img
                      src={downarrow}
                      alt="Down Arrow"
                      className="arrow-icon"
                    />
                  ) : (
                    <img src={uparrow} alt="Up Arrow" className="arrow-icon" />
                  )}
                  <span
                    className={`percentage ${
                      item.active_subscriber_percentage < 0 ? "negative" : ""
                    }`}
                  >
                    {item.active_subscriber_percentage}%
                  </span>{" "}
                  {item.active_subscriber_percentage < 0 ? "Down" : "Up"} from
                  last quater
                </span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/products"
                onClick={() => handlepagenavigate("Products")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Total Products</span>
                <img
                  src={activesubscriber}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.total_product_count}</h1>
                <span className="percentage-container">
                  {item.percentage_toal_product < 0 ? (
                    <img
                      src={downarrow}
                      alt="Down Arrow"
                      className="arrow-icon"
                    />
                  ) : (
                    <img src={uparrow} alt="Up Arrow" className="arrow-icon" />
                  )}
                  <span
                    className={`percentage ${
                      item.percentage_toal_product < 0 ? "negative" : ""
                    }`}
                  >
                    {item.percentage_toal_product}%
                  </span>{" "}
                  {item.percentage_toal_product < 0 ? "Down" : "Up"} from last
                  quater
                </span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/products"
                onClick={() => {
                  handlepagenavigate("Products");
                  localStorage.setItem("activeButton", "Low Stock");
                }}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Product Low Stock</span>
                <img
                  src={lowstock}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.lowqty_product_count}</h1>
                <span className="percentage-container">
                  {item.low_product_quarter_percentange < 0 ? (
                    <img
                      src={downarrow}
                      alt="Down Arrow"
                      className="arrow-icon"
                    />
                  ) : (
                    <img src={uparrow} alt="Up Arrow" className="arrow-icon" />
                  )}
                  <span
                    className={`percentage ${
                      item.low_product_quarter_percentange < 0 ? "negative" : ""
                    }`}
                  >
                    {item.low_product_quarter_percentange}%
                  </span>{" "}
                  {item.low_product_quarter_percentange < 0 ? "Down" : "Up"}{" "}
                  from last quater
                </span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/finance"
                onClick={() => handlepagenavigate("Finance")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Total Earnings</span>
                <img
                  src={totalearnings}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.total_earning || 0}</h1>
                <span className="percentage-container">
                  {item.percentage_toal_earning < 0 ? (
                    <img
                      src={downarrow}
                      alt="Down Arrow"
                      className="arrow-icon"
                    />
                  ) : (
                    <img src={uparrow} alt="Up Arrow" className="arrow-icon" />
                  )}
                  <span
                    className={`percentage ${
                      item.percentage_toal_earning < 0 ? "negative" : ""
                    }`}
                  >
                    {item.percentage_toal_earning}%
                  </span>{" "}
                  {item.percentage_toal_earning < 0 ? "Down" : "Up"} from last
                  Month
                </span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/subscribers"
                onClick={() => handlepagenavigate("Finance")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Total Pending</span>
                <img
                  src={totpending}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.pending_amt || 0}</h1>
                <span className="percentage-container"></span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/finance"
                onClick={() => handlepagenavigate("Finance")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Total Renewal</span>
                <img
                  src={renewal}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.total_renewal_amt || 0}</h1>
                <span className="percentage-container"></span>{" "}
              </a>
            </div>
            <div className="card">
              <a
                href="/layout/products"
                onClick={() => handlepagenavigate("Products")}
                style={{ color: "black", textDecoration: "none" }}
              >
                <span>Renewals This Week</span>
                <img
                  src={renewalweek}
                  alt="Total Subscribers"
                  className="subscriber-icon"
                />
                <h1>{item.total_renewal_amt_week || 0}</h1>
                <span className="percentage-container"></span>{" "}
              </a>
            </div>
          </>
        ))}
      </div>
      <div
        className="container"
        style={{ marginTop: "-160px", marginBottom: "-100px" }}
      >
        <div className="card-chart">
          <h4>Overview</h4>
          <p>Membership Earnings</p>
          {barChartData.data.every((amount) => amount === 0) ? (
            <div className="error-message-container">
              <p>No data available</p>
            </div>
          ) : (
            <>
              <div className="select-container">
                <select value={dataPeriod} onChange={handleDataPeriodChange}>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <canvas ref={chartRef}></canvas>
            </>
          )}
        </div>

        <div className="card-chart2">
          <h4 style={{ marginTop: "0px" }}>Customers</h4>
          <p style={{ marginBottom: "0px" }}>Customers that buy products</p>
          {productOnePercentage === 0 &&
          productTwoPercentage === 0 &&
          productThreePercentage === 0 &&
          productFourPercentage === 0 &&
          productFivePercentage === 0 ? (
            <div className="error-message-container">
              <p>No data available</p>
            </div>
          ) : (
            <canvas ref={doughnutChartRef} width={200} height={200}></canvas>
          )}
        </div>
      </div>
      <div className="container-table">
        <div className="table-container">
          <div className="table-header">
            <h2>Most Selling Products</h2>
            <div className="search-field1">
              <input
                type="text"
                placeholder="Search Product.."
                className="search-input1"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="dropdown-container">
              <select onChange={handlesortChange}>
                <option value="30">last 30 Days</option>
                <option value="60">last 60 days</option>
              </select>
            </div>
          </div>
          {filteredData && filteredData.length > 0 ? (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Stock</th>
                  <th>Price</th>
                  <th>Total Sales</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td className="product-name-column">
                      <div className="product-info">
                        <img
                          src={item.image}
                          alt={item.product_name}
                          className="product-image"
                        />
                        <div className="product-details">
                          <span className="bold-text">{item.product_name}</span>{" "}
                          <span>{item.description}</span>
                        </div>
                      </div>
                    </td>
                    <td>{item.qty}</td>
                    <td>{item.price}</td>
                    <td>{item.total_purchase}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no-data-message">No data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
