import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import rightarrow from "../../assets/arrowright.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style/settings.css";
import { callApi } from "../utils/api";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";

interface user {
  id: number;
  user_name: string;
  name: string;
  email: string;
  phone_no: string;
  address: string;
  gender: string;
  occupation: string;
  admission_no: string;
  doj: string;
  blood_group: string;
  dob: string;
  active_status: string;
  image: string;
  password: string;
}

const Settings: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activeButton") || "General";
  });
  const [editableField, setEditableField] = useState("");
  const [userId, setUserId] = useState("");
  const [userdata, setuserdata] = useState<user[]>([]);
  const [pageList, setPageList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [authUpdate, setAuthUpdate] = useState(false);
  const [passworderror, setpassworderror] = useState(false);

  useEffect(() => {
    // Save active button to local storage whenever it changes
    localStorage.setItem("activeButton", activeButton);

    // Cleanup function to remove stored active button when navigating away from the page
    return () => {
      localStorage.removeItem("activeButton");
    };
  }, [activeButton]);
  
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const storedBusiness = localStorage.getItem("selectedBusiness");

    if (storedUserId) {
      setUserId(storedUserId);
      getuser(storedUserId);
    }

    let req = {
      bussiness_type: storedBusiness
    }

    callApi("POST", "authentication_select.php", req)
      .then((responseJson: any) => {
        if (responseJson.status == 200) {
          let DynamicPages = responseJson.data.responseJson.data;
          setPageList(DynamicPages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleButtonClick(activeButton);
  }, []);

  const getuser = (userid: string) => {
    const req = {
      id: localStorage.getItem("userId"),
    };
    callApi("POST", "admin_info.php", req)
      .then((response) => {
        setuserdata(response.data.responseJson);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleEditClick = (fieldName: string) => {
    setEditableField(fieldName);
  };

  const handleFieldChange = (
    id: number,
    newValue: string,
    fieldName: string
  ) => {
    console.log(fieldName, newValue);
    setuserdata((prevState) =>
      prevState.map((user) =>
        user.id === id ? { ...user, [fieldName]: newValue } : user
      )
    );
    if (fieldName === 'password') {
      const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(newValue);
      if (!ValidInput) {
        setpassworderror(true);
        console.error("Invalid input. Please enter a valid password.");
        return;
      } else {
        setpassworderror(false);
        console.log("password___success");

        setuserdata((prevState) =>
          prevState.map((user) =>
            user.id === id ? { ...user, [fieldName]: newValue } : user
          )
        );
      }
    }
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/;
    if (!regex.test(keyValue) && keyCode !== 8 && keyCode !== 46) {
      event.preventDefault();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Form Data:", userdata); // Log the form data

    const req = userdata[0];
    callApi("POST", "admin_edit.php", req)
      .then((response) => {
        if (response.data.success === true) {
          setuserdata(response.data.responseJson);
          getuser(userId);
          setEditableField("");
          showSuccess("Success..!", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const handleBlurname = () => {
    if (passworderror == false) {
      const req = userdata[0];
      callApi("POST", "admin_edit.php", req)
        .then((response) => {
          if (response.data.success === true) {
            setuserdata(response.data.responseJson);
            getuser(userId);
            setEditableField("");
            showSuccess("Success..!", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
        });
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };
  const handlePasswordChange = (id: number, newPassword: string) => {
    setuserdata((prevState) =>
      prevState.map((user) =>
        user.id === id ? { ...user, password: newPassword } : user
      )
    );
  };

  const [state, setState] = useState({
    GetRoleData: [],
  });

  useEffect(() => {
    getTableDetails();
  }, []);

  const getTableDetails = () => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    let req = {
      bussiness_type: storedBusiness
    }
    callApi("POST", "get_role.php", req)
      .then((res: any) => {
        if (res.data.result == true) {
          console.log("coming sakthi", res.data.result);
          setState((prevState) => ({
            ...prevState,
            GetRoleData: res.data.data,
          }));
        }
        console.log("surya role list", state.GetRoleData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangetable = (
    event: React.ChangeEvent<HTMLInputElement>,
    pageIndex: number,
    roleName: string,
    pageName: string
  ) => {
    const { checked } = event.target;
    // Show confirmation dialog
    const userConfirmed = window.confirm(
      `Are you sure you want to ${checked ? "select" : "deselect"
      } ${pageName} in ${roleName}?`
    );
    if (!userConfirmed) {
      // If the user cancels, do nothing
      return;
    }
    // Update the pageList state
    setPageList((prevPageList: any[]) => {
      return prevPageList.map((page, i) => {
        if (i === pageIndex) {
          const updatedRoleList = page.role_list.map((roleItem: any) => {
            if (roleItem.role_name == roleName) {
              return { ...roleItem, ischecked: checked };
            }
            return roleItem;
          });

          return { ...page, role_list: updatedRoleList };
        }
        return page;
      });
    });
    setAuthUpdate(true);
    console.log("event____", pageIndex, roleName, pageName, pageList);
  };

  useEffect(() => {
    if (authUpdate) {
      let req = {
        authenticating_pages: pageList,
      };
      callApi("POST", "authentication_update.php", req)
        .then((response) => {
          if (response) {
            let res = response;
            showSuccess("Success", response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setAuthUpdate(false);
    }
  }, [authUpdate]);


  return (
    <>
      <ToastContainer />
      <div className="settings-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Personal Info</p>
        <div className="settings-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Settings</p>
        </div>
        <div className="cardmodal-settings">
          <button
            className={activeButton === "General" ? "active" : ""}
            onClick={() => handleButtonClick("General")}
          >
            General
          </button>
          <button
            className={activeButton === "Authentications" ? "active" : ""}
            onClick={() => handleButtonClick("Authentications")}
          >
            Authentications
          </button>
        </div>
        {activeButton === "General" && (
          <div className="personal-info-container">
            <form onSubmit={handleSubmit}>
              {userdata &&
                userdata.length > 0 &&
                userdata.map((data) => (
                  <>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="fullname">User Name</label>
                          {editableField === "fullname" ? (
                            <div className="input-row1">
                              <input
                                type="text"
                                value={data.user_name}
                                maxLength={20}
                                onChange={(e) =>
                                  handleFieldChange(
                                    data.id,
                                    e.target.value,
                                    "user_name"
                                  )
                                }
                                onBlur={handleBlurname}
                              />
                            </div>
                          ) : (
                            <p className="userdata">{data.user_name}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("fullname")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="username">Full Name</label>
                          {editableField === "username" ? (
                            <div className="input-row1">
                              <input
                                type="text"
                                value={data.name}
                                maxLength={20}
                                onChange={(e) =>
                                  handleFieldChange(
                                    data.id,
                                    e.target.value,
                                    "name"
                                  )
                                }
                                onBlur={handleBlurname}
                              />
                            </div>
                          ) : (
                            <p className="userdata">{data.name}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("username")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="email">Email</label>
                          {editableField === "email" ? (
                            <div className="input-row1">
                              <input
                                type="text"
                                value={data.email}
                                onBlur={handleBlurname}
                                maxLength={30}
                                onChange={(e) =>
                                  handleFieldChange(
                                    data.id,
                                    e.target.value,
                                    "email"
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <p className="userdata">{data.email}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("email")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="password">Password</label>
                          {editableField === "password" ? (
                            <>
                              <div className="input-row1">
                                <input
                                  type="text"
                                  value={data.password}
                                  onBlur={handleBlurname}
                                  maxLength={8}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      data.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                />
                              </div>
                              {passworderror && <><p style={{ color: "red", fontSize: "14px", marginLeft: "10px" }}>Required 8 characters with minimum of 1 uppercase,1 lowercase,1 special character and 1 numeric.</p></>}
                            </>
                          ) : (
                            <p className="userdata">{data.password}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("password")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="mobile">Phone Number</label>
                          {editableField === "mobile" ? (
                            <div className="input-row1">
                              <input
                                type="tel"
                                value={data.phone_no}
                                onBlur={handleBlurname}
                                maxLength={10}
                                pattern="[0-9]*"
                                onKeyPress={handleKeyPress}
                                onChange={(e) =>
                                  handleFieldChange(
                                    data.id,
                                    e.target.value,
                                    "phone_no"
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <p className="userdata">{data.phone_no}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("mobile")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div key={data.id} className="info-item">
                      <div className="userdata-container">
                        <div>
                          <label htmlFor="address">Address</label>
                          {editableField === "address" ? (
                            <div className="input-row1">
                              <textarea
                                id="description"
                                value={data.address}
                                maxLength={500}
                                onBlur={handleBlurname}
                                onChange={(e) =>
                                  handleFieldChange(
                                    data.id,
                                    e.target.value,
                                    "address"
                                  )
                                }
                                rows={5} cols={40}
                              />
                            </div>
                          ) : (
                            <p className="userdata">{data.address}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="edit-button"
                          onClick={() => handleEditClick("address")}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </form>
          </div>
        )}
        {activeButton === "Authentications" && (
          <div className="container-auth">
            {" "}
            <div className="table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Pages</th>
                    {state.GetRoleData.map((role: any) => (
                      <th>{role.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pageList.map((page: any, index: any) => (
                    <tr className="pointer" key={page.id}>
                      <td className="indexInput text-center">{index + 1}</td>
                      <td className="indexInput text-center">
                        {page.page_name}
                      </td>
                      {state.GetRoleData.map((role: any) => {
                        const isChecked =
                          page.role_list &&
                          page.role_list.some(
                            (roles: any) =>
                              roles.role_name === role.key && roles.ischecked
                          );
                        return (
                          <td
                            key={role.name}
                            className="indexInput text-center"
                          >
                            <input
                              type="checkbox"
                              className="form-control"
                              name={role.key}
                              checked={isChecked}
                              onChange={(event) =>
                                handleChangetable(
                                  event,
                                  index,
                                  role.key,
                                  page.page_name
                                )
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
