import React, { useEffect, useState } from "react";
import Modal from "../layout/modal";
import "../pages/style/banner.css";
import rightarrow from "../../assets/arrowright.svg";
import exportsvg from "../../assets/product/export.svg";
import deletesvg from "../../assets/product/delete.svg";
import editsvg from "../../assets/product/edit.svg";
import pageleft from "../../assets/pagination-left.svg";
import pageright from "../../assets/pagination-right.svg";
import ReactPaginate from "react-paginate";
import filtersvg from "../../assets/filter.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import plus from "../../assets/Header/plus.svg";
import { Hourglass } from "react-loader-spinner";
import { showError, showSuccess } from "../utils/sweetAlert";
import * as XLSX from "xlsx";

interface banner {
  id: number;
  title: string;
  bussiness_types: string;
  start_date: string;
  end_date: string;
  image: string;
}

const Banner: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("All Products");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [filterCriteria, setFilterCriteria] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [bannerdata, setbannerdata] = useState<banner[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
      console.log("Selected Business:", storedBusiness);
      getbanner(storedBusiness);
    }
  }, []);
  const getbanner = (businessType: string) => {
    setLoading(true);
    const req = {
      bussiness_type: businessType,
    };
    callApi("POST", "banner_select.php", req)
      .then((response) => {
        setbannerdata(response.data.responseJson);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  function formatDate(dateString: any) {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    return `${day}-${month}-${year}`;
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setFilterCriteria(buttonName);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (item: any) => {
    setSelectedItem({
      ...item,
    });
    setIsEditModalOpen(true);
  };

  const downloadExcel = () => {
    const todayDate: any = new Date();
    const formattedDate =
      todayDate.getDate().toString().padStart(2, "0") -
      (todayDate.getMonth() + 1).toString().padStart(2, "0") -
      todayDate.getFullYear();
    const flattenedData = currentItems.map((file: any, index: any) => {
      return {
        ID: index + 1,
        title: file.title,
        start_date: file.start_date,
        end_date: file.end_date,
        image: file.image,
      };
    });
    const headers = ["ID", "title", "start_date", "end_date", "image"];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    // const fileName = `Files ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, `Banner.xlsx`);
  };

  const handleDelete = (e: React.FormEvent, item: any) => {
    e.preventDefault();
    setLoading(true);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      // Proceed with deletion
      const req = {
        id: item.id,
      };
      callApi("POST", "banner_delete.php", req)
        .then((response) => {
          if (response.data["response Code"] == 200) {
            showSuccess("Success..!", "Banner Deleted Successfully.");
            getbanner(selectedBusiness);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting banner:", error);
        });
    } else {
      // Cancel deletion
      console.log("Deletion canceled.");
    }
  };
  const editsubmit = () => {
    setLoading(true);

    const editedProduct = {
      ...selectedItem,
      bussiness_types: selectedBusiness,
    };
    if (!editedProduct.imageFile) {
      editedProduct.image = "";
    }
    console.log("Submitting edited product:", editedProduct);

    callApi("POST", "banner_edit.php", editedProduct)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setLoading(false);

          closeEditModal();
          getbanner(selectedBusiness);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error updating banner:", error);
      });
  };

  const filteredData =
    bannerdata?.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 90);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const rangeStart = currentPage * itemsPerPage + 1;
  const rangeEnd = Math.min(
    (currentPage + 1) * itemsPerPage,
    filteredData.length
  );
  const totalItems = filteredData.length;
  const emptyObject: any = {};
  return (
    <>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <div className="products-page">
        <p style={{ marginTop: "1px", marginBottom: "1px" }}>Banner</p>
        <div className="dashboard-container">
          <p style={{ color: "purple", marginRight: "10px" }}>Dashboard</p>
          <p>
            <img src={rightarrow} alt="Arrow Right" />
          </p>
          <p style={{ marginRight: "10px" }}>Banner</p>
          <div className="buttons-container">
            <button className="export-button" onClick={downloadExcel}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={exportsvg}
                  alt="Export"
                  style={{ marginRight: "5px", marginLeft: "12px" }}
                />
                <span style={{ color: "purple" }}>Export</span>
              </div>
            </button>
            <button className="add-button1" onClick={openModal}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={plus}
                  alt="Add Product"
                  style={{ marginRight: "5px" }}
                />
                <span style={{ color: "white" }}>Add Banner</span>
              </div>
            </button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          buttontext={"Banner"}
          selecteddata={emptyObject}
        />

        {/* Table */}
        <div className="table-container">
          <table className="custom-table-ban">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Title</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{rangeStart + index}</td>
                  <td>
                    {" "}
                    <img
                      src={item.image}
                      alt="banner"
                      style={{ height: "50px" }}
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  <td>
                    <img
                      src={editsvg}
                      alt="Edit"
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() => handleEdit(item)}
                    />
                    <img
                      src={deletesvg}
                      alt="Delete"
                      style={{ cursor: "pointer" }}
                      onClick={(e: any) => handleDelete(e, item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-and-info">
          {/* Showing items information */}
          <div className="items-info">
            Showing {rangeStart}-{rangeEnd} from {totalItems}
          </div>

          {/* Pagination */}
          <div className="pagination">
            <ReactPaginate
              previousLabel={
                <img
                  src={pageleft}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              nextLabel={
                <img
                  src={pageright}
                  alt="Active"
                  style={{ marginRight: "5px" }}
                />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {isEditModalOpen && selectedItem && (
          <div className="edit-modal">
            <div className="edit-modal-content">
              <button className="close-button" onClick={closeEditModal}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Edit Banner</h2>
              <div className="product-fields">
                <div className="input-row">
                  <div>
                    <label htmlFor="title">
                      Title:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="title"
                      required
                      value={selectedItem.title}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="qty">
                      Start Date:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="startdate"
                      required
                      value={formatDate(selectedItem.start_date)} // Use the existing data from selectedItem
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          start_date: formatDate(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div>
                    <label htmlFor="price">
                      End Date:<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="enddate"
                      style={{ height: "30px" }}
                      required
                      value={formatDate(selectedItem.end_date)}
                      min={formatDate(selectedItem.start_date)}
                      max={formattedMaxDate}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          end_date: formatDate(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="image">New Image:</label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="image"
                        style={{ width: "147px", height: "30px" }}
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            const validImageTypes = [
                              "image/jpeg",
                              "image/png",
                              "image/gif",
                              "image/webp",
                            ]; // Add any other image types you want to support
                            if (!validImageTypes.includes(file.type)) {
                              alert("Please upload a valid image file.");
                              e.target.value = ""; // Clear the file input field
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              setSelectedItem({
                                ...selectedItem,
                                image: reader.result as string, // Set image URL to data URL
                                imageFile: file,
                                imageformat: file.type.split("/")[1],
                              });
                            };
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-row">
                  <div className="existing-image">
                    <label>Existing Image :</label>
                    {selectedItem.image && (
                      <img src={selectedItem.image} alt="Existing Image" />
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="submit-button"
                onClick={editsubmit}
                disabled={loading}
              >
                {loading ? (
                  <Hourglass
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={["white", "white"]}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Banner;
