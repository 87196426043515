import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/dashboard/close.svg";
import box from "../../assets/subscriber/box.svg";
import "./taskmodal.css";
import { callApi } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Hourglass } from "react-loader-spinner";
import BalanceAmtModal from "./BalanceAmtModal";

interface TaskModalProps {
  onClose: (type: any) => void;
}
interface plan {
  id: string;
  plan_name: string;
  duration: string;
  price: string;
  offer_price: string;
  offer: string;
  plan_type: string;
  offer_enddate: string;
  description: string;
  save_status: string;
  bussiness_type: string;
  image: string;
}
interface task {
  task: string;
  days: string;
  payment: string;
  description: string;
}

interface payment {
  total_payment: string;
}

const TaskModal: React.FC<TaskModalProps> = ({ onClose }) => {
  const [selectedplan, setselectedplan] = useState<string>("");
  const [plandata, setplandata] = useState<plan[]>([]);
  const [totalpaymentdata, settotalpayment] = useState<payment[]>([]);
  const [userId, setUserId] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [isValidDays, setIsValidDays] = useState(true);
  const [pendingPayment, setPendingPayment] = useState("");
  const [formFilled, setFormFilled] = useState(false);
  const [taskData, setTaskData] = useState({
    title: "",
    date: "",
    add_payment: "",
    description: "",
  });
  const [tasks, setTasks] = useState<task[]>([]);
  const [individualPlan, setIndividualPlan] = useState({
    offer_price: "",
    id: "",
    price: "",
    total_task_payment: "",
  });
  const [loading, setLoading] = useState(false);
  const [showBalanceAmtModal, setShowBalanceAmtModal] = useState(false);
  const [balanceTaskAmt, setBalanceTaskAmt] = useState("");

  useEffect(() => {}, [taskData, balanceTaskAmt]);
  useEffect(() => {
    console.log("totalpaymentdata_________org", totalpaymentdata);
  }, [totalpaymentdata]);

  useEffect(() => {
    console.log("individualPlan_________org", individualPlan);
    gettotalpayment(individualPlan.id);
  }, [individualPlan]);
  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    let storedplan = localStorage.getItem("selectedplanid");
    if (storedBusiness && storedplan) {
      setSelectedBusiness(storedBusiness);
      setselectedplan(storedplan);
      console.log("task plan id", storedplan);
      getplanselect(storedBusiness, storedplan);
      taskget(storedBusiness);
      // gettotalpayment(storedBusiness);
    }
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      console.log("user id", storedUserId);
      setUserId(storedUserId);
    }
  }, []);

  const handleClose = async () => {
    await gettotalpayment(individualPlan.id);
    console.log("individualPlan___c", individualPlan);

    if (
      individualPlan &&
      individualPlan.offer_price != "" &&
      individualPlan.offer_price != "0"
    ) {
      if (Number(individualPlan.offer_price) == Number(totalpaymentdata)) {
        setShowBalanceAmtModal(false);
        onClose("close");
      } else if (
        Number(individualPlan.offer_price) > Number(totalpaymentdata)
      ) {
        setShowBalanceAmtModal(true);
        const balanceAmt: any =
          Number(individualPlan.offer_price) - Number(totalpaymentdata);
        setBalanceTaskAmt(balanceAmt);
      }
    } else if (
      individualPlan &&
      individualPlan.price != "" &&
      individualPlan.price != "0"
    ) {
      if (Number(individualPlan.price) == Number(totalpaymentdata)) {
        setShowBalanceAmtModal(false);
        onClose("close");
      } else if (Number(individualPlan.price) > Number(totalpaymentdata)) {
        setShowBalanceAmtModal(true);
        const balanceAmt: any =
          Number(individualPlan.price) - Number(totalpaymentdata);
        setBalanceTaskAmt(balanceAmt);
      }
    } else {
      setShowBalanceAmtModal(false);
      onClose("close");
      console.log("close___>");
    }
    console.log(
      "balanceAmt___",
      balanceTaskAmt,
      totalpaymentdata,
      individualPlan.offer_price,
      individualPlan.price
    );

    if (
      !taskData.title ||
      !taskData.date ||
      !taskData.add_payment ||
      !taskData.description
    ) {
      // showError("Error", "Please fill out all fields.");
      // return;
    }
  };

  const hideBalanceAmtModal = () => {
    setShowBalanceAmtModal(false);
  };
  const BalanceSaveDraft = () => {
    onClose("savedraft");
  };

  const handleKeyDown = (event: any) => {
    // Allow only numeric characters (0-9), backspace, and delete
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow letters, numbers, and spaces
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent input if the key doesn't match the regex
    }
  };

  const taskget = (businessType: string) => {
    let storedplanid = localStorage.getItem("selectedplanid");
    console.log("id", storedplanid);

    const req = {
      bussiness_type: businessType,
      plan_id: storedplanid,
    };
    callApi("POST", "individual_plan_select.php", req)
      .then((response) => {
        setIndividualPlan(response.data.responseJson[0]);
        setTasks(response.data.responseJson[0].task);
        const responseData = response.data.responseJson;

        const selectedPlanData = responseData.find(
          (plan: any) => plan.id === storedplanid
        );
        if (selectedPlanData) {
          setplandata([selectedPlanData]);
          console.log("Found plan:", selectedPlanData);
        } else {
          console.log("Plan not found");
        }
        const durationday = response.data.responseJson[0].duration;
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const gettotalpayment = (planid: any) => {
    const req = {
      plan_id: planid,
    };
    callApi("POST", "total_task_payment.php", req)
      .then((response) => {
        const data = response.data;
        settotalpayment(data.response.data.total_payment);
        const price = individualPlan.offer_price
          ? Number(individualPlan.offer_price)
          : Number(individualPlan.price);
        console.log("price", price);
        const pendingPayment: any = price - Number(totalpaymentdata);
        console.log("555555555", pendingPayment);
        setPendingPayment(pendingPayment);
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const getplanselect = (businessType: string, storedplan: string) => {
    const req = {
      bussiness_type: businessType,
      plan_status: "1",
    };

    callApi("POST", "plan_select.php", req)
      .then((response) => {
        const responseData = response.data.responseJson;

        const selectedPlanData = responseData.find(
          (plan: any) => plan.id === storedplan
        );

        if (selectedPlanData) {
          setplandata([selectedPlanData]);
          console.log("Found plan:", selectedPlanData);
        } else {
          console.log("Plan not found");
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const handleAddNew = () => {
    if (
      !taskData.title ||
      !taskData.date ||
      !taskData.add_payment ||
      !taskData.description
    ) {
      showError("Error", "Please fill out all fields.");
      return;
    }
    let storedplanid = localStorage.getItem("selectedplanid");
    const offerPrice = plandata[0]?.offer_price
      ? parseFloat(plandata[0].offer_price)
      : parseFloat(plandata[0]?.price);

    const totalPayment = tasks.reduce(
      (acc, curr) => acc + parseFloat(curr.payment),
      0
    );

    const newPayment = parseFloat(taskData.add_payment);

    if (totalPayment + newPayment > offerPrice) {
      showError(
        "Error..!",
        "Total payment exceeds offer price. Cannot add payment."
      );
      return;
    }

    const isDuplicateDay = tasks.some((task) => task.days === taskData.date);
    if (isDuplicateDay) {
      const shouldClear = window.confirm("This Day is already exists");
      if (shouldClear) {
        setTaskData({ ...taskData, date: "" });
        setLoading(false);
      }
      return;
      setLoading(false);
    }
    if (!taskData.title) {
      toast.error("Please fill Title");
      return;
    }
    if (!taskData.date) {
      toast.error("Please fill Days");
      return;
    }
    if (!taskData.add_payment) {
      toast.error("Please fill Payment Field");
      return;
    }
    if (!taskData.description) {
      toast.error("Please fill Description");
      return;
    }
    //addnew
    const totalPay = Number(totalpaymentdata) + Number(taskData.add_payment);
    const planSaveStatus =
      Number(totalPay) <
      (individualPlan.offer_price
        ? Number(individualPlan.price)
        : Number(individualPlan.price))
        ? "0"
        : "1";
    setLoading(true);
    const req = {
      task: taskData.title,
      days: taskData.date,
      plan_id: storedplanid,
      payment: taskData.add_payment,
      description: taskData.description,
      createdby: userId,
      bussiness_type: selectedBusiness,
      plan_save_status: planSaveStatus,
    };
    callApi("POST", "task_add.php", req)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success..!", response.data.message);
          setTaskData({
            title: "",
            date: "",
            add_payment: "",
            description: "",
          });
          const newTask = response.data.responseJson;
          setTasks([...tasks, newTask]);
          setLoading(false);
          gettotalpayment(individualPlan.id);
          taskget(selectedBusiness);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!taskData.title || !taskData.date || !taskData.description) {
      showError("Error", "Please fill out all fields.");
      return;
    }
    let storedplanid = localStorage.getItem("selectedplanid");
    const offerPrice = plandata[0]?.offer_price
      ? parseFloat(plandata[0].offer_price)
      : parseFloat(plandata[0]?.price);

    const totalPayment = tasks.reduce(
      (acc, curr) => acc + parseFloat(curr.payment),
      0
    );

    const newPayment = parseFloat(taskData.add_payment);

    if (totalPayment + newPayment > offerPrice) {
      showError(
        "Error..!",
        "Total payment exceeds offer price. Cannot add payment."
      );
      return;
    }

    const isDuplicateDay = tasks.some((task) => task.days === taskData.date);
    if (isDuplicateDay) {
      const shouldClear = window.confirm("This Day is already exists");
      if (shouldClear) {
        setTaskData({ ...taskData, date: "" });
        setLoading(false);
      }
      return;
      setLoading(false);
    }
    if (taskData.date > plandata[0].duration) {
      setTaskData({ ...taskData, date: "" });
      setLoading(false);
    }
    setLoading(true);
    const totalPay = Number(totalpaymentdata) + Number(taskData.add_payment);
    const planSaveStatus =
      Number(totalPay) <
      (individualPlan.offer_price
        ? Number(individualPlan.offer_price)
        : Number(individualPlan.price))
        ? "0"
        : "1";
    const req = {
      createdby: userId,
      bussiness_type: selectedBusiness,
      plan_id: selectedplan,
      task: taskData.title,
      payment: taskData.add_payment,
      days: taskData.date,
      description: taskData.description,
      plan_save_status: planSaveStatus,
    };
    callApi("POST", "task_add.php", req)
      .then(async (response) => {
        if (response.data.success === true) {
          setLoading(false);
          await gettotalpayment(individualPlan.id);
          await taskget(selectedBusiness);
          showSuccess("Success..!", response.data.message);
          setTaskData({
            title: "",
            date: "",
            add_payment: "",
            description: "",
          });
          console.log("gettotalpayment___", individualPlan.total_task_payment);
          setTimeout(() => {
            console.log("individualPlan___c", individualPlan);
            const totalTaskPayment =
              Number(taskData.add_payment) +
              Number(individualPlan.total_task_payment);
            console.log(
              "balanceAmt___111",
              balanceTaskAmt,
              totalTaskPayment,
              taskData.add_payment,
              individualPlan.offer_price,
              individualPlan.price
            );

            if (
              individualPlan &&
              individualPlan.offer_price != "" &&
              individualPlan.offer_price != "0"
            ) {
              if (
                Number(individualPlan.offer_price) == Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(false);
              } else if (
                Number(individualPlan.offer_price) > Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(true);
                const balanceAmt: any =
                  Number(individualPlan.offer_price) - Number(totalTaskPayment);
                setBalanceTaskAmt(balanceAmt);

                return;
              }
            } else if (
              individualPlan &&
              individualPlan.price != "" &&
              individualPlan.price != "0"
            ) {
              if (Number(individualPlan.price) == Number(totalTaskPayment)) {
                setShowBalanceAmtModal(false);
              } else if (
                Number(individualPlan.price) > Number(totalTaskPayment)
              ) {
                setShowBalanceAmtModal(true);
                const balanceAmt: any =
                  Number(individualPlan.price) - Number(totalTaskPayment);
                setBalanceTaskAmt(balanceAmt);

                return;
              }
            } else {
              setShowBalanceAmtModal(false);
              console.log("close___>");
              setTaskData(response.data.responseJson);
              onClose("close");
              window.location.reload();
            }
            console.log(";;;;;;", balanceTaskAmt);
            console.log("wwwwwwwww", taskData.add_payment);
            if (Number(pendingPayment) === Number(taskData.add_payment)) {
              onClose("close");
              window.location.reload();
            }
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;

    if (id === "date") {
      const durationDay = parseInt(plandata[0]?.duration);
      const enteredDays = parseInt(value);

      if (enteredDays > durationDay) {
        setIsValidDays(false);
        const shouldClear = window.confirm(
          "Entered days cannot exceed duration"
        );
        if (shouldClear) {
          e.target.value = "";
          return;
        }
      } else {
        setIsValidDays(true);
      }

      if (value === "0") {
        showError("Error..!", "Day cannot be 0");
        return;
      }
    }

    setTaskData({
      ...taskData,
      [id]: value,
    });
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <Hourglass visible={true} height={50} width={50} />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="task-modal ">
        <div className="task-modal-content">
          <button className="close-button-task" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </button>
          {plandata.map((planItem) => (
            <h2>Add Task For {planItem.plan_name}</h2>
          ))}
          <div className="new-card">
            {plandata.map((planItem) => (
              <>
                <img
                  src={planItem.image}
                  alt="box"
                  style={{ width: "100px", height: "100px" }}
                />
                <div className="content-right">
                  <div key={planItem.id}>
                    <div className="row" style={{ fontSize: "12px" }}>
                      <div className="column">
                        <p>
                          <strong>Title:</strong> {planItem.plan_name}
                        </p>
                        <p>
                          <strong>Duration:</strong> {planItem.duration}
                        </p>
                      </div>
                      <div className="column">
                        <p>
                          <strong>Offer Price:</strong> {planItem.offer_price}
                        </p>
                        <p>
                          <strong>Price:</strong> {planItem.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          {pendingPayment != "" ? (
            <p style={{ textAlign: "center" }}>
              (Pending Payment -{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.99999 1V0H13V1H8.32866C9.22627 1.72635 9.83888 2.791 9.97253 4H13V5H9.97253C9.72381 7.24998 7.81627 9 5.49999 9H3.85162L9.82539 14.1204L9.1746 14.8796L2.1746 8.87963C2.01573 8.74346 1.95846 8.52277 2.03105 8.32653C2.10365 8.13029 2.29076 8 2.49999 8H5.49999C7.26323 8 8.72193 6.69615 8.96455 5H1.99999V4H8.96455C8.72193 2.30385 7.26323 1 5.49999 1H1.99999Z"
                  fill="#000000"
                />
              </svg>
              {pendingPayment})
            </p>
          ) : (
            <p></p>
          )}
          {tasks.map(
            (taskItem, index) =>
              // Add a conditional check to ensure taskItem is not undefined or null
              taskItem && (
                <div key={index} className="card-above-task">
                  <div className="row">
                    <div className="column">
                      <h3>Day</h3>
                      <p>{taskItem.days}</p>
                    </div>

                    <div className="column">
                      <h3>{taskItem.task}</h3>
                      <p>{taskItem.description}</p>
                    </div>

                    <div className="column center">
                      <button className="green-bg">{taskItem.payment}</button>
                    </div>
                  </div>
                </div>
              )
          )}

          <b>Task</b>
          <form onSubmit={handleSubmit}>
            <div className="task-columns">
              <div className="task-column">
                <input
                  id="title"
                  type="text"
                  placeholder="Title*"
                  className="outlined-input"
                  value={taskData.title}
                  onKeyPress={handleKeyPress}
                  onChange={handleChange}
                  minLength={0}
                  maxLength={30}
                  required
                />
                <input
                  id="date"
                  type="text"
                  placeholder="Day *"
                  className="outlined-input"
                  value={taskData.date}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  required
                  min="0"
                />
                <input
                  id="add_payment"
                  type="text"
                  value={taskData.add_payment}
                  onKeyDown={handleKeyDown}
                  minLength={0}
                  placeholder="Add Payment"
                  className="outlined-input"
                  onChange={handleChange}
                />
              </div>

              <div className="task-column">
                <textarea
                  id="description"
                  placeholder="Description *"
                  value={taskData.description}
                  style={{
                    background: "rgba(247, 247, 248, 1)",
                    marginBottom: "2px",
                    borderRadius: "5px",
                    height: "170px",
                    width: "250px",
                    resize: "none",
                    padding: "8px",
                    border: "1px solid #ccc",
                  }}
                  onChange={handleChange}
                  minLength={0}
                  required
                />
              </div>
            </div>
            <div className="button-row">
              <button
                type="button"
                className="save-button"
                onClick={handleAddNew}
                disabled={!isValidDays || loading}
              >
                Add New
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={!isValidDays}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <BalanceAmtModal
        showBalanceAmtModal={showBalanceAmtModal}
        hideBalanceAmtModal={hideBalanceAmtModal}
        BalanceSaveDraft={BalanceSaveDraft}
        balanceTaskAmt={balanceTaskAmt}
      />
      {/* {showBalanceAmtModal && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <button
              className="close-button"
              onClick={() => setShowBalanceAmtModal(false)}
            >
              <img src={closeIcon} alt="Close" />
            </button>
            <h2>
              For this plan, the balance amount is {balanceTaskAmt ? balanceTaskAmt : ""} Do you want the task to be added to it? If not, the plan is kept in draft plan.
            </h2>



            <div style={{ display: "flex" }}>
              <button type="submit" className="submit-button"
              // onClick={() => copyPlan()}
              >
                Save to Draft
              </button>
              <button type="submit" className="submit-button"
              // onClick={() => continueEdit()}
              >
                Add task
              </button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default TaskModal;
