import React, { createContext, useContext, useEffect, useState } from "react";
import "./Header.css";
import downarrow from "../../assets/Header/downarrow.svg";
import plus from "../../assets/Header/plus.svg";
import Modal from "../layout/modal";
import { callApi } from "../utils/api";

interface BusinessType {
  id: string;
  bussiness_name: string;
}
const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [selectedBusinessname, setSelectedBusinessname] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  useEffect(() => {
    getbussiness();
    const storedBusiness = localStorage.getItem("selectedBusiness");

    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
    }
  }, []);

  const getbussiness = () => {
    callApi("GET", "bussiness_type.php")
      .then((response) => {
        console.log("business Response:", response.data.responseJson);
        setBusinessTypes(response.data.responseJson);
        if (response.data.responseJson.length > 0) {
          setSelectedBusiness(response.data.responseJson[0].bussiness_name);
        }
        const storedBusiness = localStorage.getItem("selectedBusiness");
        const matchedBusiness = response.data.responseJson.find(
          (business: any) => business.id === storedBusiness
        );
        if (matchedBusiness) {
          setSelectedBusinessname(matchedBusiness.bussiness_name);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const storedBusiness = localStorage.getItem("selectedBusiness");

      const req = {
        search: searchQuery,
        bussiness_type: storedBusiness,
      };
      callApi("POST", "admin_search.php", req)
        .then((response) => {
          setSearchResults(response.data.responseJson);
        })
        .catch((error) => {
          console.error("Search Error:", error);
        });
    }
  }, [searchQuery]);

  const handleDropdownSelect = (id: string, name: string) => {
    setSelectedBusiness(name);
    localStorage.setItem("selectedBusiness", id);
    localStorage.setItem("Businessname", name);
    console.log("Selected Business ID:", id);
    window.location.reload();
  };

  const handleDataItemClick = (result: any) => {
    localStorage.setItem("selectedname", result.name);
    console.log("Clicked item:", result);
    if (result.page === "product") {
      window.location.href = "/layout/products";
    } else if (result.page === "user") {
      window.location.href = "/layout/subscribers";
    } else if (result.page === "plan") {
      window.location.href = "/layout/Membership";
    }
  };
  const emptyObject: any = {};
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  localStorage.setItem("Businessname", selectedBusinessname);
  return (
    <div className="header">
      <div className="left-side">
        <div className="dropdown">
          <button className="dropdown-button">
            {selectedBusinessname || "Select Business"}
            <img src={downarrow} alt="Down Arrow" className="down-arrow" />
          </button>
          <div className="dropdown-content" style={{ marginTop: "-8%" }}>
            {businessTypes.map((businessType) => (
              <a
                href="#"
                key={businessType.id}
                onClick={() =>
                  handleDropdownSelect(
                    businessType.id,
                    businessType.bussiness_name
                  )
                }
              >
                {businessType.bussiness_name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="search-field">
          <button className="add-button" onClick={openModal}>
            <img src={plus} alt="plus" />
          </button>

          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {searchQuery.trim() !== "" && (
            <div className="additional-content">
              {searchResults.length > 0 ? (
                <div className="search-results">
                  {searchResults.map((result, index) => (
                    <div key={index} className="card-search">
                      {/* Make each data item clickable */}
                      <a href="#" onClick={() => handleDataItemClick(result)}>
                        <div className="card-body-search">
                          <h5
                            className="card-title-search"
                            style={{ color: "black" }}
                          >
                            {result.name}
                          </h5>
                          {/* Add additional fields from result as needed */}
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="card-search">
                  <div className="card-body-search">
                    <h5
                      className="card-title-search"
                      style={{ color: "black" }}
                    >
                      No results available
                    </h5>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        buttontext={"User"}
        selecteddata={emptyObject}
      />
    </div>
  );
};

export default Header;
